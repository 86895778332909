import React from 'react';
import {useSelector} from 'react-redux';
import {UiBox, UiContainer, UiText, UiTitle} from '~/components/elements';
import {RootState} from '~/redux/root-reducer';

interface Props {
  onClickArrowDown?: () => any;
}

export const TellMore = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);

    return (
      <UiBox bgColor="orange.400" ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiContainer
          h="100%"
          maxW="container.xl"
          textAlign="center"
          color="white"
          p={{lg: '50px 0px', base: '30px 10px'}}
        >
          <UiTitle fontSize={{base: '2xl', lg: '6xl'}}>Transparency is our priority</UiTitle>
          <UiText fontSize={{base: 'lg', lg: '2xl'}}>
            You see what we do, then you give feedback, update goals, and change <br /> directions to meet your
            project’s needs
          </UiText>
        </UiContainer>
      </UiBox>
    );
  }),
);
