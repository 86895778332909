import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiContainer,
  UiDivider,
  UiFormControl,
  UiFormHelperText,
  UiImage,
  UiInput,
  UiLink,
  UiSimpleGrid,
  UiText,
  UiTextarea,
  UiTitle,
} from '~/components/elements';
import {Link, useHistory} from 'react-router-dom';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {SendContactMessage} from '~/components/shared/SendContactMessage';
import {useInput, useNotification, useRequestState} from '~/hooks';
import * as Scroll from 'react-scroll';
import {AppDispatch} from '~/redux/root-store';
import {useDispatch} from 'react-redux';
import {addVisitor} from '~/redux/visitors/thunk';
import {requestOK} from 'redux-thunk-kit';

let Element = Scroll.Element;

export const ContactUs = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const dispatch: AppDispatch = useDispatch();
    const {loading} = useRequestState(addVisitor);
    const {addMessage} = useNotification(dispatch);
    const colorDivider = useColorModeValue('', 'white');
    const history = useHistory();

    const rules = {
      email: {email: {message: '^Please enter a valid email address'}, presence: {message: '^This field is required'}},
      name: {presence: {message: '^Please tell us your name'}},
      message: {presence: {message: '^Hello, how can we help you?'}},
    };

    const {inputs, setInputs, validation, resetInput} = useInput({name: '', email: '', message: ''}, {rules});
    const onClickSendContactBtn = async () => {
      const validationErrors = validation.getErrors();
      if (validationErrors) {
        return;
      }
      const resAction = await dispatch(addVisitor({data: inputs}));
      if (requestOK(resAction, addVisitor)) {
        history.push(PAGES.CONTACT_SUBMITTED);
      }
      resetInput();
    };

    return (
      <Element name="contactUs">
        <UiBox ref={ref} pt="50px">
          <UiContainer maxW="container.xl" h="100%">
            <Col h="100%">
              <Col flex={1}>
                <UiSimpleGrid
                  columns={{base: 1, lg: 2}}
                  spacingX={20}
                  spacingY={10}
                  pt={{base: '2rem', lg: '6rem'}}
                  pb="60px"
                  h="100%"
                >
                  <Col>
                    <UiBox fontSize={{base: 'lg', lg: '2xl'}}>
                      <UiTitle fontSize={{base: 'xl', lg: '4xl'}} mb="10px">
                        Let&apos;s do great things together!
                      </UiTitle>
                      <Row mb="10px" alignItems="center">
                        <UiImage filePath="contact/phone-1.png" width="0.875em" height="0.875em" mr="17px" />
                        {isDesktop ? (
                          <UiText>+84 966 353 245</UiText>
                        ) : (
                          <UiLink href="tel:84 966 353 245">+84 966 353 245</UiLink>
                        )}
                      </Row>
                      <Row mb="10px" alignItems="center">
                        <UiImage filePath="contact/phone-2.png" width="0.875em" height="0.875em" mr="17px" />
                        {isDesktop ? (
                          <UiText>+84 905 122 811</UiText>
                        ) : (
                          <UiLink href="tel:84 905 122 811">+84 905 122 811</UiLink>
                        )}
                      </Row>
                      <Row mb="10px" alignItems="center">
                        <UiImage filePath="contact/whatsapp1.png" width="0.933em" height="0.933em" mr=" 15px" />
                        {isDesktop && <UiText>+84 905 122 811</UiText>}
                        {!isDesktop && <UiLink href="whatsapp://send?phone=84 905 122 811">+84 905 122 811</UiLink>}
                      </Row>
                      <Row alignItems="center">
                        <UiImage filePath="contact/mail.png" width="1em" height="0.8em" mr="17px" />
                        <UiText>
                          <UiLink href="mailto:hello@rayyone.com">hello@rayyone.com</UiLink>
                        </UiText>
                      </Row>
                      {isDesktop && (
                        <UiBox>
                          <UiDivider my={5} borderColor="#000000" opacity="1" />
                          <UiText fontWeight="bold" fontSize="xl" mb="10px" lineHeight="40px">
                            VIETNAM
                          </UiText>
                          <UiText fontSize="md" lineHeight="20px" mb="10px">
                            No. 28, Thanh Luong 3 street, Hoa Xuan ward, Cam Le District, Da Nang city 550000
                          </UiText>
                        </UiBox>
                      )}
                    </UiBox>
                  </Col>
                  <Col>
                    <UiFormControl>
                      <UiInput
                        placeholder="Name"
                        borderRadius="20px"
                        mb="10px"
                        value={inputs.name}
                        onChange={e => setInputs({name: e.target.value})}
                      />
                      {validation?.errors?.name && (
                        <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                          {validation.errors.name[0]}
                        </UiFormHelperText>
                      )}
                    </UiFormControl>
                    <UiFormControl>
                      <UiInput
                        placeholder="Email"
                        borderRadius="20px"
                        mb="10px"
                        value={inputs.email}
                        onChange={e => setInputs({email: e.target.value})}
                      />
                      {validation?.errors?.email && (
                        <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                          {validation.errors.email[0]}
                        </UiFormHelperText>
                      )}
                    </UiFormControl>
                    <UiFormControl>
                      <UiTextarea
                        placeholder="Message"
                        borderRadius="20px"
                        mb="10px"
                        rows={7}
                        value={inputs.message}
                        onChange={e => setInputs({message: e.target.value})}
                      />
                      {validation?.errors?.message && (
                        <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                          {validation.errors.message[0]}
                        </UiFormHelperText>
                      )}
                    </UiFormControl>
                    <UiText my="10px" fontSize="md" lineHeight="20px">
                      Your data collected in this form will be used only to contact you and talk about your project. For
                      more information, see our&nbsp;
                      <UiLink as={Link} to={PAGES.POLICY} color="orange.400">
                        Privacy Policy.
                      </UiLink>
                    </UiText>
                    <SendContactMessage loading={loading} onClickSendContactBtn={onClickSendContactBtn} />
                  </Col>
                </UiSimpleGrid>
              </Col>
              {!isDesktop && (
                <UiBox mb={3}>
                  <UiDivider mb={3} borderColor={colorDivider} opacity="1" />

                  <UiText fontWeight="bold" fontSize="xl" mb="10px" lineHeight="40px">
                    VIETNAM
                  </UiText>
                  <UiText fontSize="lg" mb="10px">
                    No. 28, Thanh Luong 3 street, Hoa Xuan ward, Cam Le District, Da Nang city 550000
                  </UiText>
                </UiBox>
              )}
            </Col>
          </UiContainer>
        </UiBox>
      </Element>
    );
  }),
);
