import env from './env';

export type AppStages = 'development' | 'staging' | 'production';

interface AppConfig {
  stage: AppStages;
  sentry: {isEnable: boolean; dsn: string};
  api: {
    host: string;
    url: string;
    timeout: number;
  };
  googleClientId: string;
  url: string;
  [key: string]: any;
}

const {STAGE} = env as {STAGE: AppStages};
const config: AppConfig = {
  stage: STAGE,
  sentry: {isEnable: env.SENTRY_ENABLED, dsn: env.SENTRY_DSN},
  api: {
    host: env.API.HOST,
    url: `${env.API.HOST}/api/v1`,
    timeout: env.API.TIMEOUT,
  },
  storeUrl: {
    android: 'https://play.google.com/store/apps/details?id=com.easeapp',
    ios: 'https://apps.apple.com/app/ease-birth-control-telehealth/id1583695430',
  },
  url: env.URL,
  googleClientId: env.GOOGLE_CLIENT_ID,
};

if (STAGE === 'staging') {
}
if (STAGE === 'production') {
}

export default config;
