import React from 'react';
import {
  Col,
  UiBox,
  UiContainer,
  UiFormControl,
  UiFormHelperText,
  UiImage,
  UiInput,
  UiLink,
  UiText,
  UiTextarea,
  UiTitle,
} from '~/components/elements';
import {Link, useHistory} from 'react-router-dom';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {SendContactMessage} from '~/components/shared/SendContactMessage';
import {useInput, useRequestState} from '~/hooks';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {AppDispatch} from '~/redux/root-store';
import {useDispatch} from 'react-redux';
import {addVisitor} from '~/redux/visitors/thunk';
import {requestOK} from 'redux-thunk-kit';

const Banner = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const dispatch: AppDispatch = useDispatch();
  const {loading} = useRequestState(addVisitor);
  const history = useHistory();
  const color = useColorModeValue('', 'white');

  const rules = {
    email: {email: {message: '^Please enter a valid email address'}},
    name: {presence: {message: '^Please tell us your name'}},
    message: {presence: {message: '^Hello, how can we help you?'}},
  };

  const {inputs, setInputs, validation, resetInput} = useInput({name: '', email: '', message: ''}, {rules});
  const onClickSendContactBtn = async () => {
    const validationErrors = validation.getErrors();
    if (validationErrors) {
      return;
    }
    const resAction = await dispatch(addVisitor({data: inputs}));
    if (requestOK(resAction, addVisitor)) {
      history.push(PAGES.CONTACT_SUBMITTED);
    }
    resetInput();
  };

  return (
    <UiBox mb={{base: '120px', lg: '50px'}} mt={{base: '100px', lg: '180px'}}>
      <UiContainer h="100%" maxW="container.xl" w={{base: '90%', lg: '52%'}} mr="auto" ml="auto" pos="relative">
        <UiImage
          filePath="home/diamond.png"
          h={{base: '3.75em', lg: '7.5em'}}
          pos="absolute"
          right={{base: '-5%', lg: '-10%'}}
          top={{base: '-2%', lg: '2%'}}
        />
        <Col textAlign="center">
          <UiTitle fontWeight="400" fontSize="6xl" lineHeight={{base: '40px', lg: '60px'}} mb="20px">
            Let’s do great things
            {isDesktop && <br />} together!
          </UiTitle>
          <UiText fontSize={{base: 'sm', lg: 'xl'}} lineHeight={{base: '22px', lg: '30px'}}>
            We are all ears for the sharing of your amazing ideas!
            <br /> Your message will be answered by us promptly the same or next business day
          </UiText>
        </Col>
        <Col mt="40px">
          <UiFormControl>
            <UiTextarea
              placeholder="Message"
              mb="10px"
              rows={9}
              borderRadius="20px"
              borderColor={color}
              _placeholder={{color: color}}
              value={inputs.message}
              onChange={e => setInputs({message: e.target.value})}
            />
            {validation?.errors?.message && (
              <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                {validation.errors.message[0]}
              </UiFormHelperText>
            )}
          </UiFormControl>
          <UiFormControl>
            <UiInput
              placeholder="Name"
              mb="10px"
              borderRadius="20px"
              border="1px solid #000000"
              borderColor={color}
              _placeholder={{color: color}}
              value={inputs.name}
              onChange={e => setInputs({name: e.target.value})}
            />
            {validation?.errors?.name && (
              <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                {validation.errors.name[0]}
              </UiFormHelperText>
            )}
          </UiFormControl>
          <UiFormControl>
            <UiInput
              placeholder="Email"
              mb="10px"
              borderRadius="20px"
              borderColor={color}
              _placeholder={{color: color}}
              value={inputs.email}
              onChange={e => setInputs({email: e.target.value})}
            />
            {validation?.errors?.email && (
              <UiFormHelperText mt="0px" mb="10px" color="red" fontSize="16px">
                {validation.errors.email[0]}
              </UiFormHelperText>
            )}
          </UiFormControl>
          <UiText my="10px" fontSize="md" lineHeight="20px">
            Your data collected in this form will be used only to contact you and talk about your project. For more
            information, see our&nbsp;
            <UiLink as={Link} to={PAGES.POLICY} color="orange.400">
              Privacy Policy.
            </UiLink>
          </UiText>
          <SendContactMessage loading={loading} onClickSendContactBtn={onClickSendContactBtn} />
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default Banner;
