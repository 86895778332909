import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiText} from '~/components/elements';
import {QUERY_MOBILE} from '~/constants';
import {useMediaQuery} from '@chakra-ui/react';
import {color} from '~/theme/color';
import {UiTitle} from '~/components/elements/UiTitle';

const Exam = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox>
      <Row bgColor="orange.400" textAlign="center" alignItems="center" justifyContent="center">
        <Col color="white" p={{base: '31px 0px'}} w={{base: '100%', lg: '80%'}} h="100%" fontWeight="400">
          <UiTitle fontSize={{base: 'sm', lg: '3xl'}} mb="15px" lineHeight={{base: '14px', lg: '30px'}}>
            we don’t just deliver digital products{' '}
          </UiTitle>
          <UiTitle fontSize={{base: '4xl', lg: '8xl'}} lineHeight={{base: '35px', lg: '80px'}}>
            We bring you your dreams
            {isDesktop && <br />} that come true
          </UiTitle>
        </Col>
      </Row>
      <UiContainer h="100%" maxW="container.xl" pt="40px" pos="relative" w={{base: '85%', lg: '76%'}}>
        <UiTitle fontSize={{base: 'xl', lg: '6xl'}} lineHeight={{base: '22px', lg: '60px'}}>
          a software company trusted by innovative Forbes Asia
          <br />
          <UiText as="span" color="orange.400">
            ‘top 100 to watch’{' '}
          </UiText>
          since 2021
        </UiTitle>
        <Row>
          <UiText
            fontSize={{base: 'xs', lg: 'md'}}
            color={color.home.textDescription}
            mt="20px"
            w={{base: '95%', lg: '60%'}}
            lineHeight={{base: '20px', lg: '25px'}}
          >
            Rayyone prides ourselves on being a software company with the spirit and proven history of consistently
            providing clients with express launching and high-quality products
          </UiText>
        </Row>

        <UiBox pos="relative" minH={{base: '35px', lg: '50px'}}>
          <UiImage
            filePath="home/arrow.png"
            h={{base: '4.375em', lg: '9.6875em'}}
            pos="absolute"
            right="33%"
            top={{base: '0px', lg: '-20px'}}
          />
        </UiBox>
      </UiContainer>
    </UiBox>
  );
};
export default Exam;
