import {createEntityAdapter, createSlice, EntityId, PayloadAction} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {RootState} from '~/redux/root-reducer';
import {
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  fetchSuccess,
  ReducerState,
  upsertManyMutably,
} from 'redux-thunk-kit';
import {SOURCES_JOB} from './entity-config';
import {JobEntity} from '~/types/models/jobs';
import {fetchJob, fetchJobs} from '~/redux/jobs/thunk';
import {getObjNthItem} from '~/utils/helpers';
import {PositionApiItem} from '~/types/models/positions';

interface InitialState extends ReducerState {
  positionId: string;
  positionName: string;
}

const sources = mapValues(SOURCES_JOB, () => DEFAULT_SOURCE_REDUCER_STATE);

const jobsAdapter = createEntityAdapter<JobEntity>();

const initialState = jobsAdapter.getInitialState<InitialState>({sources, positionId: '', positionName: ''});

const jobs = createSlice({
  name: 'jobs',
  initialState,
  reducers: {
    choosePosition(state, {payload}: PayloadAction<PositionApiItem>) {
      state.positionId = payload.id;
      state.positionName = payload.name;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchJobs.fulfilled, (state, {payload}) => {
      upsertManyMutably(jobsAdapter, state, payload.normalized.entities.jobs);
      fetchSuccess(state, payload);
    });
    builder.addCase(fetchJob.fulfilled, (state, {payload}) => {
      upsertManyMutably(jobsAdapter, state, payload.normalized.entities.jobs);
      fetchSuccess(state, payload);
    });
  },
});

export const {choosePosition} = jobs.actions;

export default jobs.reducer;

export const {
  selectById: selectJobById,
  selectIds: selectJobsIds,
  selectEntities: selectJobsEntities,
  selectEntities: selectJobEntities,
  selectAll: selectAllJobs,
  selectTotal: selectTotalJobs,
} = jobsAdapter.getSelectors((state: RootState) => state.jobs);

export const [getJobs] = createSelectAllBySource('jobs', selectJobsEntities, [SOURCES_JOB.JOBS_PAGE]);
export const [getJob] = createSelectAllBySource('job', selectJobEntities, [SOURCES_JOB.JOB_PAGE]);
