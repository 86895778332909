import React, {useRef, useState} from 'react';
import {Col, Row, UiBox, UiContainer, UiGrid, UiIcon, UiImage, UiTitle} from '~/components/elements/index';
import Content2 from '~/pages/story/components/easeWebStory/index';
import * as Scroll from 'react-scroll';
import '~/assets/css/main.css';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import {ComponentProps} from '~/types/models/app';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {HiArrowRight} from 'react-icons/hi';
import {arrowAnimation} from '~/constants/animation';

const Element = Scroll.Element;

const Image = (p: ComponentProps) => (
  <UiImage pos="absolute" filePath={p.url} transform="matrix(0.97, -0.27, 0.25, 0.96, 0, 0)" {...p} />
);

const EaseWebBanner = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const openModal = useSelector((state: RootState) => state.ui.isShowModalEaseWeb);
  const [isOpenModal, setOpenModal] = useState<boolean>(openModal || false);
  const easeWebRef = useRef<any>();

  const renderDesktopImage = () => {
    return (
      <UiBox h="100%" pos="relative" w="100%" overflow="hidden">
        <Image filePath="story/robiotic-cleaner.png" h="18.625em" w="12.375em" top="-8%" left="5%" />
        <Image filePath="story/mannose.png" h="12.875em" w="6.875em" top="15%" left="37%" />
        <Image filePath="story/cranberry.png" h="12.75em" w="8em" bottom="2%" left="23%" />
        <Image filePath="story/pH-balanced.png" h="13em" w="7em" bottom="-2%" left="45%" />
      </UiBox>
    );
  };

  const renderMobileImage = () => {
    return (
      <UiBox h="25em" pos="relative" w="100%" overflow="hidden">
        <UiImage filePath="story/robiotic-cleaner.png" pos="absolute" h="14.125em" top="-10%" left="22.5%" />
        <UiImage filePath="story/mannose.png" pos="absolute" h="9.375em" top="20%" right="15%" />
        <UiImage filePath="story/cranberry.png" pos="absolute" h="10.25em" bottom="10%" left="27.5%" />
        <UiImage filePath="story/pH-balanced.png" pos="absolute" h="9.625em" bottom="0%" right="15%" />
      </UiBox>
    );
  };

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <UiBox ref={easeWebRef}>
      <Element name="easeWebBanner">
        <UiContainer mt={{base: '20px', lg: '0px'}} maxW="container.xl" w={{base: '100%', lg: '90%'}} pos="relative">
          <UiBox
            mt={{base: '50px', lg: '0px'}}
            bgColor="#91AE70"
            h={{base: '100%', lg: '30em'}}
            pos={isOpenModal ? 'absolute' : 'relative'}
            cursor="pointer"
            borderRadius="20px"
            className={
              isOpenModal
                ? 'animate__animated animate__zoomOut animate__duration-1s'
                : 'animate__animated animate__zoomIn animate__duration-2s'
            }
            onClick={() => setOpenModal(true)}
          >
            <UiGrid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}} h="100%" w="100%">
              {isDesktop ? renderDesktopImage() : renderMobileImage()}
              <Col w={{base: '100%', lg: '90%'}} alignItems="center" color="white">
                <UiImage
                  filePath="start-up/ease.png"
                  w={{base: '8.625em', lg: '10.625em'}}
                  h={{base: '2em', lg: '2.5em'}}
                  mt={{base: '50px', lg: '80px'}}
                />
                <UiTitle fontWeight="400" fontSize="2xl" textAlign="center" mt={{base: '30px', lg: '60px'}}>
                  {isDesktop
                    ? 'Security-, Performance- and UX-oriented for Ease Landing Pages and WooCommerce Store'
                    : 'Hassle-free shopping experience for  healthcare products'}
                </UiTitle>
                <Row mt="30px" w="80%" justifyContent="space-around" alignItems="center">
                  <UiImage filePath="story/aws.png" h="2.5em" />
                  <UiImage filePath="story/woo.png" h="3.625em" />
                  <UiImage filePath="story/ts.png" h="1.5em" />
                </Row>
                <Row
                  my={{base: '30px', lg: '50px'}}
                  pb="20px"
                  alignItems="center"
                  border="2px"
                  borderColor="orange.100"
                  borderRadius="5px"
                  borderRight="0"
                  paddingX="1em"
                  paddingY="0.2em"
                  pos="relative"
                >
                  <UiTitle fontWeight="400" fontSize="xl" color="orange.200">
                    Expand
                  </UiTitle>
                  <UiIcon
                    as={HiArrowRight}
                    color="orange.100"
                    pos="absolute"
                    right={{base: '-10px', lg: '-20px'}}
                    animation={`${arrowAnimation} .4s linear infinite alternate`}
                  />
                </Row>
              </Col>
            </UiGrid>
          </UiBox>
        </UiContainer>
      </Element>
      {isOpenModal && <Content2 onClose={onClose} />}
    </UiBox>
  );
};

export default EaseWebBanner;
