import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiContainer,
  UiGrid,
  UiGridItem,
  UiImage,
  UiLink,
  UiText,
  UiTitle,
} from '~/components/elements';
import {ADVANTAGES_USING_TOOLS, RY_TOOLS} from '~/constants/ry-tool';
import parse from 'html-react-parser';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const WhatYouNeed = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <Col h={{base: '100%', lg: '100vh'}} justifyContent="space-evenly">
      <UiContainer maxW="container.xl" w={{base: '90%', lg: '76%'}} mr="auto" ml="auto">
        <UiGrid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(3, 1fr)'}} gap="30px" alignItems="center">
          <UiGridItem colSpan={1}>
            <UiText fontSize="6xl" fontWeight="bold" textAlign="center">
              What you need are all covered on RY
              <UiText as="span" color="orange.400">
                Tools
              </UiText>
            </UiText>
          </UiGridItem>
          <UiGridItem colSpan={isDesktop ? 2 : 1} ml={{base: '0px', lg: '50px'}} w="100%" h="100%">
            {ADVANTAGES_USING_TOOLS.map(value => {
              return (
                <Col key={value.key} mb="50px">
                  <UiBox as={isDesktop ? Row : Col} alignItems="center">
                    <UiImage filePath="ry-tool/docs.png" w={{base: '10em', lg: '12em'}} h="6.875em" />
                    <Col w="100%" pl={{base: '0px', lg: '20px'}} mt={{base: '20px', lg: '0px'}}>
                      <UiTitle fontSize="3xl">{value.title}</UiTitle>
                      <UiText mt="5px">{parse(value.description)}</UiText>
                    </Col>
                  </UiBox>
                </Col>
              );
            })}
          </UiGridItem>
        </UiGrid>
      </UiContainer>
      <Row bgColor="black" color="white" textAlign="center" p="25px 0px" justifyContent="space-evenly">
        <UiGrid templateColumns={{base: 'repeat(1, 1fr)', lg: 'repeat(4, 1fr)'}} gap="30px" alignItems="center">
          {RY_TOOLS.map(value => {
            return (
              <UiLink key={value.key} href={value.url} target="_blank" actionName={value.title}>
                <Col border={isDesktop ? ' ' : '1px solid white'} padding="10px 15px">
                  <UiText fontSize="xl">{value.title}</UiText>
                  <UiText fontSize="sm">{value.description}</UiText>
                </Col>
              </UiLink>
            );
          })}
        </UiGrid>
      </Row>
    </Col>
  );
};

export default WhatYouNeed;
