import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiTitle} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Banner = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox mt={{base: '0px', lg: '50px'}} pb="100px" overflow="hidden">
      <UiContainer h="100%" maxW="container.xl" w={{base: '100%', lg: '95%'}} pos="relative">
        <Col alignItems="center">
          <Col bgColor="#91AE70" alignItems="center" borderRadius="10px">
            <UiImage
              filePath="start-up/ease.png"
              h={{base: '1.5em', lg: '2.5625em'}}
              mt="40px"
              mb={{base: '30px', lg: '50px'}}
            />
            <UiTitle
              fontSize={{base: 'md', lg: '3xl'}}
              fontWeight="400"
              color="white"
              textAlign="center"
              w={{base: '80%', lg: '70%'}}
              lineHeight={{base: '25px', lg: '35px'}}
            >
              A secure eCommerce solution for sensitive data processing. Product bundle offers, and mix-and-match
              features for improved Customer Experience and Sales
            </UiTitle>
            <Row
              pos="relative"
              h="4em"
              mt={{lg: '50px'}}
              width={{base: '80%', lg: '50%'}}
              alignItems="center"
              justifyContent="space-evenly"
            >
              <UiImage filePath="story/woo.png" h={{base: '2.25em', lg: '3.625em'}} />
              <UiImage filePath="story/aws.png" h={{base: '1.625em', lg: '2.5em'}} />
              <UiImage filePath="story/ts.png" h={{base: '1.25em', lg: '1.5em'}} />
            </Row>
            <UiBox
              w={{base: '19.125em', lg: '62em'}}
              h={{base: '11.125em', lg: '33.25em'}}
              pos="relative"
              mt={{lg: '100px'}}
            >
              <UiImage
                filePath="story/robiotic-cleaner.png"
                h={{base: '8.25em', lg: '30.875em'}}
                pos="absolute"
                left={{base: '-5%', lg: '-10%'}}
                bottom="5%"
                zIndex="1"
                transform="rotate(-15.12deg)"
              />
              <UiImage
                filePath="story/laptop-ease.png"
                w="100%"
                h={{base: '10em', lg: '33.25em'}}
                pos="absolute"
                bottom={{base: '-20%', lg: '-70px'}}
                left="0px"
                zIndex="3"
              />
              <UiImage
                filePath="story/mannose.png"
                h={{base: '7.5em', lg: '22.375em'}}
                pos="absolute"
                right="-5%"
                top={{base: '0%', lg: '-5%'}}
                zIndex="2"
                transform="rotate(19.23deg)"
              />
            </UiBox>
            <UiImage
              filePath="story/cranberry.png"
              h={{base: '4.5em', lg: '6.25em'}}
              pos="absolute"
              bottom={{base: '25%', lg: '0px'}}
              right={{base: '18%', lg: '6%'}}
              zIndex={{base: '1', lg: '2'}}
            />
            <UiImage
              filePath="story/blueberry.png"
              h={{base: '5.5em', lg: '10.125em'}}
              top={{base: '85%', lg: '40%'}}
              right={{base: '-5%', lg: '-3%'}}
              pos="absolute"
              transform="rotate(19.95deg)"
            />
            <UiImage
              filePath="story/wallet.png"
              h={{base: '6.5em', lg: '13em'}}
              pos="absolute"
              top={{base: '50%', lg: '25%'}}
              left="1%"
              filter="blur(4px)"
            />
            {isDesktop && (
              <UiImage
                filePath="story/cranberry.png"
                h="8.375em"
                pos="absolute"
                top="25%"
                right="15%"
                zIndex="2"
                transform="rotate(-31.59deg)"
              />
            )}
            <UiImage
              filePath="story/pill.png"
              h={{base: '4.625em', lg: '18.125em'}}
              pos="absolute"
              bottom={{base: '0px', lg: '-3%'}}
              left="17px"
            />
            {isDesktop && (
              <UiImage
                filePath="story/mannose.png"
                h="6.5em"
                pos="absolute"
                bottom="5%"
                right="5%"
                zIndex="1"
                filter="blur(4px)"
                transform="rotate(12.42deg)"
              />
            )}
          </Col>
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default Banner;
