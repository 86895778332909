import React from 'react';
import {useSelector} from 'react-redux';
import {Col, UiBox, UiContainer, UiSimpleGrid, UiText, UiTitle} from '~/components/elements';
import {RootState} from '~/redux/root-reducer';

const content = [
  {title: 'Requirements received from Clients'},
  {title: 'Strategic technical approaches, resources, and staffing plans tailored'},
  {title: 'Talents info proposed to Clients'},
  {title: 'Team onboarding, the project gets started'},
];

export const LaunchProcess = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);

    return (
      <UiBox bg="orange.400" ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiContainer maxW="container.xl" color="white" pb={10}>
          <Col>
            <UiTitle fontWeight="500" fontSize="6xl" mt={18}>
              Service launch process
              <UiText as="span" color="orange.500">
                :
              </UiText>
            </UiTitle>
            <UiSimpleGrid columns={{base: 2, lg: 4}} spacing={{base: 5, lg: 20}} mt="60px" pb="60px">
              {content.map((i, idx) => (
                <UiBox key={idx}>
                  <UiText fontSize="xl">0{idx + 1}</UiText>
                  <UiText fontSize="xl">{i.title}</UiText>
                </UiBox>
              ))}
            </UiSimpleGrid>
            <UiText alignSelf="flex-end" fontSize="md">
              <UiText as="span" color="orange.500">
                *
              </UiText>
              Clients have the authorization to accept or reject talents for unlimited time. Rayyone will substitute
              rejected CV with another potential one.
            </UiText>
          </Col>
        </UiContainer>
      </UiBox>
    );
  }),
);
