import React from 'react';
import {Col, Row, UiBox, UiContainer, UiGrid, UiImage, UiLink, UiText} from '~/components/elements';
import {ADVANTAGES, RAYYONE_DOC_URLS} from '~/constants/ry-tool';
import parse from 'html-react-parser';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Advantage = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox mt={{base: '50px', lg: '100px'}} pb="50px">
      <UiContainer maxW="container.xl" w={{base: '90%', lg: '76%'}} mr="auto" ml="auto">
        <UiText fontSize="3xl" textAlign="center">
          With RYTools, implementation speed can be accelerated to{' '}
          <UiText as="span" color="orange.400" fontWeight="bold">
            5x faster.
          </UiText>
          {isDesktop ? <br /> : ' '}
          No more multi-lines of codes!
        </UiText>
        <UiGrid
          templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
          columnGap={{base: 3, lg: 10}}
          rowGap={10}
          mt={{base: '50px', lg: '100px'}}
        >
          {ADVANTAGES.map(advantage => {
            return (
              <Col key={advantage.key} h="100%" justifyContent="space-between">
                <Col>
                  <UiText fontSize="3xl" fontWeight="bold">
                    {advantage.title}
                  </UiText>
                  <UiText mt="15px" mb="15px">
                    {parse(advantage.description)}
                  </UiText>
                </Col>
                <UiImage filePath={advantage.image} />
              </Col>
            );
          })}
        </UiGrid>
        <Row justifyContent="center" mt="50px">
          <UiLink actionName="View all documents" href={RAYYONE_DOC_URLS.HOME} target="_blank">
            View all documents {'>>'}
          </UiLink>
        </Row>
      </UiContainer>
    </UiBox>
  );
};

export default Advantage;
