import {
  Colors,
  extendTheme,
  ThemeComponents,
  ThemeConfig,
  withDefaultColorScheme,
  withDefaultSize,
} from '@chakra-ui/react';

const fonts = {
  heading: 'CircularStd',
  body: 'Inter',
};
const colors: Colors = {
  // Base color: #2C4142. Generated by https://smart-swatch.netlify.app
  easeMobile: {
    50: '#e4f9f6',
    100: '#cbe5e1',
    200: '#acd2cd',
    300: '#8ec0b9',
    400: '#6eada6',
    500: '#55948c',
    600: '#41746d',
    700: '#2d534e',
    800: '#17332f',
    900: '#001311',
  },
  black: {
    100: '#575757',
    400: '#305853',
  },
  link: {
    active: '#975ca5',
  },
  orange: {
    50: '#fff1db',
    100: '#ffd6b0',
    200: '#fcbb81',
    300: '#faa151',
    400: '#fb8c00',
    500: '#de6c07',
    600: '#ae5404',
    700: '#7d3b01',
    800: '#4c2300',
    900: '#1f0900',
  },
};
const fontSizes = {
  xs: '0.75rem',
  sm: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  '2xl': '1.5625rem',
  '3xl': '1.875rem',
  '4xl': '2.25rem',
  '5xl': '2.5rem',
  '6xl': '3.75rem',
  '7xl': '4.375rem',
  '8xl': '5rem',
  '9xl': '5.625rem',
};
const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: true,
};

const components: ThemeComponents = {
  Button: {
    baseStyle: {
      borderRadius: 10,
    },
    sizes: {
      sm: {
        minW: 50,
      },
    },
  },
  Text: {
    defaultProps: {
      size: 'md',
    },
  },
};

const sizes = {container: {xl: '1350px'}};
export const uiTheme = extendTheme(
  {colors, config, components, fonts, sizes, fontSizes},
  withDefaultColorScheme({colorScheme: 'easeMobile'}),
  withDefaultSize({size: 'lg'}),
);
