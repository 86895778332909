import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiLink} from '~/components/elements';
import {QUERY_MOBILE} from '~/constants';
import {useMediaQuery} from '@chakra-ui/react';
import {UiTitle} from '~/components/elements/UiTitle';
import * as Scroll from 'react-scroll';
import {CALENDLY_LINK} from '~/constants/coupon';

interface Props {
  bgColor?: string;
}

let Link = Scroll.Link;

const ContactTime = ({bgColor = 'orange.400'}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox h="100%" textAlign="center">
      <Col
        mt={{base: '55px', lg: '95px'}}
        bgColor={bgColor}
        h={{base: '224px', lg: '300px'}}
        color="white"
        pos="relative"
        justifyContent="center"
      >
        <UiContainer maxW="container.xl" pos="relative">
          <UiTitle fontSize={{base: '2xl', lg: '5xl'}} lineHeight={{base: '50px', lg: '60px'}}>
            If the matter can not wait
          </UiTitle>
          <Row fontSize={{base: 'sm', lg: 'xl'}} lineHeight="60px" alignItems="center" justifyContent="center">
            <UiImage filePath="home/calendly-white.png" h="1.4375em" mr="15px" />
            <UiTitle mr="15px" fontWeight="400">
              <UiLink href={CALENDLY_LINK} target="_blank">
                Calendly meet
              </UiLink>
            </UiTitle>
            <UiTitle mr="15px" fontWeight="400">
              or
            </UiTitle>
            <UiImage filePath="home/phone-1.png" h="1.4375em" mr="15px" />
            <UiTitle fontWeight="400">
              <UiLink
                as={Link}
                href="whatsapp://send?phone=84 905 122 811"
                to="contactUs"
                smooth={true}
                hashSpy={true}
                offset={0}
                duration={800}
              >
                Call us
              </UiLink>
            </UiTitle>
          </Row>
          {/*<Col mt={{base: '0px', lg: '45px'}} fontSize={{base: 'sm', lg: 'xl'}} lineHeight="20px">*/}
          {/*  <UiText mb="18px"> 11 am to 9 pm Australia</UiText>*/}
          {/*  <UiText>8 am to 6 pm Vietnam</UiText>*/}
          {/*</Col>*/}
          {isDesktop && (
            <UiImage
              filePath="home/calendly-1.png"
              h={{base: '1.875em', lg: '3.375em'}}
              pos="absolute"
              top={{base: '180px', lg: '20px'}}
              left={{base: '20px', lg: '30px'}}
            />
          )}
          {isDesktop && (
            <UiImage
              filePath="home/micro.png"
              h={{base: '1.625em', lg: '2.6875em'}}
              pos="absolute"
              bottom={{base: '50px', lg: '50px'}}
              left={{base: '60px', lg: '120px'}}
            />
          )}
          {isDesktop && (
            <UiImage
              filePath="home/sun-1.png"
              pos="absolute"
              h={{base: '2.8125em'}}
              top={{base: '100px', lg: '90px'}}
              left={{base: '20px', lg: '240px'}}
            />
          )}

          <UiImage
            filePath="home/rocket-1.png"
            h={{base: '2.625em', lg: '2.8125em'}}
            pos="absolute"
            bottom={{base: '15px', lg: '70px'}}
            right={{base: '330px', lg: '250px'}}
          />
          <UiImage
            filePath="home/lightbulb-1.png"
            h={{base: '2em', lg: '2.9375em'}}
            pos="absolute"
            top={{base: '25px', lg: '50px'}}
            right={{base: '10px', lg: '170px'}}
          />
          <UiImage
            filePath="home/headphone-1.png"
            h="3.375em"
            pos="absolute"
            right={{base: '50px', lg: '20px'}}
            bottom={{base: '-30%', lg: '20px'}}
            transform="rotate(-25.02deg)"
          />
        </UiContainer>
      </Col>
    </UiBox>
  );
};

export default ContactTime;
