import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {CouponEntity} from '~/types/models/coupon';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_COUPON} from '~/redux/coupons/entity-config';
import {addCoupon, deleteCoupon, fetchCoupons, updateCoupon} from '~/redux/coupons/thunk';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_COUPON, () => DEFAULT_SOURCE_REDUCER_STATE);

const couponsAdapter = createEntityAdapter<CouponEntity>();

const initialState = couponsAdapter.getInitialState<InitialState>({sources});

const coupons = createSlice({
  name: 'coupons',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchCoupons.fulfilled, (state, {payload}) => {
      upsertManyMutably(couponsAdapter, state, payload.normalized.entities.coupons);
      fetchSuccess(state, payload);
    });
    builder.addCase(addCoupon.fulfilled, (state, {payload}) => {
      upsertMany(couponsAdapter, state, payload.normalized.entities.coupons);
      addIdsToSources(state, payload.normalized.result, [SOURCES_COUPON.COUPONS_PAGE]);
    });
    builder.addCase(updateCoupon.fulfilled, (state, {payload}) => {
      upsertOneMutably(couponsAdapter, state, payload.normalized.entities.coupons);
    });
    builder.addCase(deleteCoupon.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      couponsAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
  },
});

// export const {} = coupons.actions;

export default coupons.reducer;

export const {
  selectById: selectCouponById,
  selectIds: selectCouponsIds,
  selectEntities: selectCouponsEntities,
  selectAll: selectAllCoupons,
  selectTotal: selectTotalCoupons,
} = couponsAdapter.getSelectors((state: RootState) => state.coupons);

export const [getCoupons] = createSelectAllBySource('coupons', selectCouponsEntities, [SOURCES_COUPON.COUPONS_PAGE]);
