import React, {memo, useEffect, useState} from 'react';
import classNames from 'classnames';
import placeholder from '~/assets/img/noimage-placeholder.svg';
import {UiBox, UiIcon, UiSpinner} from '~/components/elements';
import {AiOutlineClose} from 'react-icons/ai';
import {Image, ImageProps, useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

export interface AppImageProps extends ImageProps {
  filePath?: string;
  src?: string;
  inline?: boolean;
  alt?: string;
  onClose?: (arg0: any) => any;
  containerClasses?: string;
  isLazy?: boolean;
}

const requireImage = (name: string) => {
  try {
    return require(`~/assets/img/${name}`);
  } catch (err) {
    return undefined;
  }
};

const getImageDesktopUrl = (name: string) => {
  const [imageName, imageExtension] = name.split('.');
  return imageName + '@2x.' + imageExtension;
};

export const UiImage = memo(function UiImage({
  filePath,
  src,
  alt,
  containerClasses,
  onClose,
  inline,
  isLazy = true,
  ...rest
}: AppImageProps) {
  const [imageSrc, setImageSrc] = useState('');
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  if (filePath && src) {
    throw new Error('Only specify either filepath or src');
  }
  useEffect(() => {
    if (filePath) {
      if (!isDesktop) {
        setImageSrc(requireImage(filePath) || placeholder);
      } else {
        const imageDesktopUrl = getImageDesktopUrl(filePath);
        const desktopImage = requireImage(imageDesktopUrl);
        if (desktopImage) {
          setImageSrc(desktopImage);
        } else {
          const mobileImage = requireImage(filePath);
          setImageSrc(mobileImage || placeholder);
        }
      }
    }
  }, [filePath, isDesktop]);

  const isLoadingLocalFile = filePath && imageSrc === '';

  return isLoadingLocalFile ? (
    <UiSpinner size="sm" />
  ) : (
    <div className={classNames(containerClasses, {inline})}>
      <Image
        src={src || imageSrc}
        fallbackSrc={placeholder}
        alt={alt || ''}
        onError={() => setImageSrc(placeholder)}
        className={classNames({inline})}
        loading={isLazy ? 'lazy' : undefined}
        {...rest}
      />
      {!!onClose && imageSrc !== placeholder && (
        <UiBox onClick={onClose} className="cursor-pointer">
          <UiIcon as={AiOutlineClose} color="red" />
        </UiBox>
      )}
    </div>
  );
});
