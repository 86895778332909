import {ENDPOINTS} from '~/constants';
import {FetchParams} from 'redux-thunk-kit';
import {thunk} from '~/utils/thunk';
import {NormalizedPosition, PositionEntity} from '~/types/models/positions';
import {positionSchema} from '~/redux/positions/entity-config';

export interface FetchPositionsParams extends FetchParams {
  with?: string;
}

export interface FetchPositionParams extends FetchParams {}

const query = thunk.query<PositionEntity, NormalizedPosition>('position', positionSchema);

export const fetchPositions = query.fetch<FetchPositionsParams>(ENDPOINTS.POSITIONS);

export const fetchPosition = query.fetchOne<FetchPositionParams>(`${ENDPOINTS.POSITIONS}/:id`);
