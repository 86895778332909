import {createEntityAdapter, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {AuthEntity} from '~/types/models/auth';
import {RootState} from '~/redux/root-reducer';
import {DEFAULT_SOURCE_REDUCER_STATE, ReducerState} from 'redux-thunk-kit';
import {SOURCES_AUTH} from '~/redux/auth/entity-config';
import {UserEntity} from '~/types/models/user';

/* Slice */
interface InitialState extends ReducerState {
  currentUser?: UserEntity;
  token?: string;
}

const sources = mapValues(SOURCES_AUTH, () => DEFAULT_SOURCE_REDUCER_STATE);

const authsAdapter = createEntityAdapter<AuthEntity>();

const initialState = authsAdapter.getInitialState<InitialState>({
  sources,
  currentUser: {} as UserEntity,
  token: undefined,
});

// Slice
const auths = createSlice({
  name: 'auths',
  initialState,
  reducers: {
    setCurrentUser(state, {payload}: PayloadAction<AuthEntity>) {
      if (payload.user) {
        state.currentUser = {...state.currentUser, ...payload.user};
      }
      state.token = payload.token;
    },
  },
  extraReducers: builder => {
    // builder.addCase(fetchCurrentUserInfo.fulfilled, (state, {payload}) => {
    //   const {normalized} = payload;
    //   state.currentUser = {...state.currentUser, ...normalized.entities.admins[normalized.result]};
    //   fetchSuccess(state, payload);
    // });
    // builder.addCase(loginByEmail.fulfilled, (state, {payload}) => {
    //   const {normalized} = payload;
    //   state.currentUser = normalized.entities.auths[normalized.result].user;
    // });
  },
});

export const {setCurrentUser} = auths.actions;

export default auths.reducer;

export const {
  selectById: selectAuthById,
  selectIds: selectAuthsIds,
  selectEntities: selectAuthsEntities,
  selectAll: selectAllAuths,
  selectTotal: selectTotalAuths,
} = authsAdapter.getSelectors((state: RootState) => state.auth);
