import {useHistory} from 'react-router-dom';
import {UiButton} from '~/components/elements';
import {PAGES, QUERY_MOBILE} from '~/constants';
import React from 'react';
import {useMediaQuery} from '@chakra-ui/react';

export const ContactUsBtn = ({title = 'Contact us'}: {title?: string}) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const history = useHistory();

  const onClickContactUs = () => {
    history.push(PAGES.CONTACT_US);
  };

  return (
    <UiButton
      fontSize={22}
      px={{base: '1.5rem', lg: '2rem'}}
      py={{base: '1.2rem', lg: '1.7rem'}}
      colorScheme={isDesktop ? undefined : 'white'}
      color="white"
      backgroundColor="black"
      onClick={onClickContactUs}
    >
      {title}
    </UiButton>
  );
};
