import React from 'react';
import {Col, UiBox, UiLink, UiText, UiTitle} from '~/components/elements/index';
import MeetUsOffline from '~/components/shared/MeetUsOffline';

const ContactUs = () => {
  return (
    <Col justifyContent="space-between">
      <MeetUsOffline />
      <UiBox bgColor="orange.400" lineHeight="30px" mt="50px">
        <Col p="64px 0px " color="white" textAlign="center">
          <UiTitle fontSize="5xl" mb="30px">
            Looking for job opening?
          </UiTitle>
          <UiText fontSize="xl">
            <UiLink href="https://www.facebook.com/rayyone.vn" target="_blank">
              Please check our FanPage
            </UiLink>
          </UiText>
        </Col>
      </UiBox>
    </Col>
  );
};

export default ContactUs;
