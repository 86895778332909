import React, {memo} from 'react';
import {AppDispatch} from '~/redux/root-store';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {useHistory} from 'react-router-dom';
import {showMobileMenu} from '~/redux/ui/slice';
import {
  Col,
  Row,
  UiButton,
  UiDivider,
  UiDrawer,
  UiDrawerBody,
  UiDrawerContent,
  UiDrawerFooter,
  UiDrawerHeader,
  UiDrawerOverlay,
  UiImage,
  UiText,
} from '~/components/elements';
import {publicRoutes} from '~/routes';
import {PAGES} from '~/constants';
import {renderMenuItems} from './Navigation';

export const MobileNavigation = memo(() => {
  const dispatch: AppDispatch = useDispatch();
  const isShow = useSelector((state: RootState) => state.ui.menu.isShowMobileMenu) || false;
  const history = useHistory();

  const closeDrawer = () => {
    dispatch(showMobileMenu({value: false}));
  };

  return (
    <UiDrawer isOpen={isShow} placement="bottom" onClose={closeDrawer} size="xs">
      <UiDrawerOverlay />
      <UiDrawerContent>
        <UiDrawerHeader>
          <Row align="flex-end">
            <UiImage
              filePath="rayyone.svg"
              width={'4rem'}
              objectFit="contain"
              onClick={() => {
                closeDrawer();
                history.push('/');
              }}
            />
            <UiText
              _hover={{cursor: 'pointer'}}
              fontWeight="bold"
              color={'orange.400'}
              fontSize="4xl"
              onClick={() => {
                closeDrawer();
                history.push('/');
              }}
              display="inline-block"
            >
              | Rayyone
            </UiText>
          </Row>
          <UiDivider mt={3} borderColor="orange.600" />
        </UiDrawerHeader>

        <UiDrawerBody>
          {publicRoutes.map(item => {
            return renderMenuItems(item, '', true, closeDrawer);
          })}
        </UiDrawerBody>

        <UiDrawerFooter mb={10}>
          <Col flex={1}>
            <UiButton
              variant="outline"
              colorScheme="white"
              color="orange.400"
              onClick={() => {
                closeDrawer();
                history.push(PAGES.CONTACT_US);
              }}
            >
              Contact us!
            </UiButton>
          </Col>
        </UiDrawerFooter>
      </UiDrawerContent>
    </UiDrawer>
  );
});
