import React, {memo, useEffect} from 'react';
import {BaseLayout, Footer, Navigation} from '~/components/layouts';
import {UiBox} from '~/components/elements';
import ReactGA from 'react-ga';
import {useLocation} from 'react-router-dom';

interface Props {
  children: React.ReactNode;
}

export const PublicLayout = memo(({children}: Props) => {
  // if (isEmpty(currentUser) && window.location.pathname !== '/p/login') {
  //   const redirectTo = stringify({
  //     redirect: window.location.href,
  //   });
  //   return <Redirect to={`/p/login?${redirectTo}`} />;
  // }
  //
  const {pathname} = useLocation();
  useEffect(() => {
    ReactGA.pageview(pathname);
  }, [pathname]);

  return (
    <BaseLayout>
      <Navigation />
      <UiBox minH="100vh">{children}</UiBox>
      <Footer />
    </BaseLayout>
  );
});
