import React, {useRef} from 'react';
import {UiBanner, UiBox, UiText} from '~/components/elements';
import {TellMore} from '~/pages/how-we-work/TellMore';
import {ContactUs} from '~/components/shared/ContactUs';
import HowWeWork from '~/pages/how-we-work/HowWeWork';
import ContactUsScrumTeam from '~/components/shared/ContactUsScrumTeam';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const HowWeWorkPage = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const contactUsRef = useRef<HTMLDivElement>(null);
  const description =
    'We understand that only true cooperation will result in working\nsoftware satisfying your needs. In our work, we follow Scrum/Agile\nmethodologies – short 1 to 2-week sprints, clear deliverables,\nand visible progress.';
  const title = (
    <>
      <UiText as="span" color="orange.400">
        How{' '}
      </UiText>
      we work
    </>
  );

  useScrollToTop();

  useMetaTags(
    {
      title: `How We Work | Rayyone`,
      description: `We use modern tools like React, Golang, Flutter to build scalable, fault-tolerant software products. Our Scrum team is responsible end-to-end for the projects.`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content: 'React Native,Golang,Flutter,React,Scrum team,software development,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox>
      <UiBanner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
        title={title}
        description={description}
      />
      <TellMore ref={mainRef} />
      <HowWeWork />
      <ContactUsScrumTeam hiddenLink={true} />
      <ContactUs ref={contactUsRef} />
    </UiBox>
  );
};

export default HowWeWorkPage;
