import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {RootState} from '~/redux/root-reducer';
import {
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  fetchSuccess,
  ReducerState,
  upsertManyMutably,
} from 'redux-thunk-kit';
import {fetchLevels} from '~/redux/levels/thunk';
import {SOURCES_LEVEL} from '~/redux/levels/entity-config';
import {LevelEntity} from '~/types/models/levels';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_LEVEL, () => DEFAULT_SOURCE_REDUCER_STATE);

const levelsAdapter = createEntityAdapter<LevelEntity>();

const initialState = levelsAdapter.getInitialState<InitialState>({sources});

const levels = createSlice({
  name: 'levels',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchLevels.fulfilled, (state, {payload}) => {
      upsertManyMutably(levelsAdapter, state, payload.normalized.entities.levels);
      fetchSuccess(state, payload);
    });
  },
});

// export const {} = levels.actions;

export default levels.reducer;

export const {
  selectById: selectLevelById,
  selectIds: selectLevelsIds,
  selectEntities: selectLevelsEntities,
  selectAll: selectAllLevels,
  selectTotal: selectTotalLevels,
} = levelsAdapter.getSelectors((state: RootState) => state.levels);

export const [getLevels] = createSelectAllBySource('levels', selectLevelsEntities, [SOURCES_LEVEL.LEVELS_PAGE]);
