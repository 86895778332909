import React from 'react';
import {Col, Row, UiBox, UiContainer, UiDivider, UiImage, UiLink, UiText, UiTitle} from '~/components/elements/index';
import {QUERY_MOBILE} from '~/constants';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import * as Scroll from 'react-scroll';

let Element = Scroll.Element;

const MeetUsOffline = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const colorDivider = useColorModeValue('', 'white');

  return (
    <Element name="contactUs">
      <UiContainer
        maxW="container.xl"
        w={{base: '80%', lg: '70%'}}
        mr="auto"
        ml="auto"
        pt={{base: '50px ', lg: '120px '}}
      >
        <UiTitle fontWeight="400px" fontSize="5xl" textAlign="center">
          Meet us offline
        </UiTitle>
        <UiBox as={isDesktop ? Row : Col} justifyContent="space-between" mt="50px">
          <Col mb="50px">
            <UiText fontWeight="700" fontSize="xl" lineHeight="40px">
              VIETNAM
            </UiText>
            <UiText fontSize="md" lineHeight="20px">
              No. 28, Thanh Luong 3 street, Hoa Xuan ward,
              <br /> Cam Le District, Da Nang city 550000,
            </UiText>
            <UiDivider borderColor={colorDivider} pt="23px" />
          </Col>
          <Col fontSize={{base: 'lg', lg: '3xl'}}>
            <Row mb="10px" alignItems="center">
              <UiImage filePath="contact/phone-1.png" width="0.875em" height="0.875em" mr="17px" />
              {isDesktop ? (
                <UiText>+84 966 353 245</UiText>
              ) : (
                <UiLink href="tel:84 966 353 245">+84 966 353 245</UiLink>
              )}
            </Row>
            <Row mb="10px" alignItems="center">
              <UiImage filePath="contact/phone-2.png" width="0.875em" height="0.875em" mr="17px" />
              {isDesktop ? (
                <UiText>+84 905 122 811</UiText>
              ) : (
                <UiLink href="tel:84 905 122 811">+84 905 122 811</UiLink>
              )}
            </Row>

            <Row mb="10px" alignItems="center">
              <UiImage filePath="contact/whatsapp1.png" width="0.933em" height="0.933em" mr=" 15px" />
              {isDesktop && <UiText>+84 905 122 811</UiText>}
              {!isDesktop && <UiLink href="whatsapp://send?phone=84 905 122 811">+84 905 122 811</UiLink>}
            </Row>
            <Row alignItems="center">
              <UiImage filePath="contact/mail.png" width="1em" height="0.8em" mr="17px" />
              <UiText>
                <UiLink href="mailto:hello@rayyone.com">hello@rayyone.com</UiLink>
              </UiText>
            </Row>
          </Col>
        </UiBox>
      </UiContainer>
    </Element>
  );
};

export default MeetUsOffline;
