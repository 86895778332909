import React from 'react';
import {UiBox} from '~/components/elements';
import Banner from '~/pages/contact-us/components/Banner';
import ContactUs from '~/pages/contact-us/components/ContactUs';
import ContactUsTime from '~/components/shared/ContactUsTime';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const ContactUsPage = () => {
  useScrollToTop();

  useMetaTags(
    {
      title: `Contact Us | Rayyone`,
      description: `Get in touch with us right now! Send your email to Rayyone and it will be answered within few hours.`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content: 'rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox>
      <Banner />
      <ContactUsTime />
      <ContactUs />
    </UiBox>
  );
};

export default ContactUsPage;
