import React, {memo} from 'react';
import {useMediaQuery} from '@chakra-ui/react';
import {Link, useHistory} from 'react-router-dom';
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiContainer,
  UiDivider,
  UiFormControl,
  UiFormHelperText,
  UiGrid,
  UiIcon,
  UiImage,
  UiInput,
  UiLink,
  UiText,
  UiTitle,
} from '~/components/elements/index';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {ComponentProps} from '~/types/models/app';
import {connects, menu, SUBSCRIBER_API_CATEGORY} from '~/constants/footer';
import {IconType} from 'react-icons';
import moment from 'moment';
import {SubscriberEntity} from '~/types/models/subscribers';
import {useDispatch} from 'react-redux';
import {useInput, useNotification, useRequestState} from '~/hooks';
import {AppDispatch} from '~/redux/root-store';
import {addSubscriber} from '~/redux/subscribers/thunk';
import {requestOK} from 'redux-thunk-kit';

const SEPARATOR = '/';

interface InputParams extends Partial<SubscriberEntity> {}

const getDefaultParams = (item?: Partial<SubscriberEntity> | undefined): InputParams => ({
  category: SUBSCRIBER_API_CATEGORY,
  email: item?.email || '',
});

const getPostParams = (inputs: InputParams) => ({
  category: SUBSCRIBER_API_CATEGORY,
  email: inputs?.email,
});

interface MenuProps {
  title: string;
  pages: {url: string; title: string; icon?: IconType; key: number; mobileUrl?: string}[];
}

const IconWrapper = (p: ComponentProps) => (
  <Row
    w="38px"
    h="38px"
    borderRadius="50%"
    backgroundColor="white"
    alignItems="center"
    justifyContent="center"
    {...p}
  />
);

const Subscribe = ({isDesktop}: {isDesktop: boolean}) => {
  const dispatch: AppDispatch = useDispatch();
  const {addMessage} = useNotification(dispatch);
  const {loading} = useRequestState(addSubscriber);
  const rules = {
    email: {email: {message: '^Please enter a valid email address'}, presence: {message: '^This field is required'}},
  };
  const defaultParams = getDefaultParams({});
  const {inputs, setInputs, validation, resetInput} = useInput(defaultParams, {rules});

  const onClickSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();
    let resAction;

    if (validation.getErrors()) {
      return;
    }
    const postParams = getPostParams(inputs);
    resAction = await dispatch(addSubscriber({data: postParams}));
    if (requestOK(resAction, addSubscriber)) {
      resetInput();
      addMessage('Thanks for subscribing. Your request has been submitted successfully!');
    }
  };

  return (
    <UiBox>
      {isDesktop && <UiImage filePath="footer/plane.png" pos="absolute" top="-40%" left="20%" />}
      <UiTitle fontSize={{base: '3xl', lg: '3xl'}} textAlign="center">
        Promotion, Tips <br /> and more
      </UiTitle>
      <UiImage
        filePath="footer/arrow.png"
        pos="absolute"
        top={{base: '-15%', lg: '-10%'}}
        right={{base: '-5%', lg: '-15%'}}
      />

      <UiInput
        variant="flushed"
        placeholder="Your email address"
        value={inputs.email}
        onChange={({target}) => setInputs({email: target.value})}
        fontSize={{base: 'sm', lg: 'md'}}
      />
      <UiFormControl pos="absolute">
        {validation?.errors?.email && (
          <UiFormHelperText mt="8px" color="red" fontSize="13px">
            {validation.errors.email[0]}
          </UiFormHelperText>
        )}
      </UiFormControl>

      <UiButton
        isLoading={loading}
        mt="30px"
        fontSize="sm"
        height={{base: '30px'}}
        w={{base: '35%', lg: '35%'}}
        bgColor="transparent"
        color="white"
        border="1px solid white"
        onClick={onClickSubmit}
      >
        Subscribe
      </UiButton>
    </UiBox>
  );
};

const Menu = ({title, pages}: MenuProps) => {
  return (
    <Col color="white">
      <UiText fontSize="lg" fontWeight="bold">
        {title}
      </UiText>
      <Col mt={{base: '20px', lg: '35px'}} fontSize={{base: 'xs', lg: 'sm'}}>
        {pages.map(page => {
          const isRelativePath = page.url.charAt(0) === SEPARATOR;
          if (isRelativePath) {
            return (
              <UiLink key={page.key} as={Link} to={page.url} mb="15px">
                {page.title}
              </UiLink>
            );
          }
          return (
            <UiLink key={page.key} href={page.url} target="_blank" mb="15px">
              {page.title}
            </UiLink>
          );
        })}
      </Col>
    </Col>
  );
};

export const Footer = memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const history = useHistory();
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

    return (
      <UiBox bgColor="black" p={{base: '20px 0px', lg: '40px 0px'}}>
        <UiContainer maxW="container.xl" h="100%" color="white" w={{base: '90%', lg: '100%'}}>
          {isDesktop && (
            <Row>
              <UiImage
                filePath="rayyone.svg"
                width={50}
                height="100%"
                objectFit="contain"
                onClick={() => history.push('/')}
              />
              <UiText color="white" fontSize="xs">
                We pride ourselves on delivering software
                <br /> products compromising the triple constraints:
                <br />
                <strong>Low Cost - High Quality - Done Quickly</strong>
              </UiText>
            </Row>
          )}
          <UiBox as={isDesktop ? Row : Col} mt="72px">
            {!isDesktop && (
              <Col pos="relative" mb="50px">
                <Subscribe isDesktop={isDesktop} />
              </Col>
            )}
            <Row w={{base: '100%', lg: '81%'}} alignItems="center">
              <UiGrid
                templateColumns={{base: 'repeat(2,1fr)', lg: 'repeat(5,1fr)'}}
                columnGap={{base: 3, lg: 10}}
                rowGap={10}
                w="100%"
              >
                {menu.map(value => {
                  return <Menu key={value.key} title={value.title} pages={value.pages} />;
                })}
                {isDesktop && <Menu title="Connect" pages={connects} />}
              </UiGrid>
            </Row>
            {!isDesktop && (
              <Row justifyContent="center" mt="20px">
                <Row w="80%" justifyContent="space-evenly">
                  {connects.map(connect => {
                    return (
                      <UiLink
                        key={connect.key}
                        href={connect.mobileUrl || connect.url}
                        target="_blank"
                        actionName={connect.title}
                      >
                        <IconWrapper>
                          <UiIcon as={connect.icon} color="black" />
                        </IconWrapper>
                      </UiLink>
                    );
                  })}
                </Row>
              </Row>
            )}
            {isDesktop && (
              <Col pos="relative">
                <Subscribe isDesktop={isDesktop} />
              </Col>
            )}
          </UiBox>
        </UiContainer>
        <UiDivider borderColor="#FAC98C" borderBottomWidth="2px" mt="20px" />
        <UiContainer maxW="container.xl" h="100%" color="white">
          <Row color="white" mt={{base: '10px', lg: '35px'}} justifyContent={{base: 'end', lg: 'space-between'}}>
            {isDesktop && (
              <UiLink as={Link} to={PAGES.POLICY} fontSize="sm">
                Privacy Policy
              </UiLink>
            )}
            <UiText fontSize="sm">© {moment().year()} Rayyone. All Rights Reserved.</UiText>
          </Row>
        </UiContainer>
      </UiBox>
    );
  }),
);
