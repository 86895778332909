import {useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {UiBox, UiImage} from '~/components/elements';
import {QUERY_MOBILE} from '~/constants';

const EaseMobileAnimation = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  return (
    <UiBox pos="relative" width={{base: 'auto', lg: '350px'}}>
      <UiBox>
        <UiImage filePath="home/ease-video.png" pos="relative" zIndex="5" />
      </UiBox>
      <UiBox pos="absolute" top="0px" transition="2s">
        <AnimationOnScroll animateIn="animate__zoomIn">
          <UiBox zIndex="2">
            <svg
              viewBox="0 0 98 104"
              height={isDesktop ? '8em' : '6.25em'}
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M51.5 92.9785C77.1812 92.9785 98 72.1646 98 46.4893C98 20.814 77.1812 0 51.5 0C25.8188 0 5 20.814 5 46.4893C5 72.1646 25.8188 92.9785 51.5 92.9785Z"
                fill="#456762"
              ></path>
            </svg>
          </UiBox>
          <UiBox pos="absolute" left="0px" top="80px" zIndex="1">
            <svg
              width={isDesktop ? '5.5em' : '3.875em'}
              height={isDesktop ? '5.3125em' : '3.375em'}
              viewBox="0 0 98 104"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className="path"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 10 10 H 90 V 90 H 10 L 10 10"
                stroke="#FB8C00"
                strokeWidth="2"
              ></path>
            </svg>
          </UiBox>
        </AnimationOnScroll>
      </UiBox>
      <UiBox pos="absolute" bottom="55px" right="-15px">
        <AnimationOnScroll animateIn="animate__slideInLeft">
          <svg
            width={isDesktop ? '7.375em' : '6.25em'}
            height={isDesktop ? '8.5em' : '6.25em'}
            viewBox="0 0 78 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M39 8.99805L78 63.9853H0L39 8.99805Z" fill="#FDD466" />
          </svg>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__zoomIn">
          <UiBox pos="absolute" bottom="20px" transition="2s">
            <svg
              width={isDesktop ? '7.375em' : '5.9375em'}
              height={isDesktop ? '8.5em' : '5.9375em'}
              viewBox="0 0 78 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path className="path" d="M76.046 1L47 41.2803L17.954 1H76.046Z" stroke="black" strokeWidth="1"></path>
            </svg>
          </UiBox>
        </AnimationOnScroll>
      </UiBox>
    </UiBox>
  );
};

export default EaseMobileAnimation;
