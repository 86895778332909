import {useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Col, UiBox, UiButton, UiContainer, UiImage, UiText, UiTitle} from '~/components/elements';
import ContactTime from '~/components/shared/ContactUsTime';
import MeetUsOffline from '~/components/shared/MeetUsOffline';
import {QUERY_MOBILE} from '~/constants';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const ContactSubmitted = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const history = useHistory();

  useScrollToTop();

  return (
    <UiBox pb="50px">
      <UiContainer maxW="container.xl" pos="relative">
        <Col h="100vh" justifyContent={{base: 'center', lg: 'end'}}>
          <Col h={{base: '70%', lg: '90%'}} alignItems="center" justifyContent="space-evenly" textAlign="center">
            <UiTitle color="#FB8C00" fontSize="6xl">
              Thank you for contacting us!
            </UiTitle>
            <UiText fontSize="xl">
              We have well received your message.
              <br />
              Your message will be answered by us promptly the same or next business day!
            </UiText>
            <UiImage filePath="contact/contact.png" w="20em" h="16.125em" />
            <UiButton bgColor="#FB8C00" color="white" mt="20px" onClick={() => history.push('/')}>
              Back to Home Page
            </UiButton>
          </Col>
        </Col>
      </UiContainer>
      <ContactTime bgColor="#98C026" />
      <MeetUsOffline />
    </UiBox>
  );
};

export default ContactSubmitted;
