import {ListItem, UnorderedList} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useDispatch} from 'react-redux';
import {UiBox, UiButton} from '~/components/elements';
import {RootState} from '~/redux/root-reducer';
import {AppDispatch} from '~/redux/root-store';
import {setWhyChooseUsItemId} from '~/redux/ui/slice';

type WhyChooseUsItem = {
  id: number;
  title: string;
};
interface Props {
  id: number;
  title: string;
  items: WhyChooseUsItem[];
}

const renderItems = (items: WhyChooseUsItem[]) => {
  return items.map(item => (
    <ListItem key={item.id} fontSize={{base: '10px', lg: '20px'}} mb={{base: '10px', lg: '20px'}}>
      {item.title}
    </ListItem>
  ));
};

const WhyChooseUsCollapsible = ({id, title, items}: Props) => {
  const [open, setOpen] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const openId = useSelector((state: RootState) => state.ui.whyChooseUsItemId) || 0;

  const handleOpenMenu = () => {
    dispatch(setWhyChooseUsItemId({value: id}));
  };

  useEffect(() => {
    if (id === openId) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [openId, id]);

  return (
    <UiBox w={{base: '100%', lg: '90%'}} borderBottom="1px" _last={{borderBottom: 0}}>
      <UiBox
        onClick={() => {
          if (!open) {
            handleOpenMenu();
          } else {
            dispatch(setWhyChooseUsItemId({value: 0}));
          }
        }}
        fontSize={{base: '18px', lg: '25px'}}
        my={{base: '20px', lg: '40px'}}
        backgroundColor="transparent"
        color="black"
        userSelect="none"
        cursor="pointer"
      >
        {title}
      </UiBox>
      <UnorderedList listStyleType="none">{open ? renderItems(items) : undefined}</UnorderedList>
    </UiBox>
  );
};

export default WhyChooseUsCollapsible;
