import {theme} from '@chakra-ui/react';
import {isArray} from 'lodash';
import React, {memo, useEffect, useRef} from 'react';
import NotificationSystem from 'react-notification-system';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {RootState} from '~/redux/root-reducer';
import {AppDispatch} from '~/redux/root-store';
import {resetTrackerBag, setTracker, setVisitingPage} from '~/redux/tracker/slice';
import {addTrackers} from '~/redux/tracker/thunk';
import {requestOK} from 'redux-thunk-kit';
import {appConfig} from '~/config';
import {TrackerApiItem} from '~/types/models/tracker';
import moment from 'moment';
import {TRACKER_PARAMS, TRACKER_TYPES} from '~/constants/tracker';

interface Props {
  children: React.ReactNode;
}

export const BaseLayout = memo(({children}: Props) => {
  const dispatch: AppDispatch = useDispatch();
  const notificationSystem = useRef(null);
  const {pathname, search} = useLocation();
  const {error, success} = useSelector((state: RootState) => state.ui.messages);
  const trackerLogs = useSelector((state: RootState) => state.trackers.trackerLogs);

  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  useEffect(() => {
    dispatch(setVisitingPage({pathname: `${pathname}${search}`, search}));
    const utmContent = query.get(TRACKER_PARAMS.UTM_CONTENT);
    const utmSource = query.get(TRACKER_PARAMS.UTM_SOURCE);
    if (utmContent && utmSource) {
      const action: TrackerApiItem = {
        duration: 0,
        value: utmContent,
        path: `${pathname}${search}`,
        triggered_at: moment().format(),
        type: utmSource === 'ry_email' ? TRACKER_TYPES.EMAIL : TRACKER_TYPES.LINK,
      };
      dispatch(setTracker(action));
    }
  }, [dispatch, pathname, query, search]);

  useEffect(() => {
    const storeData = async () => {
      if (appConfig.stage === 'development') {
        return;
      }
      if (trackerLogs.length > 0) {
        const params = {
          data: trackerLogs,
        };
        const resAction = await dispatch(addTrackers({data: params}));
        if (requestOK(resAction, addTrackers)) {
          dispatch(resetTrackerBag());
        }
      }
    };

    const interval = setInterval(() => {
      storeData();
    }, 3000);

    return () => clearInterval(interval);
  }, [dispatch, trackerLogs]);

  useEffect(() => {
    if (error) {
      const notification = notificationSystem.current;
      if (notification) {
        if (isArray(error)) {
          // @ts-ignore
          notification.addNotification({
            title: 'Validation error!',
            level: 'error',
            position: 'tc',
            autoDismiss: 5,
            children: (
              <div>
                {error.map(e => (
                  <p key={e}>{e}</p>
                ))}
              </div>
            ),
          });
        } else {
          // @ts-ignore
          notification.addNotification({
            title: 'Oops, something went wrong!',
            message: error,
            level: 'error',
            position: 'tc',
            autoDismiss: 5,
          });
        }
      }
    }
  }, [error]);

  useEffect(() => {
    if (success) {
      const notification = notificationSystem.current;
      if (notification) {
        // @ts-ignore
        notification.addNotification({
          title: 'Success!',
          message: success,
          level: 'success',
          position: 'tc',
          autoDismiss: 5,
        });
      }
    }
  }, [success]);

  return (
    <div>
      <NotificationSystem ref={notificationSystem} style={notifOverrideStyle} />
      {children}
    </div>
  );
});

const notifOverrideStyle = {
  Containers: {
    DefaultStyle: {
      zIndex: theme.zIndices.tooltip,
    },
  },
};
