import React from 'react';
import {AiOutlineClockCircle, AiOutlineUser} from 'react-icons/ai';
import {FaMoneyCheckAlt} from 'react-icons/fa';
import {useHistory} from 'react-router-dom';
import {Col, Row, UiBox, UiDivider, UiGrid, UiIcon, UiImage, UiText} from '~/components/elements';
import {PAGES} from '~/constants';
import {JobApiItem} from '~/types/models/jobs';
import {formatDate} from '~/utils/helpers';

interface Props {
  job: JobApiItem;
}

const JobCard = ({job}: Props) => {
  const history = useHistory();

  return (
    <Col
      key={job.key}
      w="100%"
      bgColor="yellow"
      cursor="pointer"
      boxShadow="rgb(145 158 171 / 10%) -8px 8px 24px -4px"
      backgroundColor="white"
      border="1px solid rgba(227, 227, 227, 0.8)"
      borderRadius="16px"
      overflow="hidden"
      transition="0.4s"
      role="group"
      _hover={{
        boxShadow:
          '0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)',
      }}
      onClick={() => history.push(`${PAGES.JOB}/${job.id}`)}
    >
      <UiBox overflow="hidden" pb="66%" pos="relative">
        <UiImage
          pos="absolute"
          _hover={{transform: 'scale(1.2)'}}
          _groupHover={{transform: 'scale(1.2)'}}
          transition="0.4s"
          src={job.image_thumbnail_url}
          w="100%"
          h="100%"
        />
      </UiBox>
      <Col p={{base: '16px', lg: '10px 20px'}} justifyContent="space-between" h="100%">
        <Col justifyContent="center" h="100%">
          <UiText fontWeight="bold" fontSize="xl">
            {job.title}
          </UiText>
          <UiText color="orange.500">{job.position?.name}</UiText>
          <UiText opacity="0.8" fontSize="sm">
            Post day: {formatDate(job.created_at)}
          </UiText>
          <UiText opacity="0.8" fontSize="sm">
            Expired at: {formatDate(job.expired_at)}
          </UiText>
        </Col>
        <Col>
          <UiDivider m="16px 0px" borderColor="rgba(145, 158, 171, 0.84)" borderStyle="dashed" />
          <UiGrid templateColumns="repeat(1,1fr)" columnGap={{base: 3, lg: 6}} rowGap={2}>
            <Row alignItems="center">
              <UiIcon as={AiOutlineClockCircle} color="black" />
              <UiText>{job.type_str}</UiText>
            </Row>
            <Row alignItems="center">
              <UiIcon as={FaMoneyCheckAlt} color="black" />
              {!job.min_salary || !job.max_salary ? (
                <UiText>Negotiate</UiText>
              ) : (
                <UiText>
                  {job.min_salary} - {job.max_salary} Million VND
                </UiText>
              )}
            </Row>
            <Row alignItems="center">
              <UiIcon as={AiOutlineUser} color="black" />
              {job.levels?.map((level, idx) => {
                return (
                  <Row key={idx} alignItems="center">
                    <UiText mr="5px">{level.name}</UiText>
                  </Row>
                );
              })}
            </Row>
          </UiGrid>
        </Col>
      </Col>
    </Col>
  );
};

export default JobCard;
