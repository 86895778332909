import React from 'react';
import {Col, UiBox, UiContainer, UiIcon, UiText, UiTitle} from '~/components/elements';
import {BsArrowDown} from 'react-icons/bs';
import connectionLine from '~/assets/img/connection-dot.png';
import {useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {ContactUsBtn} from '~/components/shared/ContactUsBtn';
import {color} from '~/theme/color';
import {useLocation} from 'react-router-dom';

interface Props {
  onClickArrowDown?: () => any;
  title: string | JSX.Element;
  description: string;
}

export const UiBanner = React.memo(({onClickArrowDown, title, description}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const location = useLocation();
  const isServicesPath = location.pathname === PAGES.SERVICE;

  return (
    <UiBox
      backgroundImage={`url(${connectionLine})`}
      backgroundPosition="right"
      backgroundRepeat="no-repeat"
      backgroundSize={{base: '100% 100%', lg: 'contain'}}
      pt={50}
      bgColor="white"
      h="100vh"
      whiteSpace={isDesktop ? 'pre-line' : undefined}
    >
      <UiContainer h="100%" maxW="container.xl" w={{base: '80%', lg: '76%'}}>
        <Col h="100%" pb="2rem" justifyContent="center" w={{base: '100%', lg: '75%'}}>
          <Col justify="center">
            <UiTitle fontSize={{base: '4xl', lg: '9xl'}} lineHeight={{base: '40px', lg: '100px'}}>
              {title}
            </UiTitle>
            <UiText fontSize="xl" lineHeight={{base: '23px', lg: '30px'}} mt={8} color="#808080">
              {description}
            </UiText>
            {!isServicesPath && (
              <Col justifyContent="center" alignItems="start" mt="75px">
                <ContactUsBtn />
              </Col>
            )}
            {isDesktop && (
              <UiIcon
                _hover={{cursor: onClickArrowDown ? 'pointer' : undefined}}
                as={BsArrowDown}
                boxSize={70}
                color={color.home.textDescription}
                mt={10}
                alignSelf="flex-start"
                onClick={onClickArrowDown}
              />
            )}
          </Col>
        </Col>
      </UiContainer>
    </UiBox>
  );
});
