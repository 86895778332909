import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiContainer,
  UiGrid,
  UiIcon,
  UiImage,
  UiSimpleGrid,
  UiText,
  UiTitle,
} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import {VscFeedback} from 'react-icons/vsc';
import {AiOutlineDeliveredProcedure} from 'react-icons/ai';
import {BsCodeSlash, BsPlayCircle} from 'react-icons/bs';
import {DiScrum} from 'react-icons/di';
import {FaRegHandshake} from 'react-icons/fa';
import {FiPackage} from 'react-icons/fi';
import {GiCutDiamond, GiTeamIdea} from 'react-icons/gi';
import {HiOutlineChatAlt2, HiOutlineLightBulb} from 'react-icons/hi';
import {ImLoop} from 'react-icons/im';
import {MdOutlineManageSearch} from 'react-icons/md';

const data = [
  {title: 'Your ideas', icon: HiOutlineLightBulb},
  {title: 'Backlogs', icon: FiPackage},
  {title: 'Coding & Testing', icon: BsCodeSlash},
  {title: 'Demo', icon: BsPlayCircle},
  {title: 'Ship it', icon: AiOutlineDeliveredProcedure},
  {title: 'Great product', icon: GiCutDiamond},
];

const comData = [
  {title: 'You & Our Team', icon: GiTeamIdea},
  {title: '', img: 'hww/hww_00.svg'},
  {title: 'Communication Rules', icon: HiOutlineChatAlt2},
  {title: '', img: 'hww/hww_00.svg'},
  {title: 'Interaction Agile Scrum', icon: DiScrum},
  {title: '', img: 'hww/hww_00.svg'},
  {title: 'Feedback Final Accept', icon: VscFeedback},
  {title: '', img: 'hww/hww_00.svg'},
  {title: 'Ongoing Monitoring', icon: MdOutlineManageSearch},
  {title: '', img: 'hww/hww_00.svg'},
  {title: 'Good deal', icon: FaRegHandshake},
];

const HowWeWork = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const minIconWidth = isDesktop ? '130px' : '100px';
  const minComWidth = isDesktop ? '70px' : '70px';

  return (
    <UiBox h="100%" mt={{base: '40px', lg: '100px'}} mb="100px">
      <UiContainer maxW="container.xl" w={{base: '90%', lg: '76%'}}>
        <UiTitle fontWeight="400" fontSize={{base: '4xl', lg: '8xl'}} textAlign="center">
          Rayyone Approach
        </UiTitle>
        <UiText
          fontSize="xl"
          mt={{base: '15px', lg: '21px'}}
          color="#808080"
          lineHeight={{base: '25px', lg: '30px'}}
          textAlign={{base: 'inherit', lg: 'center'}}
        >
          We are all ears for your stories!
          <br /> We know who needs to join the team at which stage and free you up from doing estimation
        </UiText>
        <Row justifyContent="center">
          <UiImage filePath={isDesktop ? 'hww/hww-1.png' : 'hww/hww-2.png'} w="55.5em" mt="40px" />
        </Row>
        <Col mb="20px" mt="100px">
          <UiTitle fontSize="2xl" mb={6} fontWeight="500">
            Product cycle
          </UiTitle>
          <UiBox>
            {isDesktop && (
              <UiSimpleGrid columns={6} spacingX={5} mt={6} pb={3}>
                {data.map((i, idx) => (
                  <Col key={idx} align="center" mx={5}>
                    <UiIcon as={i.icon} boxSize="50px" />
                    <UiText textAlign="center" mt={25} maxW="120px">
                      {i.title}
                    </UiText>
                  </Col>
                ))}
              </UiSimpleGrid>
            )}
            {!isDesktop && (
              <UiGrid
                templateColumns={`repeat(auto-fill, minmax(${minIconWidth}, 1fr))`}
                gridAutoFlow="column"
                gridAutoColumns={`minmax(${minIconWidth}, 1fr)`}
                overflowX="auto"
                overflowY="hidden"
                mt={6}
                pb={3}
              >
                {data.map((i, idx) => (
                  <Col key={idx} align="center" mx={5}>
                    <UiIcon as={i.icon} boxSize="50px" />
                    <UiText textAlign="center" mt={25} maxW="120px">
                      {i.title}
                    </UiText>
                  </Col>
                ))}
              </UiGrid>
            )}
            <Col ml={{base: 5, lg: '12rem'}}>
              <UiBox borderBottomWidth="1px" my="2" w={{base: '70%', lg: '50%'}} />
              <Row align="center">
                <UiIcon as={ImLoop} boxSize="30px" mr={5} />
                <UiText>1-2 weeks iterations</UiText>
              </Row>
            </Col>
          </UiBox>
          <UiBox borderBottomWidth="3px" borderStyle="dashed" my="5" />
          <UiTitle fontSize="2xl" mb={6} fontWeight="500">
            Communication
          </UiTitle>
          {isDesktop && (
            <UiSimpleGrid columns={11} mt={6} pb={3}>
              {comData.map((i, idx) => (
                <Col key={idx} align="center">
                  {i.icon && <UiIcon as={i.icon} boxSize={{base: '30px', lg: '40px'}} />}
                  {i.img && <UiImage filePath={i.img} boxSize={{base: '30px', lg: '40px'}} />}
                  <UiText textAlign="center" mt={25} maxW="120px">
                    {i.title}
                  </UiText>
                </Col>
              ))}
            </UiSimpleGrid>
          )}
          {!isDesktop && (
            <UiSimpleGrid
              templateColumns={`repeat(auto-fill, minmax(${minComWidth}, 1fr))`}
              gridAutoFlow="column"
              gridAutoColumns={`minmax(${minComWidth}, 1fr)`}
              overflowX="auto"
              overflowY="hidden"
              spacingX={0}
              mt={6}
              pb={3}
            >
              {comData.map((i, idx) => (
                <Col key={idx} align="center">
                  {i.icon && <UiIcon as={i.icon} boxSize={{base: '30px', lg: '40px'}} />}
                  {i.img && <UiImage filePath={i.img} boxSize={{base: '30px', lg: '40px'}} />}
                  <UiText textAlign="center" mt={25} maxW="120px">
                    {i.title}
                  </UiText>
                </Col>
              ))}
            </UiSimpleGrid>
          )}
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default HowWeWork;
