import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiText} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Exam3 = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  const renderDesktopImage = () => {
    return (
      <UiBox pos="relative" w="50%" h="35em">
        <UiImage
          filePath="story/ease-5.png"
          h="35em"
          transform="rotate(9.88deg)"
          pos="absolute"
          top="-10%"
          right="0px"
        />
        <UiImage filePath="story/ease-2.png" w="19.375em" h="6.25em" pos="absolute" top="52%" left="15%" />
        <UiImage filePath="story/feature.png" w="21.25em" h="7.5em" pos="absolute" top="25%" left="20%" />
        <UiImage filePath="story/feature-1.png" w="25.875em" h="9.375em" pos="absolute" top="35%" left="5%" />
      </UiBox>
    );
  };

  const renderMobileImage = () => {
    return (
      <Row w="100%" justifyContent="flex-end">
        <Row h="17.75em" minW="8.75em" pos="relative">
          <UiImage
            filePath="story/ease-5.png"
            h="17.75em"
            transform="rotate(9.88deg)"
            pos="absolute"
            top="-10%"
            right="5%"
            zIndex="2"
          />
          <UiImage
            filePath="story/ease-2.png"
            maxW="initial"
            h="4em"
            pos="absolute"
            top="35%"
            left="-115%"
            zIndex="1"
          />
          <UiImage
            filePath="story/feature.png"
            h="4.625em"
            pos="absolute"
            top="5%"
            left="-120%"
            zIndex="1"
            maxW="initial"
          />
          <UiImage
            filePath="story/feature-1.png"
            h="5.625em"
            pos="absolute"
            top="15%"
            left="-155%"
            zIndex="1"
            maxW="initial"
          />
        </Row>
      </Row>
    );
  };

  return (
    <Col pb="100px" pt={{base: '30px', lg: '0px'}} overflow={{base: 'hidden', lg: 'inherit'}}>
      <UiBox pos="relative" bgColor="#456762" w="100%">
        <UiContainer h="100%" maxW="container.xl" pos="relative">
          <UiBox
            as={isDesktop ? Row : Col}
            m={{base: '10px 0px', lg: '0px '}}
            justifyContent={{base: 'center', lg: 'space-between'}}
            alignItems={{base: 'center'}}
          >
            {!isDesktop && renderMobileImage()}
            <Col
              pb={{base: '20px'}}
              fontSize={{base: 'md', lg: '3xl'}}
              color="white"
              w={{base: '90%', lg: '38%'}}
              textAlign="center"
              lineHeight={{base: '20px', lg: '40px'}}
            >
              <UiText mb={{base: '10px', lg: '20px'}}>
                Community with more than{' '}
                <UiText as="span" fontWeight="bold">
                  15.000+
                </UiText>{' '}
                users,{' '}
                <UiText as="span" fontWeight="bold">
                  9.000+
                </UiText>{' '}
                topics,{' '}
                <UiText as="span" fontWeight="bold">
                  40.000+
                </UiText>{' '}
                comments and reactions.
              </UiText>
              <UiText mb={{base: '10px', lg: '20px'}}>Notifications system for new activities update.</UiText>
              <UiText>Firebase events and funnels tracking.</UiText>
            </Col>
            {isDesktop && renderDesktopImage()}
          </UiBox>
        </UiContainer>
      </UiBox>
      <UiContainer maxW="container.xl" pos="relative" w={{base: '100%', lg: '90%'}} mt={{base: '0px', lg: '100px'}}>
        <UiBox
          as={isDesktop ? Row : Col}
          h={{base: '100%', lg: '33.375em'}}
          m={{base: '100px 0px', lg: '150px 0px'}}
          justifyContent="space-between"
        >
          <Row w={{base: '100%', lg: '50%'}} alignItems="end" justifyContent={{base: 'space-evenly', lg: 'center'}}>
            <UiBox w={{base: '8.4375em', lg: '17.5em'}} h={{base: '17.125em', lg: '33.375em'}} pos="relative" mr="20px">
              <UiImage filePath="story/ease-22.png" w="100%" h={{base: '17.125em', lg: '33.375em'}} />
              <UiBox
                pos="absolute"
                bgColor="#B2BFB3"
                w={{base: '12em', lg: '20em'}}
                h={{base: '12em', lg: '20em'}}
                top="-25%"
                zIndex="-1"
                borderRadius="50%"
                opacity="0.2"
                left={{base: '-22.5%', lg: '-7.5%'}}
              />
            </UiBox>
            <UiBox w={{base: '7.25em', lg: '14.75em'}} h={{base: '15.5em', lg: '30em'}} pos="relative">
              <UiImage filePath="story/video.png" h={{base: '15.5em', lg: '30em'}} opacity="0.3" />
              <UiImage
                filePath="story/video.png"
                h={{base: '15.5em', lg: '30em'}}
                pos="absolute"
                top="-10%"
                left="10%"
              />
              <UiBox
                pos="absolute"
                bgColor="#B2BFB3"
                w={{base: '6em', lg: '10em'}}
                h={{base: '6em', lg: '10em'}}
                bottom="-10%"
                zIndex="-1"
                borderRadius="50%"
                opacity="0.2"
                right="-40%"
              />
            </UiBox>
          </Row>
          <Row
            w={{base: '100%', lg: '40%'}}
            h="100%"
            mt={{base: '50px', lg: '0px'}}
            alignItems="center"
            justifyContent="center"
          >
            <UiText fontSize={{base: 'md', lg: '4xl'}} textAlign="center">
              Appointments booking system to connect professors and users
            </UiText>
          </Row>
        </UiBox>
        <Row mt={{lg: '100px'}}>
          <Row
            w={{base: '60%', lg: '50%'}}
            h={{base: '18.125em', lg: '37em'}}
            alignItems={{lg: 'center'}}
            justifyContent="center"
            pt={{base: '20px'}}
            pr={{base: '10px'}}
          >
            <UiText fontSize={{base: 'md', lg: '4xl'}} textAlign={{base: 'end', lg: 'center'}}>
              Doctor Chats feature with hassle-free service purchases via a wide range of payment methods
            </UiText>
          </Row>
          <Row w={{base: '40%', lg: '50%'}} alignItems="center" justifyContent="flex-end">
            <Row w={{base: '9em', lg: '19em'}} pos="relative">
              <UiImage filePath="story/ease-23.png" h={{base: '18.125em', lg: '37em'}} />
              <UiImage
                filePath="story/ease-24.png"
                h={{base: '3.75em', lg: '8.5em'}}
                pos="absolute"
                left="-80%"
                top="40%"
              />
              <UiBox
                pos="absolute"
                bgColor="#B2BFB3"
                w={{base: '10em', lg: '18em'}}
                h={{base: '10em', lg: '18em'}}
                bottom="-5%"
                zIndex="-1"
                borderRadius="50%"
                opacity="0.2"
                left="-45%"
              />
            </Row>
          </Row>
        </Row>
      </UiContainer>
    </Col>
  );
};

export default Exam3;
