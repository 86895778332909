import {useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Row, UiBox, UiButton, UiText, UiTitle} from '~/components/elements';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {RAYYONE_DOC_URLS} from '~/constants/ry-tool';

const GetStarted = () => {
  const history = useHistory();
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox p={{base: '20px 25px', lg: '72px 116px'}} bgColor="#F4F4F4">
      <Col p={{base: '30px 10px', lg: '60px 0px'}} bgColor="white" textAlign="center" borderRadius="30px">
        <UiTitle fontSize={{base: 'xl', lg: '4xl'}}>
          Leverage RYTools,
          <br />
          Boost your productivity today
        </UiTitle>
        <UiText mt="10px">
          Contact us now! Share with us your story and we will revert to you with the most
          {isDesktop && <br />} strategic plan of Low Cost - High Quality - Done Quickly
        </UiText>
        <Row mt="20px" justifyContent="center">
          <UiButton
            mr="20px"
            bgColor="black"
            color="white"
            fontSize={{base: 'sm', lg: 'md'}}
            onClick={() => history.push(PAGES.CONTACT_US)}
          >
            Get Started
          </UiButton>
          <UiButton
            bgColor="transparent"
            color="black"
            fontSize={{base: 'sm', lg: 'md'}}
            border="1px solid #000000"
            onClick={() => window.open(RAYYONE_DOC_URLS.HOME, '_blank')}
          >
            Watch Tutorials
          </UiButton>
        </Row>
      </Col>
    </UiBox>
  );
};

export default GetStarted;
