import React, {ChangeEvent, useState} from 'react';
import {
  Col,
  UiBox,
  UiContainer,
  UiInput,
  UiText,
  UiTitle,
  UiRadioGroup,
  UiRadio,
  Row,
  UiButton,
  UiTextarea,
  UiFormControl,
  UiFormHelperText,
  UiFormLabel,
} from '~/components/elements';
import {ComponentProps} from '~/types/models/app';
import {JobApiItem, JobEntity} from '~/types/models/jobs';
import {useInput, useNotification} from '~/hooks';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '~/redux/root-store';
import request from '~/utils/request';
import {ENDPOINTS} from '~/constants';
import UploadFile from '~/components/shared/UploadFile';

interface Props {
  job?: JobApiItem;
}

const BoxRadio = (p: ComponentProps) => (
  <UiBox
    as={p.type === 'Row' ? Row : Col}
    color="black"
    sx={{
      '&>label': {
        bg: 'white',
        borderRadius: '12px',
        mb: '8px',
        p: '10px 20px',
        transition: '0.5s',
        mr: '20px',
      },
    }}
    {...p}
  />
);

const InputStyle = {
  variant: 'out',
  color: 'black',
  bgColor: 'white',
  mt: '10px',
  boxShadow: '0 0 #0000,0 0 #0000,0 2px 27px -10px rgba(0,0,0,.18)',
};

interface InputParams extends Partial<JobEntity> {
  job_id?: string;
}

const getDefaultParams = (item?: Partial<JobEntity> | undefined): InputParams => ({
  email: item?.email || '',
  phone: item?.phone || '',
  full_name: item?.full_name || '',
  salary: item?.salary || undefined,
  salary_preferences: item?.salary_preferences || undefined,
  note: item?.note || '',
  cv_file: item?.cv_file || undefined,
  job_id: item?.id,
});

const Form = ({job}: Props) => {
  const [fileName, setFileName] = useState<string>('');
  const dispatch: AppDispatch = useDispatch();
  const {addMessage} = useNotification(dispatch);

  const rules = {
    email: {email: {message: '^Please enter a valid email address'}, presence: {message: '^This field is required'}},
    phone: {numericality: {message: '^Invalid phone number'}, length: {minimum: 9, maximum: 11}},
    full_name: {presence: {message: '^This field is required'}},
    cv_file: {presence: {message: '^This field is required'}},
  };
  const defaultParams = getDefaultParams(job || {});
  const {inputs, setInputs, validation, resetInput} = useInput(defaultParams, {rules});

  const onChooseFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFileName(event.target.files[0].name);
      const file = event.target.files[0];
      setInputs({cv_file: file});
    }
  };

  const handleClearFile = () => {
    setFileName('');
    setInputs({cv_file: undefined});
  };

  const onClickSubmit = async (e: React.FormEvent) => {
    e?.preventDefault();

    if (validation.getErrors()) {
      return;
    }
    let formData = new FormData();
    formData.append('email', inputs.email || '');
    formData.append('full_name', inputs.full_name || '');
    formData.append('phone', inputs.phone || '');
    formData.append('cv_file', inputs.cv_file || '');
    formData.append('note', inputs.note || '');
    formData.append('salary', inputs.salary || '');
    formData.append('salary_preferences', inputs.salary_preferences || '');
    const resAction = await request.post(`${ENDPOINTS.JOBS}/${job?.id}/apply`, formData);
    if (resAction.data.status === 'success') {
      resetInput();
      setFileName('');
      addMessage('Thanks for contacting us. Your request has been submitted successfully!');
    }
  };

  return (
    <UiBox bg="orange.400">
      <UiContainer
        h="100%"
        maxW="container.xl"
        pb="40px"
        pos="relative"
        w={{base: '100%', lg: '76%'}}
        pt="50px"
        color="white"
      >
        <Col h="100%" alignItems="center" justifyContent="center" textAlign="center" pos="relative">
          <UiTitle fontSize={{base: '2xl', lg: '7xl'}} mt="20px">
            {job?.title}
          </UiTitle>
          <UiText fontSize={{base: 'lg', lg: '2xl'}} mt="20px">
            ⭐️ Hey! Welcome to the Rayyone application form! To start your career with us, please fill out the form in
            English!
          </UiText>
        </Col>
        <Col alignItems="center">
          <Col mt="50px" w={{base: '100%', lg: '52%'}}>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Full name*</UiFormLabel>
                <UiInput
                  {...InputStyle}
                  value={inputs.full_name}
                  onChange={({target}) => setInputs({full_name: target.value})}
                />
                {validation?.errors?.full_name && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.full_name[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Phone number*</UiFormLabel>
                <UiInput
                  {...InputStyle}
                  value={inputs.phone}
                  onChange={({target}) => setInputs({phone: target.value})}
                />
                {validation?.errors?.phone && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.phone[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Email*</UiFormLabel>
                <UiInput
                  {...InputStyle}
                  value={inputs.email}
                  onChange={({target}) => setInputs({email: target.value})}
                />
                {validation?.errors?.email && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.email[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Monthly salary expectation </UiFormLabel>
                <UiInput
                  {...InputStyle}
                  value={inputs.salary}
                  onChange={({target}) => setInputs({salary: target.value})}
                />
                {validation?.errors?.salary && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.salary[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Salary preferences </UiFormLabel>
                <UiText fontSize="sm">Please specify whether the expected salary is indicated in Gross or Net!</UiText>
                <UiRadioGroup defaultValue="2" mt="10px">
                  <BoxRadio type="Row">
                    <UiRadio
                      value="0"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setInputs({salary_preferences: 0})}
                    >
                      GROSS
                    </UiRadio>
                    <UiRadio
                      value="1"
                      onChange={(event: ChangeEvent<HTMLInputElement>) => setInputs({salary_preferences: 1})}
                    >
                      NET
                    </UiRadio>
                  </BoxRadio>
                </UiRadioGroup>
                {validation?.errors?.salary_preferences && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.salary_preferences[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Upload CV*</UiFormLabel>
                <UploadFile fileName={fileName} onChooseFile={onChooseFile} handleClearFile={handleClearFile} />
                {validation?.errors?.cv_file && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.cv_file[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px">
              <UiFormControl>
                <UiFormLabel>Note </UiFormLabel>
                <UiTextarea
                  rows={9}
                  variant="out"
                  color="black"
                  bgColor="white"
                  mt="10px"
                  placeholder="Type an answer..."
                  boxShadow="0 0 #0000,0 0 #0000,0 2px 27px -10px rgba(0,0,0,.18)"
                  borderRadius="20px"
                  value={inputs.note}
                  onChange={({target}) => setInputs({note: target.value})}
                />

                {validation?.errors?.note && (
                  <UiFormHelperText mt="8px" color="red" fontSize="13px">
                    {validation.errors.note[0]}
                  </UiFormHelperText>
                )}
              </UiFormControl>
            </Col>
            <Col mt="20px" alignItems="center">
              <UiButton
                p="30px"
                borderRadius="30px"
                w="50%"
                _hover={{transform: ' translateY(-5px)  '}}
                onClick={onClickSubmit}
              >
                Submit application
              </UiButton>
            </Col>
          </Col>
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default Form;
