import {appConfig} from '~/config';

const API_HOST = appConfig.api.host;
const API_URL = appConfig.api.url;

export const ENDPOINTS = {
  LOGIN: `login`,
  SOCIAL_LOGIN: `auth/social/login`,
  LOGOUT: `auth/logout`,
  CURRENT_USER: `me`,
  MEDICATIONS: `medications`,
  USERS: `users`,
  TABLE_STATES: `users/table_states`,
  TABLE_STATES_DELETE: `users/table_states/delete`,
  FILE_UPLOAD: 'FILE_UPLOAD',
  ADMINS: 'admins',
  ROLES: 'roles',
  GROUPS: 'groups',
  VISITORS: 'visitors',
  TRACKERS: 'trackers',
  BULK_TRACKERS: 'trackers/bulk',
  COUPONS: 'coupons',
  SUBSCRIBER: 'subscribers',
  LEVELS: 'levels',
  POSITIONS: 'positions',
  JOBS: 'jobs',
  APPLY_JOBS: 'apply_jobs',
  MAIL_UNSUBSCRIBES: 'unsubscribes',
};
