import React from 'react';
import {UiBox, UiContainer, UiLink, UiText} from '~/components/elements';
import {UiTitle} from '~/components/elements/UiTitle';
import ContactUsTime from '~/components/shared/ContactUsTime';
import {CALENDLY_LINK} from '~/constants/coupon';

const HowDoWeStart = () => {
  return (
    <UiBox h="100%" textAlign="center">
      <UiContainer
        h="100%"
        maxW="container.xl"
        pt="40px"
        pos="relative"
        w={{base: '90%', lg: '76%'}}
        fontSize={{base: 'sm', lg: 'md'}}
        lineHeight={{base: '16px', lg: '20px'}}
      >
        <UiTitle fontSize={{base: '2xl', lg: '5xl'}} lineHeight="40px">
          How do we start?
        </UiTitle>
        <UiText mt="25px">Share with us your story!</UiText>
        <UiText mt="15px">
          Schedule a one-hour meeting with our Senior leadership to look at
          <br /> where you are now and where you want to be.
        </UiText>
        <UiText mt="15px">Usually, we can meet within a few days and start work within one to two weeks.</UiText>
        <UiText mt="35px" fontSize={{base: 'md', lg: 'lg'}} lineHeight={{base: '18px', lg: '28px'}} fontWeight="bold">
          <UiLink href={CALENDLY_LINK} target="_blank">
            Schedule the meeting &gt;
          </UiLink>
        </UiText>
      </UiContainer>
      <ContactUsTime />
    </UiBox>
  );
};

export default HowDoWeStart;
