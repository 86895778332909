import React, {useEffect, useState} from 'react';
import {Col, Row, UiBox, UiContainer, UiGrid, UiIcon, UiImage, UiTitle} from '~/components/elements/index';
import EaseWebStory from '~/pages/story/components/easeAppStory/index';
import background from '~/assets/img/story/background.png';
import * as Scroll from 'react-scroll';
import '~/assets/css/main.css';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import {HiArrowRight} from 'react-icons/hi';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {setShowModalEaseApp} from '~/redux/ui/slice';
import {AppDispatch} from '~/redux/root-store';
import {arrowAnimation} from '~/constants/animation';

const Element = Scroll.Element;

const EaseAppBanner = () => {
  const dispatch: AppDispatch = useDispatch();
  const openModal = useSelector((state: RootState) => state.ui.isShowModalEaseApp);
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const [isOpenModal, setOpenModal] = useState<boolean>(openModal || false);

  useEffect(() => {
    dispatch(setShowModalEaseApp({value: false}));
  }, [dispatch]);

  const renderDesktopImage = () => {
    return (
      <UiBox h="100%" pos="relative" w="100%" overflow="hidden">
        <UiImage filePath="story/ease-15.png" pos="absolute" h="20em" top="-20%" left="5%" />
        <UiImage filePath="story/ease-16.png" pos="absolute" h="22em" top=" 25%" left="35%" />
        <UiImage filePath="start-up/status-ring-1.png" pos="absolute" h="10em" bottom="22%" right="20%" />
        <UiImage filePath="start-up/reply.png" pos="absolute" h="5.5em" bottom="20%" left="5%" />
      </UiBox>
    );
  };

  const renderMobileImage = () => {
    return (
      <Row mt="50px" justifyContent="center">
        <UiBox h="22em" w="11em" pos="relative">
          <UiImage filePath="story/ease-15.png" h="100%" w="100%" />
          <UiImage filePath="start-up/status-ring-1.png" pos="absolute" h="9.125em" top="25%" right="-55%" />
        </UiBox>
      </Row>
    );
  };

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <UiBox mt={{base: '20px'}} mb={{lg: '100px'}}>
      <Element name="easeAppBanner">
        <UiContainer maxW="container.xl" w={{base: '100%', lg: '90%'}} pos="relative" overflow="hidden">
          <UiBox
            bgColor="#456762"
            backgroundImage={isDesktop ? `url(${background})` : ''}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            h={{base: '100%', lg: '30em'}}
            pos={isOpenModal ? 'absolute' : 'relative'}
            cursor="pointer"
            borderRadius="20px"
            className={
              isOpenModal
                ? 'animate__animated animate__zoomOut animate__duration-1s'
                : 'animate__animated animate__zoomIn animate__duration-2s'
            }
            onClick={() => setOpenModal(true)}
          >
            <UiGrid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}} h="100%" w="100%">
              {isDesktop ? renderDesktopImage() : renderMobileImage()}
              <Col w={{base: '100%', lg: '90%'}} alignItems="center" color="white">
                <UiImage filePath="start-up/ease.png" h={{base: '2em', lg: '2.5em'}} mt={{base: '50px', lg: '80px'}} />
                <UiTitle fontWeight="400" fontSize="2xl" textAlign="center" mt={{base: '30px', lg: '60px'}}>
                  {isDesktop
                    ? 'Build from scratch the all-in-one women super-app, with average new feature launched less than 2 weeks'
                    : 'Build from scratch the all-in-one women super-app'}
                </UiTitle>
                <Row mt="30px" w="100%" justifyContent="space-evenly" alignItems="center">
                  <UiImage filePath="story/aws.png" h={{base: '1.5em', lg: '2.5em'}} />
                  <UiImage filePath="story/react.png" h={{base: '1.5em', lg: '2.5em'}} />
                  <UiImage filePath="story/go.png" h={{base: '1.5em', lg: '1.75em '}} />
                  <UiImage filePath="story/js.png" h={{base: '1.5em', lg: '2.5em'}} />
                  <UiImage filePath="story/php.png" h={{base: '1em', lg: '2em'}} />
                </Row>
                <Row
                  my={{base: '30px', lg: '50px'}}
                  pb="20px"
                  alignItems="center"
                  border="2px"
                  borderColor="orange.200"
                  borderRadius="5px"
                  borderRight="0"
                  paddingX="1em"
                  paddingY="0.2em"
                  pos="relative"
                >
                  <UiTitle fontWeight="400" fontSize="xl" color="orange.300">
                    Expand
                  </UiTitle>
                  <UiIcon
                    as={HiArrowRight}
                    color="orange.200"
                    pos="absolute"
                    right={{base: '-10px', lg: '-20px'}}
                    animation={`${arrowAnimation} .4s linear infinite alternate`}
                  />
                </Row>
              </Col>
            </UiGrid>
          </UiBox>
        </UiContainer>
      </Element>
      {isOpenModal && <EaseWebStory onClose={onClose} />}
    </UiBox>
  );
};

export default EaseAppBanner;
