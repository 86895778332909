/* eslint-disable react/prop-types */
import {difference, isEmpty, map, omit} from 'lodash';
import React, {memo} from 'react';
import {IconType} from 'react-icons/lib';
import {BrowserRouter, Redirect, Route, RouteProps, Switch} from 'react-router-dom';
import {CustomSwitch} from '~/components/CustomSwitch';
import {UserRole} from '~/types/models/user';

export interface BasicRoute extends RouteProps {
  position?: 'left' | 'right';
  path: string;
  redirect?: string;
  routes?: BasicRoute[];
  action?: 'new-tab';
  label?: string;
  icon?: IconType;
  class?: string;
}

export interface PrivateRoute extends BasicRoute {
  permissions?: string[];
  routes?: PrivateRoute[];
}

export interface AppRouterProps {
  basename?: string;
  authorities?: UserRole[];
  defaultRedirect: string;
  privateRoutes?: PrivateRoute[];
  publicRoutes?: BasicRoute[];
  notFoundPage?: React.FunctionComponent<any>;
  unauthorizedPage?: React.FunctionComponent<any>;
}

const omitRouteRenderProps = (route: BasicRoute) => {
  return omit(route, ['render', 'component']);
};

const checkPermissions = (authorities: UserRole[] = [], permissions: string[] = []) => {
  if (isEmpty(permissions)) {
    return true;
  }
  const rolesCanNotAccess = difference(
    authorities.map(r => r.value),
    permissions,
  );
  return rolesCanNotAccess.length < authorities.length;
};

const NotFoundPageDefault = () => {
  return <Redirect to={'/'} />;
};

const UnauthorizedPageDefault = () => {
  return (
    <div>
      <h1>This is an unauthorized page by default</h1>
    </div>
  );
};

export const AppRouter = memo(function AppRouter({
  basename,
  authorities,
  defaultRedirect,
  privateRoutes = [],
  publicRoutes = [],
  notFoundPage: NotFoundPage = NotFoundPageDefault,
  unauthorizedPage: UnauthorizedPage = UnauthorizedPageDefault,
}: AppRouterProps) {
  const renderRedirectRoute = (route: BasicRoute) => (
    <Route
      key={`redirect-${route.path}`}
      {...omitRouteRenderProps(route)}
      render={() => <Redirect to={route.redirect || defaultRedirect} />}
    />
  );

  const renderPublicRoute = (route: BasicRoute, parentPath = '') => {
    const {
      path,
      component: RouteComponent = ({children}: {children: React.ReactNode}) => <div>{children}</div>,
      exact,
    } = route;
    const fullPath = parentPath && parentPath !== '/' ? `${parentPath}${path}` : path;

    // Handle redirect URLs
    if (route.redirect) {
      return renderRedirectRoute(route);
    }

    return !route.routes ? (
      <Route
        key={`public-${fullPath}`}
        exact={exact === undefined ? true : exact}
        {...omitRouteRenderProps(route)}
        path={fullPath}
        render={props => <RouteComponent {...props} />}
      />
    ) : (
      <Route
        key={`public-${fullPath}`}
        exact={exact === undefined ? true : exact}
        {...omitRouteRenderProps(route)}
        path={fullPath}
        render={props => (
          <RouteComponent {...props}>
            <Switch>
              {route?.routes?.map(subRoute => renderPublicRoute(subRoute, fullPath))}
              {renderNotFoundRoute()}
            </Switch>
          </RouteComponent>
        )}
      />
    );
  };

  const renderPrivateRoute = (route: PrivateRoute, parentPath = '') => {
    const {
      permissions,
      redirect,
      path,
      component: RouteComponent = ({children}: {children: React.ReactNode}) => <div>{children}</div>,
      exact,
    } = route;
    const hasPermission = checkPermissions(authorities, permissions);
    const fullPath = parentPath && parentPath !== '/' ? `${parentPath}${path}` : path;

    if (redirect) {
      return renderRedirectRoute(route);
    }
    if (!hasPermission) {
      if (redirect) {
        return renderRedirectRoute(route);
      }
      return (
        <Route
          key={`private-${fullPath}`}
          {...omitRouteRenderProps(route)}
          path={fullPath}
          render={props => <UnauthorizedPage {...props} />}
        />
      );
    }

    return !route.routes ? (
      <Route
        key={`private-${fullPath}`}
        exact={exact === undefined ? true : exact}
        {...omitRouteRenderProps(route)}
        path={fullPath}
        render={props => <RouteComponent {...props} />}
      />
    ) : (
      <Route
        key={`private-${fullPath}`}
        exact={exact === undefined ? true : exact}
        {...omitRouteRenderProps(route)}
        path={fullPath}
        render={props => (
          <RouteComponent {...props}>
            <Switch>
              {route?.routes?.map(subRoute => renderPrivateRoute(subRoute, fullPath))}
              {renderNotFoundRoute()}
            </Switch>
          </RouteComponent>
        )}
      />
    );
  };

  const renderNotFoundRoute = () => {
    return <Route path="*" key="notfound-route" render={props => <NotFoundPage {...props} />} />;
  };

  return (
    <BrowserRouter basename={basename}>
      <CustomSwitch>
        {map(publicRoutes, route => renderPublicRoute(route))}
        {/*{map(privateRoutes, route => renderPrivateRoute(route))}*/}
        {renderNotFoundRoute()}
      </CustomSwitch>
    </BrowserRouter>
  );
});
