import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {TrackerEntity} from '~/types/models/tracker';

class EntityConfig extends BaseEntityConfig<TrackerEntity> {
  entityKey = 'tracker';

  associates = [];

  whiteListFields: WhiteListFields<TrackerEntity> = ['*'];

  getAccessors = (value: TrackerEntity) => {};
}

const trackerEntConf = new EntityConfig();

export const trackerSchema = trackerEntConf.getEntitySchema();

export default trackerEntConf;

export const SOURCES_TRACKER = {
  TRACKER_PAGE: 'TRACKER_PAGE',
  TRACKER_DROPDOWN: 'TRACKER_DROPDOWN',
};
