import React from 'react';
import {Col, Row, UiBox, UiText, UiTitle} from './index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import parse from 'html-react-parser';

interface Props {
  content: {
    title: string;
    badges: string[];
    description: string;
    color: string;
  };
}

export const AppPostBadge = ({content}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  return (
    <Col mb={{base: '20px', lg: '30px'}}>
      <UiBox as={isDesktop ? Row : Col} alignItems={{base: 'baseline', lg: 'center'}}>
        <UiTitle fontSize={{base: 'lg', lg: '2xl'}} mr="10px" fontWeight="400">
          {content.title}
        </UiTitle>
        <Row flexWrap="wrap" alignItems="center">
          {content.badges.map((badge, idx1) => {
            return (
              <Row
                bgColor={content.color}
                borderRadius="30px"
                alignItems="center"
                mr={{base: '5px', lg: '10px'}}
                mb={{base: '5px', lg: '0px'}}
                key={idx1}
              >
                <UiText color="white" fontSize={{base: 'xs', lg: 'lg'}} p={{base: '3px 10px', lg: '7px 10px'}}>
                  {badge}
                </UiText>
              </Row>
            );
          })}
        </Row>
      </UiBox>
      <UiText fontSize={{base: 'md', lg: 'lg'}} mt={{base: '13px', lg: '5px'}}>
        {parse(content.description)}
      </UiText>
    </Col>
  );
};
