import React, {useEffect, useState} from 'react';
import {Col, Row, UiBox, UiContainer, UiImage} from '~/components/elements';
import {MEMBERS} from '~/constants/service';
import {ComponentProps} from '~/types/models/app';
import {randomNumberRanges, sleep} from '~/utils/helpers';

const AMOUNT_OF_MEMBER = 2;

const Balloon = (p: ComponentProps) => <UiBox pos="absolute" borderRadius="50%" w={p.size} h={p.size} {...p} />;

const getRandomMembers = (amount: number) => {
  let cloneMembers = [...MEMBERS];
  let randomMembers = [];
  for (let i = 0; i < amount; i++) {
    if (randomMembers.length === amount) {
      break;
    }
    const randomIdx = randomNumberRanges(cloneMembers.length);
    randomMembers.push(cloneMembers[randomIdx]);
    cloneMembers.splice(randomIdx, 1);
  }
  return randomMembers;
};

const ScrumTeam = () => {
  const [rerenderAt, forceRerender] = useState(Date.now());
  const [membersShouldShow, setMembersShouldShow] = useState<string[]>([MEMBERS[0], MEMBERS[MEMBERS.length - 1]]);

  useEffect(() => {
    const runAnimation = async () => {
      setMembersShouldShow(getRandomMembers(AMOUNT_OF_MEMBER));
      await sleep(4000);
      forceRerender(Date.now());
    };
    runAnimation();
  }, [rerenderAt]);

  return (
    <UiBox ml={{base: '0', lg: '50px'}} mt={{base: '40px', lg: '0'}}>
      <UiContainer h="80%" maxW="container.xl" w={{base: '85%', lg: '46%'}} mr="auto" ml="auto" pos="relative">
        <Col alignItems="center" pos="relative" pb="50px">
          <Row
            w={{base: '300px', lg: '405px'}}
            h={{base: '300px', lg: '405px'}}
            border="3px solid #D8D8D8"
            pos="relative"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
          >
            <UiBox pos="absolute" width="93%" height="93%" borderRadius="50%" border="3px solid #D8D8D8" />
            <UiImage
              filePath="scrum-team/phuoc.png"
              height={{base: '4.375em', lg: '7.125em'}}
              pos="absolute"
              top="20px"
              left="-20px"
            />
            <UiImage
              filePath={membersShouldShow[0]}
              height={{base: '3.4375em', lg: '5.625em'}}
              pos="absolute"
              top={{base: '210px', lg: '290px'}}
              right="0px"
            />
            <UiImage
              filePath="scrum-team/nhi.png"
              height={{base: '3.575em', lg: '6.375em'}}
              pos="absolute"
              top={{base: '65px', lg: '50px'}}
              right={{base: '-15px', lg: '-40px'}}
            />
            <UiImage
              filePath={membersShouldShow[1]}
              pos="absolute"
              top={{base: '240px', lg: '330px'}}
              left={{base: '40px', lg: '45px'}}
              height={{base: '3.4375em', lg: '5.625em'}}
            />
            <Balloon
              size="7px"
              bgColor="#FB8C00"
              left={{base: '155px', lg: '270px'}}
              top={{base: '-10px', lg: '5px'}}
              boxShadow="0 0 5px 10px #FB8C00"
            />
            <Balloon
              size="30px"
              bgColor="#FDD466"
              left={{base: '-10px', lg: '-4px'}}
              top={{base: '190px', lg: '285px'}}
              boxShadow="0 0 5px 10px #FDD466"
            />
            <Balloon
              size="20px"
              bgColor="#95DCBC"
              left={{base: '170px', lg: '250px'}}
              top={{base: '280px', lg: '390px'}}
              boxShadow="0 0 5px 10px #95DCBC"
            />
          </Row>
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default ScrumTeam;
