import React, {useEffect, useRef} from 'react';
import {UiBox} from '~/components/elements';
import {useScrollToTop} from '~/hooks/useScrollToTop';
import Banner from '~/pages/career/components/Banner';
import LatestVacancies from '~/pages/career/components/LatestVacancies';
import Vacancies from '~/pages/career/components/Vacancies';

const CareerPage = () => {
  const vacanciesRef = useRef<HTMLDivElement>(null);
  useScrollToTop();

  return (
    <UiBox pb="100px">
      <Banner
        onClickDownArrow={() => {
          vacanciesRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
      />
      <Vacancies ref={vacanciesRef} />
      <LatestVacancies />
    </UiBox>
  );
};

export default CareerPage;
