import {Icon, IconProps} from '@chakra-ui/react';
import React, {memo} from 'react';
import {IconType} from 'react-icons';

interface Props extends IconProps {
  as: IconType;
}

export const UiIcon = memo(function UiIcon({color, mx = 1.5, boxSize = 5, ...rest}: Props) {
  return <Icon color={color || '#fb8c00'} mx={mx} boxSize={boxSize} {...rest} />;
});
