import React, {forwardRef, useEffect} from 'react';
import {Col, Row, UiBox, UiContainer, UiGrid, UiGridItem, UiIcon, UiText, UiTitle} from '~/components/elements';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {techStacks} from '~/constants/index';
import {AppDispatch} from '~/redux/root-store';
import {choosePosition} from '~/redux/jobs/slice';
import {useHistory} from 'react-router-dom';
import {fetchPositions} from '~/redux/positions/thunk';
import {SOURCES_POSITION} from '~/redux/positions/entity-config';
import {getPositions} from '~/redux/positions/slice';
import {PositionApiItem} from '~/types/models/positions';
import {GET_POSITION_TOTAL_JOB} from '~/constants/index';

const Vacancies = React.memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const dispatch: AppDispatch = useDispatch();
    const history = useHistory();
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const colorText = useColorModeValue('black', 'white');
    const {data: positions} = useSelector(getPositions);

    useEffect(() => {
      const fetchData = async () => {
        await dispatch(fetchPositions({source: SOURCES_POSITION.POSITIONS_PAGE, with: GET_POSITION_TOTAL_JOB}));
      };
      fetchData();
    }, [dispatch]);

    const handleChoosePosition = (position: PositionApiItem) => {
      dispatch(choosePosition(position));
      history.push(PAGES.JOB);
    };

    return (
      <UiBox ref={ref}>
        <Col
          h={isDesktop ? '100vh' : '100%'}
          pt={{base: '70px', lg: '20px'}}
          justifyContent="center"
          sx={{scrollMarginTop: `${menuHeight}px`}}
        >
          <UiContainer maxW="container.xl" pb="40px" pos="relative" w={{base: '89%', lg: '76%'}} overflow="hidden">
            <Col textAlign="center" mb="50px">
              <UiTitle fontSize="5xl">Opportunities at Rayyone</UiTitle>
              <UiText>
                With a world-class expert team, we will accompany you to get as far as your dreams take you
              </UiText>
              <UiText>Create your own success by sharing your IT passion and talent – GROW WITH US.</UiText>
            </Col>
            <UiGrid
              templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(5,1fr)'}}
              columnGap={{base: 3, lg: 6}}
              rowGap={5}
              mt="20px"
              w="100%"
            >
              <UiGridItem colSpan={2}>
                <UiText mb="20px">Vacancies Are Recruiting :</UiText>
                <Col>
                  {positions.map((position, idx) => {
                    return (
                      <Row
                        key={position.key}
                        w="90%"
                        alignItems="center"
                        justifyContent="space-between"
                        bgColor="rgb(245, 245, 245)"
                        p="12px 20px"
                        cursor="pointer"
                        color="rgb(79, 82, 88)"
                        fontSize="lg"
                        fontWeight="bold"
                        borderRadius="12px"
                        transition="0.4s"
                        mb="15px"
                        _hover={{
                          bgColor: 'orange.400',
                          color: 'white',
                        }}
                        onClick={() => handleChoosePosition(position)}
                      >
                        <UiText>{position.name}</UiText>
                        <UiText>{position.total_job}</UiText>
                      </Row>
                    );
                  })}
                </Col>
              </UiGridItem>
              {isDesktop && (
                <UiGridItem colSpan={3}>
                  <UiTitle fontSize="xl">Our Tech Stack</UiTitle>
                  <UiGrid templateColumns="repeat(4,1fr)" columnGap={{base: 3, lg: 10}} rowGap={10} pt={5}>
                    {techStacks.map((value, index) => {
                      return (
                        <Col key={index} justify="center" align="center">
                          <UiIcon as={value.icon} boxSize={{base: '3em', lg: '4em'}} color={value.color} />
                          <UiText
                            color={colorText}
                            fontSize={{base: 'xs', lg: 'md'}}
                            lineHeight={{base: '12px', lg: '16px'}}
                            mt={{base: '11px', lg: '15px'}}
                            textAlign="center"
                          >
                            {value.name}
                          </UiText>
                        </Col>
                      );
                    })}
                  </UiGrid>
                </UiGridItem>
              )}
            </UiGrid>
          </UiContainer>
        </Col>
      </UiBox>
    );
  }),
);

export default Vacancies;
