import React, {memo} from 'react';
import {UiCenter, UiSpinner} from '.';

interface Props {
  text?: string;
}

export const UiPageLoading = memo(function UiPageLoading({text = 'Loading'}: Props) {
  return (
    <UiCenter className="h-screen">
      <UiSpinner size="xl" label={text} />
    </UiCenter>
  );
});
