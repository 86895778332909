import React from 'react';
import {Col, Row, UiBox, UiContainer, UiGrid, UiImage, UiText} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Exam2 = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <Col pb="100px">
      <UiBox pos="relative" bgColor="#B2BFB3" w="100%" h={{base: '100%', lg: '32.75em'}}>
        <UiContainer h="100%" maxW="container.xl" pos="relative" pt="20px">
          <UiBox as={isDesktop ? Row : Col} alignItems="center" h="100%">
            <Row w="100%" height="100%" justifyContent="flex-end" alignItems="center" mb={{base: '20px'}}>
              <Row w={{base: '100%', lg: '50%'}} pr={{lg: '25px'}}>
                <UiText
                  fontSize={{base: 'md', lg: '5xl'}}
                  color="white"
                  textAlign="center"
                  lineHeight={{base: '25px', lg: '50px'}}
                  w="100%"
                  fontWeight="600"
                >
                  Tailored in-app shopping
                  {isDesktop && <br />} experience
                  <br /> for 2 different markets,
                  <br /> 10+ services, 30+ SKUs
                </UiText>
              </Row>
            </Row>
            <UiImage
              filePath="story/ease-6.png"
              pos={{base: 'relative', lg: 'absolute'}}
              h={{base: '12.875em', lg: '37.375em'}}
              bottom="0px"
              left="0px"
              zIndex="100"
            />
            <UiImage
              filePath="story/ease-7.png"
              w={{base: '6.5em', lg: 'auto'}}
              h={{base: '6.375em', lg: '15em'}}
              pos="absolute"
              bottom={{base: '-15%', lg: '-18%'}}
              left={{base: '70%', lg: '45%'}}
            />
            {!isDesktop && (
              <UiImage
                filePath="story/ease-7.png"
                w={{base: '2.125em', lg: '18.5em'}}
                h={{base: '2.375em', lg: '15em'}}
                pos="absolute"
                top="20%"
                right="15%"
              />
            )}
            {!isDesktop && (
              <UiImage
                filePath="story/ease-8.png"
                w="4em"
                h="4.5em"
                pos="absolute"
                top="-10%"
                left="2%"
                filter="blur(4px)"
              />
            )}
            {isDesktop && (
              <UiImage
                filePath="story/ease-8.png"
                w="9.5em"
                h="8.375em"
                pos="absolute"
                top="-10%"
                right="0%"
                filter="blur(4px)"
              />
            )}
            {!isDesktop && (
              <UiImage
                filePath="story/ease-8.png"
                w="4em"
                h="4.5em"
                pos="absolute"
                bottom="20%"
                left="15%"
                filter="blur(4px)"
                zIndex="1"
              />
            )}
          </UiBox>
        </UiContainer>
      </UiBox>
      <UiContainer
        h="100%"
        maxW="container.xl"
        pos="relative"
        w={{base: '100%', lg: '80%'}}
        mt={{base: '20%', lg: '200px'}}
      >
        <UiGrid
          templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
          h={{base: '100%', lg: '44.25em'}}
          w="100%"
          columnGap={{base: 1, lg: 10}}
        >
          <Row w="100%" justifyContent="center">
            <Row h={{base: '25em', lg: '40.25em'}} w={{base: '15em', lg: '22.875em'}} pos="relative">
              <UiImage filePath="story/ease-10.png" pos="absolute" h={{base: '25em', lg: '40.25em'}} />
              <UiImage
                filePath="story/ease-9.png"
                pos="absolute"
                h={{base: '15em', lg: '24.75em'}}
                right="-10%"
                bottom="0"
              />
            </Row>
          </Row>

          <Row h="100%" alignItems="center" mt={{base: '20px', lg: '0px'}}>
            <UiText fontSize={{base: 'md', lg: '4xl'}} textAlign="center">
              User journey planning and services provided were followed with A/B testing after launch to continuously
              improve UX
            </UiText>
          </Row>
        </UiGrid>
      </UiContainer>
    </Col>
  );
};

export default Exam2;
