import DOMPurify from 'dompurify';
import React from 'react';
import {useSelector} from 'react-redux';
import '~/assets/css/main.css';
import {UiBox, UiContainer, UiText} from '~/components/elements';
import {RootState} from '~/redux/root-reducer';
import {JobApiItem} from '~/types/models/jobs';

interface Props {
  job?: JobApiItem;
}

const Content = React.memo(
  React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);

    const createMarkup = (html?: string | Node) => {
      if (html)
        return {
          __html: DOMPurify.sanitize(html),
        };
    };

    return (
      <UiBox>
        <UiContainer
          mt="50px"
          mb="50px"
          h="100%"
          maxW="container.xl"
          pb="40px"
          pos="relative"
          w={{base: '89%', lg: '55%'}}
          ref={ref}
          sx={{scrollMarginTop: `${menuHeight}px`}}
        >
          <UiText fontSize="xl" dangerouslySetInnerHTML={createMarkup(props.job?.content)}></UiText>
        </UiContainer>
      </UiBox>
    );
  }),
);

export default Content;
