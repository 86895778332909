import React, {useRef} from 'react';
import {UiBanner, UiBox, UiText} from '~/components/elements';
import {ContactUs} from '~/components/shared/ContactUs';
import {Team} from './Team';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const CompanyPage = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  useScrollToTop();

  useMetaTags(
    {
      title: `Company | Rayyone`,
      description: `We started Rayyone with the vision of satisfying young startups with unique and qualified technical services and solutions that don't break the bank. Learn more!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content: 'Rayyone,startup,technical services,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  const title = (
    <>
      Let&#39;s do great things{' '}
      <UiText as="span" color="orange.400">
        together!
      </UiText>{' '}
    </>
  );

  return (
    <UiBox>
      <UiBanner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
        title={title}
        description="We started Rayyone with the vision of satisfying young startups with unique and qualified technical
         services and solutions that don't break the bank."
      />
      <Team ref={mainRef} />
      <ContactUs />
    </UiBox>
  );
};

export default CompanyPage;
