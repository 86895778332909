import React, {useRef} from 'react';
import {UiBox} from '~/components/elements';
import Banner from '~/pages/start-up/components/Banner';
import AreYou from '~/pages/start-up/components/AreYou';
import VividIdeas from '~/pages/start-up/components/VividIdeas';
import Example from '~/pages/start-up/components/Example';
import Reason from '~/pages/start-up/components/Reason';
import {ContactUs} from '~/components/shared/ContactUs';
import ContactTime from '~/components/shared/ContactUsTime';
import ContactUsScrumTeam from '~/components/shared/ContactUsScrumTeam';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const StartupPage = () => {
  const mainRef = useRef<HTMLDivElement>(null);

  useScrollToTop();

  useMetaTags(
    {
      title: `Startup | Rayyone`,
      description: `Do you want to launch your MVP asap? We can help you to build more-than-an-MVP in top-notch quality and scalability, within a short period of time. Book free demo!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content: 'MVP,build mvp,create app,build app,build web,app development,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox whiteSpace="pre-line" fontFamily="Inter">
      <Banner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
      />
      <AreYou ref={mainRef} />
      <VividIdeas />
      <Example />
      <Reason />
      <ContactUsScrumTeam />
      <ContactTime />
      <ContactUs />
    </UiBox>
  );
};

export default StartupPage;
