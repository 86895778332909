import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiButton,
  UiContainer,
  UiGrid,
  UiIcon,
  UiImage,
  UiLink,
  UiText,
  UiTitle,
} from '~/components/elements';
import {useMediaQuery} from '@chakra-ui/react';
import {AiOutlineClockCircle} from 'react-icons/ai';
import {QUERY_MOBILE} from '~/constants';
import {DOCS_MENU, RAYYONE_DOC_URLS, TECH_LIST} from '~/constants/ry-tool';
import parse from 'html-react-parser';
import {ComponentProps} from '~/types/models/app';

const StyledImage = (p: ComponentProps) => {
  return <UiImage filePath={p.url} pos="absolute" top="-80%" zIndex="-1" {...p} />;
};

const Banner = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox pb={{base: '40px', lg: '100px'}} minH="100vh" pt={{base: '30px', lg: '80px'}}>
      {isDesktop && (
        <Row fontSize="sm" bgColor="black" color="white" p="20px 0px" justifyContent="space-evenly">
          {DOCS_MENU.map(value => {
            return (
              <UiLink key={value.key} href={value.url} target="_blank">
                {value.title}
              </UiLink>
            );
          })}
        </Row>
      )}

      <UiContainer h="100%" maxW="container.xl" w={{base: '90%', lg: '76%'}} mr="auto" ml="auto">
        <Col mt="80px" textAlign="center" alignItems="center">
          <UiTitle fontSize="4xl">
            Boost Your Productivity With RY
            <UiTitle as="span" color="orange.400">
              Tools
            </UiTitle>
          </UiTitle>
          <UiText>
            If you are looking for a new product building, service development or existing services rewritten, RY has
            {isDesktop ? <br /> : ' '}
            the tools to help you achieve your goals with accelerated speed to market and increased scalability.
          </UiText>
          <Row mt="40px" mb={{base: '50px', lg: '100px'}}>
            <UiButton onClick={() => window.open(RAYYONE_DOC_URLS.HOME, '_blank')}>Get Started</UiButton>
          </Row>
          <UiImage filePath="ry-tool/ry-tools-1.png" h={{base: 'auto', lg: '27.75em'}} mb={{base: '50px', lg: '0px'}} />
        </Col>
      </UiContainer>
      <Col bgColor="#F4F4F4" w="100%" alignItems="center" p={{base: '35px 0px', lg: '75px 0px'}} pos="relative">
        {isDesktop && (
          <UiBox>
            <StyledImage url="ry-tool/bg.png" left="0px" />
            <StyledImage url="ry-tool/bg-2.png" right="0px" />
          </UiBox>
        )}

        <UiContainer h="100%" maxW="container.xl" w={{base: '90%', lg: '76%'}} mr="auto" ml="auto">
          <UiText fontSize="2xl" textAlign="center">
            We use modern tools to build scalable,
            {isDesktop && <br />} fault-tolerant, distributed software products
          </UiText>
          <Row mt="50px">
            <UiGrid
              templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(4,1fr)'}}
              columnGap={{base: 3, lg: 10}}
              rowGap={{base: '80px', lg: 10}}
              pt={5}
            >
              {TECH_LIST.map(value => {
                return (
                  <Col key={value.key} h="100%" justifyContent="space-between">
                    <Col>
                      <Row align={'center'}>
                        <UiImage filePath={value.image} h="1.5em" />
                        <UiText fontSize="xl" fontWeight="bold" ml="10px">
                          {value.title}
                        </UiText>
                      </Row>
                      <UiText mt="10px" mb="20px">
                        {parse(value.description)}
                      </UiText>
                    </Col>
                    <UiLink href={value.url} actionName={value.title} target="_blank">
                      <Row
                        border="1px solid #000000"
                        borderRadius="5px"
                        p="5px 0px"
                        bgColor="transparent"
                        justifyContent="center"
                        alignItems="center"
                        color="black"
                        fontWeight="500"
                      >
                        <UiText>Tutorials - 5 mins</UiText>
                        <UiIcon as={AiOutlineClockCircle} color="black" />
                      </Row>
                    </UiLink>
                  </Col>
                );
              })}
            </UiGrid>
          </Row>
        </UiContainer>
      </Col>
    </UiBox>
  );
};

export default Banner;
