import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import moment from 'moment';
import {TRACKER_TYPES} from '~/constants/tracker';
import {TrackerApiItem} from '~/types/models/tracker';

interface InitialState {
  trackerLogs: TrackerApiItem[];
  sessionId: string;
}

const initialState: InitialState = {
  trackerLogs: [] as TrackerApiItem[],
  sessionId: '',
};

// Slice
const trackers = createSlice({
  name: 'trackers',
  initialState,
  reducers: {
    setSessionIdUser(state, {payload}: PayloadAction<{sessionId: string}>) {
      state.sessionId = payload.sessionId;
      if (payload.sessionId) {
        state.trackerLogs.forEach(l => (l.session_id = payload.sessionId));
      }
    },
    setTracker(state, {payload}: PayloadAction<TrackerApiItem>) {
      state.trackerLogs.push({...payload, session_id: state.sessionId});
    },
    setVisitingPage(state, {payload}: PayloadAction<{pathname: string; search: string}>) {
      const {pathname, search} = payload;
      const now = moment();
      const currentPageLog = {
        value: '',
        path: `${pathname}${search}`,
        session_id: state.sessionId,
        triggered_at: now.format(),
        type: TRACKER_TYPES.PAGE_CHANGE,
        duration: 0,
      };
      state.trackerLogs.push(currentPageLog);
    },
    resetTrackerBag(state) {
      state.trackerLogs = [];
    },
  },

  extraReducers: builder => {},
});

export const {setSessionIdUser, setTracker, setVisitingPage, resetTrackerBag} = trackers.actions;

export default trackers.reducer;
