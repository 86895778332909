import {TextProps, useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {BsDot} from 'react-icons/bs';
import {
  Col,
  Row,
  UiBox,
  UiContainer,
  UiGrid,
  UiIcon,
  UiImage,
  UiLink,
  UiText,
  UiTitle,
} from '~/components/elements/index';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {Link} from 'react-router-dom';

const TextHighlight = (p: TextProps) => <UiText as="span" color="orange.400" fontWeight="bold" {...p} />;

const style = {
  bg: 'linear-gradient(180.11deg, rgba(251, 140, 0, 0.32) -13.98%, rgba(251, 140, 0, 0) 99.9%)',
  borderRadius: '15px',
  boxShadow: '2px 11px 30px rgba(51, 51, 51, 0.08)',
  alignItems: 'center',
  p: '20px 0px',
};

const HowWeMakeIt = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox>
      <UiContainer h="100%" maxW="container.xl" w={{base: '85%', lg: '76%'}} mr="auto" ml="auto">
        <Col textAlign="center">
          <UiTitle fontSize="5xl">How We Made It?</UiTitle>
          <UiText fontSize="2xl" mt={{base: '10px', lg: '50px'}}>
            Leveraging our <TextHighlight>custom-made tools, highly-skilled</TextHighlight> senior developers,{' '}
            <TextHighlight>
              and
              {isDesktop && <br />} well-structured
            </TextHighlight>{' '}
            Scrum team, we satisfy you by getting your products speed
            {isDesktop && <br />} to market within <TextHighlight>2 working weeks!</TextHighlight>
          </UiText>
        </Col>
        <Col {...style} mt={{base: '50px', lg: '100px'}}>
          <UiImage filePath="coupon/mobile-app.png" h="8.125em" />
          <UiTitle fontSize="4xl" opacity="0.8" mt="35px">
            Development Tools
          </UiTitle>
          <Col textAlign={{lg: 'center'}} color="black.100" padding={{base: '0px 10px'}}>
            <UiText mb={{base: '10px'}}>
              Our in-house developed tools accelerate implementation speed to <strong>5x faster.</strong>{' '}
            </UiText>
            <UiText mb={{base: '10px'}}>No more coding hassle!</UiText>
            <Row alignItems="center" mb={{base: '10px'}} justifyContent="center">
              {isDesktop && <UiIcon as={BsDot} color="black" />}
              <UiText>
                Create APIs within <strong>10 minutes</strong> using<strong> Rayyone’s CLI tools</strong>
              </UiText>
            </Row>
            <Row alignItems="center" justifyContent="center">
              {isDesktop && <UiIcon as={BsDot} color="black" />}
              <UiText>
                <strong>Deploy and manage</strong> your services using <strong>Rayyone’s Infrastructure</strong>
              </UiText>
            </Row>
          </Col>
          <UiLink as={Link} to={PAGES.RAYYONE_TOOLS} textDecorationLine="underline" mt="20px">
            Learn more
          </UiLink>
        </Col>
        <UiGrid
          templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
          columnGap={{base: 3, lg: 10}}
          rowGap={10}
          mt="30px"
        >
          <Col
            bg="linear-gradient(180deg, rgba(251, 140, 0, 0.02) 0%, rgba(251, 140, 0, 0.2) 100%)"
            boxShadow="2px 11px 30px rgba(51, 51, 51, 0.08)"
            borderRadius="15px"
            p="30px 10px"
            alignItems="center"
          >
            <UiImage filePath="coupon/web-designing.png" h="8.125em" />
            <UiTitle fontSize="2xl" mt="30px" opacity="0.8">
              Management Tools
            </UiTitle>
            <Col mt="10px" color="black.100" textAlign={{lg: 'center'}} padding={{base: '0px 10px'}}>
              <UiText mb={{base: '10px'}}>
                Powerful custom-made resources and projects management tools offering{' '}
                <strong>24/7 transparent, real-time</strong> progress to
                {isDesktop && <br />} stakeholders.
              </UiText>
              <UiText>
                Comprehensive performance metrics are tracked and flexibly adjusted to adapt to each project’s
                characteristics.
              </UiText>
            </Col>
          </Col>
          <Col
            bg="linear-gradient(180deg, rgba(251, 140, 0, 0.02) 0%, rgba(251, 140, 0, 0.2) 100%)"
            boxShadow="2px 11px 30px rgba(51, 51, 51, 0.08)"
            borderRadius="15px"
            p="30px 10px 100px 10px"
            alignItems="center"
          >
            <UiImage filePath="coupon/web-development.png" h="8.125em" />
            <UiTitle fontSize="2xl" mt="30px" opacity="0.8" w="100%" textAlign="center">
              Highly-skilled Expertise
            </UiTitle>
            <Col mt="10px" color="black.100" textAlign={{lg: 'center'}} padding={{base: '0px 10px'}}>
              <UiText mb={{base: '5px'}}>
                Our well-structured Scrum team is responsible for end-to-end
                {isDesktop && <br />} to your projects.
              </UiText>
              <UiText>
                We capture the low-hanging fruits first and continue working
                {isDesktop && <br />} on the long-term opportunities!{' '}
              </UiText>
            </Col>
          </Col>
        </UiGrid>
      </UiContainer>
    </UiBox>
  );
};

export default HowWeMakeIt;
