import React from 'react';
import {Col, UiBox, UiContainer, UiGrid, UiIcon, UiText} from '~/components/elements/index';
import {useColorModeValue} from '@chakra-ui/react';
import {techStacks} from '~/constants/index';

const TechStack = () => {
  const colorText = useColorModeValue('black', 'white');

  return (
    <UiBox h="100%">
      <UiBox bgColor="orange.400">
        <UiContainer
          h="100%"
          maxW="container.xl"
          pt="45px"
          pb="45px"
          pos="relative"
          w={{base: '100%', lg: '76%'}}
          alignItems="center"
          display="flex"
          justifyContent="center"
        >
          <UiText
            fontSize={{base: 'md  ', lg: '4xl'}}
            lineHeight={{base: '30px', lg: '60px'}}
            fontFamily="SecularOne"
            color="white"
            textAlign="center"
          >
            We use modern tools to build scalable,
            <br />
            fault-tolerant, distributed software products
          </UiText>
        </UiContainer>
      </UiBox>
      <UiContainer h="100%" maxW="container.xl" pt="40px" pb="40px" pos="relative" w={{base: '90%', lg: '76%'}}>
        <UiText fontSize={{base: '4xl', lg: '8xl'}} lineHeight={{base: '30px', lg: '80px'}} fontFamily="SecularOne">
          Our Tech Stack
        </UiText>
        <UiGrid
          templateColumns={{base: 'repeat(4,1fr)', lg: 'repeat(5,1fr)'}}
          columnGap={{base: 3, lg: 10}}
          rowGap={10}
          pt={5}
        >
          {techStacks.map((value, index) => {
            return (
              <Col key={index} justify="center" align="center">
                <UiIcon as={value.icon} boxSize={{base: '3em', lg: '4em'}} color={value.color} />
                <UiText
                  fontSize={{base: 'xs', lg: 'md'}}
                  lineHeight={{base: '12px', lg: '16px'}}
                  color={colorText}
                  mt={{base: '11px', lg: '15px'}}
                  textAlign="center"
                >
                  {value.name}
                </UiText>
              </Col>
            );
          })}
        </UiGrid>
      </UiContainer>
    </UiBox>
  );
};

export default TechStack;
