import {useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {Row, AppAvatar, Col, UiText} from '~/components/elements';
import {QUERY_MOBILE} from '~/constants';

interface Props {
  avatar: string;
  name: string;
  jobTitle: string;
  color: string;
}

const RenderUser = ({avatar, name, jobTitle, color}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <Row
      alignItems="center"
      justifyContent={isDesktop ? 'inherit' : 'flex-end'}
      textAlign={isDesktop ? 'inherit' : 'end'}
    >
      {isDesktop && <AppAvatar url={avatar} />}
      <Col pl={{lg: '15px'}} fontSize={{base: 'xs', lg: 'md'}} lineHeight={{base: '16px', lg: '20px'}}>
        <UiText>{name}</UiText>
        <UiText color={color} fontStyle="italic">
          {jobTitle}
        </UiText>
      </Col>
      {!isDesktop && <AppAvatar url={avatar} width="1.75em" height="1.75em" ml="5px" />}
    </Row>
  );
};

export default RenderUser;
