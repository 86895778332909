import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {CouponEntity, NormalizedCoupon} from '~/types/models/coupon';
import {couponSchema} from '~/redux/coupons/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchCouponsParams extends FetchParams {}

export interface FetchCouponParams extends FetchParams {}

export interface AddCouponParams extends PostParams {
  data: Partial<CouponEntity>;
}

export interface UpdateCouponParams extends PutParams {
  data: Partial<CouponEntity>;
  id: string;
}

const query = thunk.query<CouponEntity, NormalizedCoupon>('coupon', couponSchema);
export const fetchCoupons = query.fetch<FetchCouponsParams>(ENDPOINTS.COUPONS);
export const addCoupon = query.post<AddCouponParams>(ENDPOINTS.COUPONS);
export const updateCoupon = query.put<UpdateCouponParams>(`${ENDPOINTS.COUPONS}/:id`);
export const deleteCoupon = query.delete<DeleteParams>(`${ENDPOINTS.COUPONS}/:id`, '', {withoutNormalize: true});

export const fetchCoupon = query.fetchOne<FetchCouponParams>(`${ENDPOINTS.COUPONS}/:id`);
