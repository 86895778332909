export const tab = {DEFAULT: 'default', MOBILE: 'mobileApp', LANDING_PAGE: 'landingPage', E_COMMERCE: 'eCommerce'};

export const results = [
  'Platform migration management from Drupal to Adobe Magento 2 preserving SEO ranking and data',
  'Platform migration management from Drupal to Adobe Magento 2 preserving SEO ranking and data',
  'Platform migration management from Drupal to Adobe Magento 2 preserving SEO ranking and data',
  'Platform migration management from Drupal to Adobe Magento 2 preserving SEO ranking and data',
];

export const resultsModal1 = [
  'Successful launch of a comprehensive women healthcare super-app for iOS and Android users',
  'Contraception and Period Trackers are personalized to provide unique and relevant experiences to users',
  'Push notification system ensuring news and updates can easily reach specific target users',
  'Booking system integrated with Google Services and SMS system available 24/7 for users',
  'Tailored services for users based on their preferences and insights. Providing telehealth services and products based on users GEO locations',
];

export const resultsModal2 = [
  'Code-base, database optimized and CDN, load balancing applied helped to improve the site’s loading speed by 35-40%',
  'The new cloud infrastructure offers a scalable solution for a stable and reliable site performance',
  'Continuous on-demand support based on the client’s business needs',
  'System flaws were addressed immediately as they were discovered via multiple bug-traps and alert system',
];

export const approachesModal1 = [
  'Rayyone proposed talents and built the high-qualified Scrum team, applied the custom-made workflow to ensure the speed to market features launch',
  'Setting up high availability scalable infrastructure in Amazon',
  'CI/CD system for auto-building and deploying Applications',
  'Well-structured and up-to-date Script system. We make sure that every single code line is carefully reviewed',
  'Codebase management is much more feasible by applying Semantic Commit Messages. Changelog is always equipped for every release.',
];

export const approachesModal2 = [
  'Setting up high availability scalable infrastructure in Amazon Web Services',
  'DevOps are available all the time in case of blockers or downtimes, ensuring a successful sales process',
  'Improved widget functionality to increase efficiency of content management team',
  "A scrum team of Rayyone's PM, QA, developers working alongside the client's in-house team",
  'Synchronize with and between Mobile App and Admin systems to assure the most optimized operations flow',
  'Continuous CRO suggestions and on-demand updates to optimize user experience',
];
