import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {SubscriberEntity} from '~/types/models/subscribers';

class EntityConfig extends BaseEntityConfig<SubscriberEntity> {
  entityKey = 'subscribers';

  associates = [];

  whiteListFields: WhiteListFields<SubscriberEntity> = ['*'];

  getAccessors = (value: SubscriberEntity) => {
    //
  };
}

const subscriberEntConf = new EntityConfig();

export const subscriberSchema = subscriberEntConf.getEntitySchema();

export default subscriberEntConf;

export const SOURCES_SUBSCRIBER = {
  SUBSCRIBERS_PAGE: 'SUBSCRIBERS_PAGE',
};
