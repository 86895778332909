import React from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiGrid, UiIcon, UiLink, UiText} from '~/components/elements';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {BoxProps, Icon, IconProps, TextProps, useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {UiTitle} from '~/components/elements/UiTitle';
import {Link, useHistory} from 'react-router-dom';
import {FiKey, FiTool} from 'react-icons/fi';
import {CgArrowsExpandRight} from 'react-icons/cg';
import {BsPeople} from 'react-icons/bs';
import {IconType} from 'react-icons/lib';

interface BoxIconProps extends IconProps {
  as: IconType;
}

const ContainerContent = (p: BoxProps) => <Col ml={{base: '0px', lg: '25px'}} mt={{base: '10px', lg: '0px'}} {...p} />;

const Header = (p: TextProps) => (
  <UiText fontSize={{base: 'md', lg: 'xl'}} lineHeight={{base: '16px', lg: '22px'}} fontWeight="extrabold" {...p} />
);

const Content = (p: TextProps) => (
  <UiText
    fontSize={{base: 'sm', lg: 'md'}}
    lineHeight={{base: '16px', lg: '18px'}}
    mb={{base: '10px', lg: '15px'}}
    mt={{base: '10px', lg: '15px'}}
    {...p}
  />
);

const BoxIcon = ({...rest}: BoxIconProps) => <Icon {...rest} boxSize={{base: 4, lg: 6}} color="white" />;

const BoxCircle = (p: BoxProps) => (
  <UiBox
    display="flex"
    alignItems="center"
    justifyContent="center"
    h={{base: '28px', lg: '50px'}}
    w={{base: '28px', lg: '50px'}}
    borderRadius="50%"
    p={{base: '0px', lg: '25px'}}
    {...p}
  />
);

const Services = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const history = useHistory();
    const colorButton = useColorModeValue('black', '#333237');

    const ServiceComponent = ({
      icon,
      header,
      content,
      link,
    }: {
      icon: React.ReactNode;
      header: string;
      content: string;
      link: string;
    }) => {
      return (
        <UiBox as={isDesktop ? Row : Col}>
          {isDesktop && icon}
          <ContainerContent>
            <Row alignItems="center">
              {!isDesktop && <UiBox mr="10px">{icon}</UiBox>}
              <Header>{header}</Header>
            </Row>
            <Content>{content}</Content>
            <UiLink textDecoration="underline" as={Link} to={link}>
              READ MORE
            </UiLink>
          </ContainerContent>
        </UiBox>
      );
    };

    return (
      <UiBox
        id="services"
        height={{base: 'auto', lg: '100%'}}
        mb={{base: '80px', lg: '95px'}}
        ref={ref}
        sx={{scrollMarginTop: `${menuHeight}px`}}
      >
        <UiBox w="100%" bgColor="orange.400">
          <UiContainer h="100%" maxW="container.xl" w={{base: '95%', lg: '87%'}} pos="relative" p="37px 0px">
            <UiTitle
              fontSize={{base: 'xl', lg: '5xl'}}
              lineHeight={{base: '25px', lg: '60px'}}
              textAlign="center"
              fontWeight="400"
              color="white"
            >
              We provide diversified services ranging from Mobile App, Web App, and Website development to IT Offshore
              team construction
            </UiTitle>
          </UiContainer>
        </UiBox>
        <UiContainer
          h="100%"
          maxW="container.xl"
          w={{base: '90%', lg: '76%'}}
          pos="relative"
          mt={{base: '50px', lg: '80px'}}
        >
          <Col pos="relative">
            <UiTitle
              lineHeight={{base: '35px', lg: '80px'}}
              fontSize={{base: '4xl', lg: '8xl'}}
              mb={{base: '5px', lg: '10px'}}
            >
              Main Services
            </UiTitle>

            <UiGrid
              templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
              columnGap={50}
              rowGap={{base: 35, lg: '75px'}}
              pt={{base: '30px', lg: '60px'}}
            >
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="orange.400">
                    <BoxIcon as={FiKey} />
                  </BoxCircle>
                }
                header="New Product Development"
                content="Set your budget in advance, pass management risks to Rayyone, and build a product according to your business vision."
                link={PAGES.START_UP}
              />
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="#E3CDF7">
                    <BoxIcon as={CgArrowsExpandRight} />
                  </BoxCircle>
                }
                header="Software Service Expansion"
                content="Scale out your product without establishing a permanent own engineering team."
                link={PAGES.BUILD_IDEAS}
              />
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="#95DCBC">
                    <BoxIcon as={FiTool} />
                  </BoxCircle>
                }
                header="All-in-one Maintenance Service"
                content="No permanent developers are needed, let us help you to maintain your products flexibly and effectively with our well-trained team."
                link={PAGES.EXPAND_TEAM}
              />
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="#FDD466">
                    <BoxIcon as={BsPeople} />
                  </BoxCircle>
                }
                header="Build Stellar Off-shore team"
                content="Let us assemble a dream team of software engineers, Scrum Masters, and QA specialists just for you. Our model allows you to maximize the efficiency of your team."
                link={PAGES.MANAGE_SERVICE}
              />
            </UiGrid>
          </Col>
        </UiContainer>
        <UiBox w="100%" bgColor="orange.400" mt={{base: '105px', lg: '160px'}}>
          <UiContainer h="100%" maxW="container.xl" w={{base: '85%', lg: '80%'}} pos="relative" p="67px 0px">
            <UiTitle
              fontSize={{base: '2xl', lg: '7xl'}}
              lineHeight={{base: '35px', lg: '80px'}}
              textAlign="center"
              fontWeight="400"
              color="white"
            >
              We care about your product {isDesktop && <br />} as much as you do!
            </UiTitle>
          </UiContainer>
        </UiBox>
      </UiBox>
    );
  }),
);

export default Services;
