import React, {useRef} from 'react';
import {UiBanner, UiBox, UiText} from '~/components/elements';
import {HowWeHelp} from '~/pages/expand-your-team/HowWeHelp';
import {Stories} from '~/pages/build-your-ideas/Stories';
import {ContactUs} from '~/components/shared/ContactUs';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const ExpandTeamsPage = () => {
  const mainRef = useRef<HTMLDivElement>(null);

  useScrollToTop();

  useMetaTags(
    {
      title: `Expand Your Team | Rayyone`,
      description: `Boost your IT team with Rayyone's dedicated world-class software developers. Our model allows you to maximize the efficiency of your team. Hear more about us!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content:
            'outsource it,app developer company,hire it team,teams app development,build offshore team,remote it team hiring',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  const title = (
    <>
      <UiText as="span" color="orange.400">
        Expand
      </UiText>{' '}
      your team
    </>
  );

  return (
    <UiBox>
      <UiBanner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
        title={title}
        description="Let us assemble a dream team of software engineers, Scrum Masters, and QA specialists just for you. Our
        model allows you to maximize the efficiency of your team."
      />
      <HowWeHelp ref={mainRef} />

      <Stories />
      <ContactUs />
    </UiBox>
  );
};

export default ExpandTeamsPage;
