import {useSelector} from 'react-redux';
import {AsyncThunk} from '@reduxjs/toolkit';
import {RootState} from '~/redux/root-reducer';
import {RequestState} from 'redux-thunk-kit';

export function useRequestState(thunkAction: AsyncThunk<any, any, any> & any): RequestState {
  const {firstPage, loading, error, errCode, contexts} = useSelector(
    (state: RootState) => state.ui[thunkAction.typePrefix] || {},
  );

  return {firstPage, loading, error, errCode, contexts};
}
