import React from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiIcon, UiImage, UiText, UiTitle} from '~/components/elements';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {BsArrowDown} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';
import {color} from '~/theme/color';

interface Props {
  onClickArrowDown?: () => any;
}

const Banner = React.memo(({onClickArrowDown}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const history = useHistory();
  const colorButton = useColorModeValue('black', 'white');

  const onClickContactUs = () => {
    return history.push(PAGES.CONTACT_US);
  };

  return (
    <UiBox h="100vh" pt={50}>
      <UiContainer h="100%" maxW="container.xl" w={{base: '85%', lg: '76%'}} pos="relative">
        <Col h="100%" w="100%" justifyContent="center">
          <Col>
            <UiBox as={isDesktop ? Row : Col} fontSize="8xl" lineHeight={{base: '90px', lg: '100px'}}>
              {isDesktop && (
                <UiTitle>
                  Make{' '}
                  <UiText as="span" color="orange.400">
                    your ideas {isDesktop && <br />}{' '}
                  </UiText>
                  come true!
                </UiTitle>
              )}
              {!isDesktop && (
                <UiTitle>
                  realize{' '}
                  <UiText as="span" color="orange.400">
                    your ideas {isDesktop && <br />}{' '}
                  </UiText>
                </UiTitle>
              )}
            </UiBox>
            <UiBox
              as={isDesktop ? Row : Col}
              color="#647576"
              mt="51px"
              fontSize={{base: 'sm', lg: 'xl'}}
              lineHeight={{base: '20px', lg: '30px'}}
            >
              <UiText>Let’s write a new story where the next Unicorn startup begin!</UiText>
            </UiBox>
          </Col>
          <UiBox as={isDesktop ? Row : Col} alignItems={isDesktop ? 'center' : 'left'} mt="75px">
            <UiButton
              fontWeight="bold"
              bgColor="transparent"
              color={colorButton}
              variant="outline"
              border="3px solid  "
              borderColor="#FB8C00"
              borderRadius="10px"
              px={{base: '18px  ', lg: '20px  '}}
              py={{base: '8px', lg: '30px'}}
              mr="15px"
              fontSize="xl"
              onClick={onClickContactUs}
            >
              Share your story with us!
            </UiButton>
          </UiBox>
          {isDesktop ? (
            <UiBox pos="absolute" right="0px">
              <UiImage filePath="start-up/diamond.png" h="10em" />
            </UiBox>
          ) : (
            <UiBox pos="absolute" right="0px" top="45%">
              <UiImage filePath="start-up/diamond.png" h="4.6875em" />
            </UiBox>
          )}

          {isDesktop && (
            <UiIcon
              pos="absolute"
              right="0px"
              bottom="100px"
              _hover={{cursor: onClickArrowDown ? 'pointer' : undefined}}
              as={BsArrowDown}
              boxSize={70}
              color={color.home.textDescription}
              mt={10}
              alignSelf="flex-start"
              onClick={onClickArrowDown}
            />
          )}
        </Col>
      </UiContainer>
    </UiBox>
  );
});

export default Banner;
