import React, {useEffect} from 'react';
import {MdKeyboardArrowRight} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {Col, UiButton, UiContainer, UiGrid, UiIcon, UiTitle} from '~/components/elements';
import {PAGES} from '~/constants';
import JobCard from '~/pages/career/components/JobCard';
import {SOURCES_JOB} from '~/redux/jobs/entity-config';
import {getJobs} from '~/redux/jobs/slice';
import {fetchJobs} from '~/redux/jobs/thunk';
import {AppDispatch} from '~/redux/root-store';
import {JobApiItem} from '~/types/models/jobs';

const LatestVacancies = () => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const {data: jobs} = useSelector(getJobs);

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchJobs({source: SOURCES_JOB.JOBS_PAGE, limit: 3}));
    };
    fetchData();
  }, [dispatch]);

  return (
    <UiContainer h="100%" maxW="container.xl" pb="40px" pos="relative" w={{base: '89%', lg: '76%'}}>
      <Col alignItems="center" justifyContent="center">
        <UiTitle fontSize="5xl">Latest Vacancies</UiTitle>
        <UiGrid
          templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)'}}
          columnGap={{base: 3, lg: 6}}
          rowGap={5}
          mt="20px"
        >
          {jobs.map((job: JobApiItem) => {
            return <JobCard key={job.id} job={job} />;
          })}
        </UiGrid>
        <UiButton
          p="16px 28px"
          fontSize="sx"
          minW="220px"
          borderRadius="20px"
          mt="48px"
          role="group"
          actionName="All Jobs"
          onClick={() => history.replace(PAGES.JOB)}
        >
          All jobs{' '}
          <UiIcon
            as={MdKeyboardArrowRight}
            _groupHover={{ml: '20px'}}
            color="white"
            transition="0.5s"
            sx={{width: '30px', height: '30px'}}
          />
        </UiButton>
      </Col>
    </UiContainer>
  );
};

export default LatestVacancies;
