import React, {useEffect, useRef, useState} from 'react';
import {Switch, useLocation} from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

type LoadingBarRef = {
  add(value: number): void;
  decrease(value: number): void;
  continuousStart(startingValue?: number, refreshRate?: number): void;
  staticStart(startingValue: number): void;
  complete(): void;
};

export const CustomSwitch = ({children}: any) => {
  const ref = useRef<LoadingBarRef>(null);
  const [prevLoc, setPrevLoc] = useState('');
  const location = useLocation();

  useEffect(() => {
    setPrevLoc(location.pathname);
    ref?.current?.continuousStart();
    if (location.pathname === prevLoc) {
      setPrevLoc('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  useEffect(() => {
    ref?.current?.complete();
  }, [prevLoc]);

  return (
    <>
      <LoadingBar color="#fb8c00" ref={ref} />
      <Switch>{children}</Switch>
    </>
  );
};
