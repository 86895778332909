import React, {useRef} from 'react';
import {UiBanner, UiBox, UiText} from '~/components/elements';
import {HowWeHelp} from '~/pages/build-your-ideas/HowWeHelp';
import {Stories} from '~/pages/build-your-ideas/Stories';
import {ContactUs} from '~/components/shared/ContactUs';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const BuildYourIdeas = () => {
  const mainRef = useRef<HTMLDivElement>(null);

  useScrollToTop();

  useMetaTags(
    {
      title: `Build Your Ideas | Rayyone`,
      description: `Make your ideas come true with a great custom-build product according to your business vision. Set your budget in advance, pass management risks to Rayyone. Call us!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content:
            'build app,software development,app development,website development,web development,application development,create app,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  const title = (
    <>
      <UiText as="span" color="orange.400">
        Build your ideas
      </UiText>{' '}
      from scratch
    </>
  );

  return (
    <UiBox>
      <UiBanner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
        title={title}
        description=" Set your budget in advance, pass management risks to Rayyone and build a product according to your
    business vision."
      />

      <HowWeHelp ref={mainRef} />
      {/*<Col align="center">*/}
      {/*  <Divider w="80%" borderBottomWidth={2} />*/}
      {/*</Col>*/}
      <Stories />
      <ContactUs />
    </UiBox>
  );
};

export default BuildYourIdeas;
