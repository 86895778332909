import {ButtonProps, useMediaQuery} from '@chakra-ui/react';
import React from 'react';
import {IoChevronDownCircleOutline} from 'react-icons/io5';
import {Col, Row, UiBox, UiButton, UiIcon, UiTitle} from '~/components/elements/index';
import {QUERY_MOBILE} from '~/constants';
import connectionLine from '~/assets/img/connection-dot.png';

interface Props {
  onClickDownArrow?: () => void;
}

const Button = (p: ButtonProps) => <UiButton borderRadius="30px" p="16px 28px" fontSize="md" minWidth="220px" {...p} />;

const Banner = React.memo(({onClickDownArrow}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <Col
      backgroundImage={`url(${connectionLine})`}
      backgroundPosition="right"
      backgroundRepeat="no-repeat"
      backgroundSize={{base: '100% 100%', lg: 'contain'}}
      h="100vh"
      alignItems="center"
      justifyContent="center"
      pos="relative"
      p={{base: '20px', lg: '0px'}}
    >
      <UiTitle fontSize={{base: '6xl', lg: '8xl'}} textAlign="center">
        WELCOME TO RAYYONE
        <br /> CAREERS
      </UiTitle>
      <UiBox
        as={isDesktop ? Row : Col}
        w={{base: '70%', lg: '40%'}}
        justifyContent="center"
        mt="30px"
        lineHeight="80px"
      >
        <Button mt={{base: '15px', lg: '0px'}} onClick={onClickDownArrow}>
          Job openings
        </Button>
      </UiBox>
      {isDesktop && (
        <Row pos="absolute" bottom="50px" cursor="pointer" onClick={onClickDownArrow}>
          <UiIcon as={IoChevronDownCircleOutline} color="black" width="48px" height="48px" bottom="50px" />
        </Row>
      )}
    </Col>
  );
});

export default Banner;
