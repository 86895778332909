import React, {useEffect, useState} from 'react';
import {
  Col,
  Row,
  UiBox,
  UiContainer,
  UiText,
  UiTitle,
  UiMenu,
  UiMenuButton,
  UiButton,
  UiMenuList,
  UiMenuItem,
  UiGrid,
  UiIcon,
} from '~/components/elements';
import {useMediaQuery} from '@chakra-ui/react';
import {RiArrowDownSLine} from 'react-icons/ri';
import {AppDispatch} from '~/redux/root-store';
import {useDispatch, useSelector} from 'react-redux';
import {getLevels} from '~/redux/levels/slice';
import {fetchLevels} from '~/redux/levels/thunk';
import {SOURCES_LEVEL} from '~/redux/levels/entity-config';
import {LevelApiItem} from '~/types/models/levels';
import {SOURCES_POSITION} from '~/redux/positions/entity-config';
import {fetchPositions} from '~/redux/positions/thunk';
import {getPositions} from '~/redux/positions/slice';
import {PositionApiItem} from '~/types/models/positions';
import {fetchJobs} from '~/redux/jobs/thunk';
import {SOURCES_JOB} from '~/redux/jobs/entity-config';
import {getJobs} from '~/redux/jobs/slice';
import {JobApiItem} from '~/types/models/jobs';
import JobCard from '~/pages/career/components/JobCard';
import ReactPaginate from 'react-paginate';
import {MdKeyboardArrowLeft, MdKeyboardArrowRight} from 'react-icons/md';
import {RootState} from '~/redux/root-reducer';
import {DEFAULT_FILTER_AT_LEVEL, DEFAULT_FILTER_AT_POSITION, QUERY_MOBILE} from '~/constants/index';
import {useInput} from '~/hooks';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const renderNotFound = () => (
  <Row w="100%" justifyContent="center" mt="50px">
    <Col w="50%" textAlign="center">
      <UiText fontSize="2xl">No jobs found</UiText>
      <UiText mt="15px" fontSize="lg">
        Your search does not match any job. Connect with us to stay up to date on new openings.
      </UiText>
    </Col>
  </Row>
);

const JobsPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const [selectedLevel, setSelectedLevel] = useState<string>(DEFAULT_FILTER_AT_LEVEL);
  const positionID = useSelector((state: RootState) => state.jobs.positionId);
  const positionName = useSelector((state: RootState) => state.jobs.positionName);
  const [selectedPosition, setSelectedPosition] = useState<string>(positionName || DEFAULT_FILTER_AT_POSITION);
  const {inputs: queryParams, setInputs: setQueryParams} = useInput({limit: 6, page: 1, position_id: positionID});
  const {data: levels} = useSelector(getLevels);
  const {data: positions} = useSelector(getPositions);
  const {data: jobs, paginator} = useSelector(getJobs);

  useScrollToTop();

  useEffect(() => {
    const fetchData = async () => {
      await dispatch(fetchLevels({source: SOURCES_LEVEL.LEVELS_PAGE}));
      await dispatch(fetchPositions({source: SOURCES_POSITION.POSITIONS_PAGE}));
    };
    fetchData();
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 200);
    const fetchData = async () => {
      await dispatch(fetchJobs({source: SOURCES_JOB.JOBS_PAGE, ...queryParams}));
    };
    fetchData();
  }, [dispatch, queryParams]);

  const handleFilterPosition = ({id, name}: PositionApiItem) => {
    setQueryParams({position_id: id, page: 1});
    setSelectedPosition(name);
  };

  const handleFilterLevel = ({id, name}: LevelApiItem) => {
    setQueryParams({level_id: id, page: 1});
    setSelectedLevel(name);
  };

  const setAllFilterPosition = () => {
    setQueryParams({position_id: null, page: 1});
    setSelectedPosition('All Positions');
  };

  const setAllFilterLevel = () => {
    setQueryParams({level_id: null, page: 1});
    setSelectedLevel('Any Level');
  };

  const handleChangePage = ({selected}: {selected: number}) => {
    setQueryParams({page: selected + 1});
  };

  return (
    <UiBox p="100px 0px">
      <UiContainer maxW="container.xl" w={{base: '100%', lg: '76%'}} pos="relative">
        <Col textAlign="center">
          <UiTitle fontSize="4xl">Current Vacancies</UiTitle>
          <UiText fontSize="xl" mt="20px">
            Ready for a new challenge in your life? Check out our open vacancies!
          </UiText>
        </Col>
        <Row mt="40px" alignItems="center" justifyContent={{base: 'flex-start', lg: 'flex-end'}}>
          <UiBox
            as={isDesktop ? Row : Col}
            justifyContent={{base: 'flex-start', lg: 'flex-end'}}
            alignItems={{base: 'flex-start', lg: 'flex-end'}}
          >
            <UiBox mb={{base: '10px', lg: '0px'}}>
              <UiMenu>
                <UiMenuButton
                  fontSize="md"
                  bgColor="transparent"
                  border=" 2px solid #FB8C00"
                  as={UiButton}
                  rightIcon={<RiArrowDownSLine />}
                  actionName="Filter Position"
                >
                  {selectedPosition}
                </UiMenuButton>
                <UiMenuList>
                  <UiMenuItem onClick={setAllFilterPosition}>All Positions</UiMenuItem>
                  {positions.map((position: PositionApiItem) => {
                    return (
                      <UiMenuItem
                        key={position.key}
                        value={position.name}
                        onClick={() => handleFilterPosition(position)}
                      >
                        {position.name}
                      </UiMenuItem>
                    );
                  })}
                </UiMenuList>
              </UiMenu>
            </UiBox>
            <UiBox ml={{base: '0px', lg: '20px'}}>
              <UiMenu>
                <UiMenuButton
                  fontSize="md"
                  bgColor="transparent"
                  border=" 2px solid #FB8C00"
                  as={UiButton}
                  rightIcon={<RiArrowDownSLine />}
                  actionName="Filter Level"
                >
                  {selectedLevel}
                </UiMenuButton>
                <UiMenuList>
                  <UiMenuItem onClick={setAllFilterLevel}>All</UiMenuItem>
                  {levels.map((level: LevelApiItem) => {
                    return (
                      <UiMenuItem key={level.key} value={level.name} onClick={() => handleFilterLevel(level)}>
                        {level.name}
                      </UiMenuItem>
                    );
                  })}
                </UiMenuList>
              </UiMenu>
            </UiBox>
          </UiBox>
        </Row>
        {jobs.length ? (
          <UiGrid
            templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)'}}
            columnGap={{base: 3, lg: 6}}
            rowGap={5}
            mt="20px"
          >
            {jobs.map((job: JobApiItem) => {
              return <JobCard key={job.id} job={job} />;
            })}
          </UiGrid>
        ) : (
          renderNotFound()
        )}
        {paginator?.total_pages! > 1 && (
          <Row
            mt="20px"
            justifyContent="flex-end"
            sx={{
              '& ul': {display: 'flex', listStyle: 'none'},
              '& li': {
                display: 'flex',
                w: '32px',
                h: '32px',
                ml: '10px',
                borderRadius: '8px',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                transition: '0.3s',
                border: '1px solid  rgba(0, 0, 0, 0.23)',
                color: 'rgba(0, 0, 0, 0.87)',
              },
              '& .selected': {
                border: '1px solid orange.400',
                bgColor: 'rgba(251,140,0,0.28)',
                color: 'orange.400',
              },
              '& a': {
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              },
            }}
          >
            <ReactPaginate
              breakLabel="..."
              nextLabel={<UiIcon as={MdKeyboardArrowRight} color="black" />}
              onPageChange={handleChangePage}
              pageRangeDisplayed={1}
              pageCount={paginator?.total_pages || 1}
              previousLabel={<UiIcon as={MdKeyboardArrowLeft} color="black" />}
            />
          </Row>
        )}
      </UiContainer>
    </UiBox>
  );
};

export default JobsPage;
