import {DiLaravel, DiMongodb, DiNodejsSmall, DiPhp, DiPostgresql, DiReact} from 'react-icons/di';
import {FaAws, FaVuejs} from 'react-icons/fa';
import {GrGraphQl} from 'react-icons/gr';
import {SiGoland, SiRubyonrails} from 'react-icons/si';

export const techStacks = [
  {
    icon: DiReact,
    name: 'React Native',
    color: '#61dbfb',
  },
  {
    icon: DiPhp,
    name: 'PHP',
    color: '#474A8A',
  },
  {
    icon: DiLaravel,
    name: 'Laravel',
    color: '#F44F5A',
  },
  {
    icon: FaVuejs,
    name: 'VueJs',
    color: '#1ABC9C',
  },
  {
    icon: FaAws,
    name: 'AWS',
    color: '#F1C40F',
  },
  {
    icon: DiPostgresql,
    name: 'Postgres Sql',
    color: '#0277BD',
  },
  {
    icon: DiNodejsSmall,
    name: 'NodeJS',
    color: '#6cc24a',
  },
  {
    icon: SiGoland,
    name: 'Golang',
    color: '#29BEB0',
  },
  {
    icon: DiMongodb,
    name: 'MongoDB',
    color: '#3FA037',
  },
  {
    icon: DiReact,
    name: 'React Js',
    color: '#61dbfb',
  },
  {
    icon: GrGraphQl,
    name: 'GraphQL',
    color: '#FF4081',
  },
  {
    icon: SiRubyonrails,
    name: 'Ruby',
    color: '#D50000',
  },
];
