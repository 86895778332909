import React from 'react';
import {Col, Row, UiBox, UiContainer, UiIcon, UiText, UiTitle} from '~/components/elements/index';
import Approach from '~/pages/story/components/easeAppStory/Approach';
import Exam1 from '~/pages/story/components/easeAppStory/Exam1';
import Exam2 from '~/pages/story/components/easeAppStory/Exam2';
import Exam3 from '~/pages/story/components/easeAppStory/Exam3';
import Result from '~/pages/story/components/easeAppStory/Result';
import Banner from '~/pages/story/components/easeAppStory/Banner';
import {GrClose} from 'react-icons/gr';
import * as Scroll from 'react-scroll';
import '~/assets/css/main.css';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

let Link = Scroll.Link;

interface Props {
  onClose: () => void;
}

const EaseAppStory = ({onClose}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox
      pb={{base: '50px', lg: '100px'}}
      mt={{base: '20px', lg: '100px'}}
      className={'animate__animated animate__slideInUp animate__duration-1s'}
      pos="relative"
    >
      <UiContainer pos="sticky" top="85px" zIndex="1000" maxW="container.xl" w={{base: '100%', lg: '95%'}}>
        <Row justifyContent="flex-end" zIndex="1000">
          <Link to="easeAppBanner" smooth={true} hashSpy={true} offset={-150} duration={800} onClick={onClose}>
            <UiBox
              as={isDesktop ? Col : Row}
              alignItems="center"
              justifyContent="center"
              w={{base: '130px', lg: '70px'}}
              h={{base: '50', lg: '70px'}}
              borderRadius="3px"
              cursor="pointer"
              bgColor="white"
              right="0px"
              mr={{base: '0px', lg: '10px'}}
            >
              {!isDesktop && <UiText color="black">Close</UiText>}
              <UiIcon as={GrClose} width="30px" height="30px" />
              {isDesktop && <UiText>Close</UiText>}
            </UiBox>
          </Link>
        </Row>
      </UiContainer>

      <UiContainer
        h="100%"
        maxW="container.xl"
        w={{base: '100%', lg: '95%'}}
        top="0px"
        left="0"
        right="0"
        ml="auto"
        marginRight="auto"
        pos={{base: 'relative', lg: 'absolute'}}
      >
        <Col textAlign="center">
          <UiText fontSize={{base: 'xs', lg: 'xl'}}>BUILD IDEAS FROM SCRATCH AND MANAGE AN ENTIRE SERVICE</UiText>
          <UiTitle fontSize={{base: 'lg', lg: '5xl'}} fontWeight="400" mb="30px">
            an all-in-one women’s health super-app
          </UiTitle>
        </Col>
      </UiContainer>
      <Col>
        <Banner />
        <Approach />
        <Exam1 />
        <Exam2 />
        <Exam3 />
        <Result />
      </Col>
    </UiBox>
  );
};

export default EaseAppStory;
