import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Col, Row, UiText, UiTitle} from '~/components/elements';
import {UNSUBSCRIBE_PARAMS} from '~/constants/unsubscribe';
import {addMailUnsubscribe} from '~/redux/mail-unsubscribes/thunk';
import {AppDispatch} from '~/redux/root-store';
import useMetaTags from 'react-metatags-hook';

const UnsubscribePage = () => {
  const dispatch: AppDispatch = useDispatch();
  const {search} = useLocation();
  const query = React.useMemo(() => new URLSearchParams(search), [search]);

  const email = query.get(UNSUBSCRIBE_PARAMS.EMAIL) || '';
  const id = query.get(UNSUBSCRIBE_PARAMS.ID) || '';
  const weeks = Number(query.get(UNSUBSCRIBE_PARAMS.WEEKS) || 0);

  useMetaTags(
    {
      title: `Unsubscribe`,
      charset: 'utf8',
      lang: 'en',
      metas: [{name: 'robots', content: 'noindex,nofollow'}],
    },
    [],
  );

  useEffect(() => {
    const submitData = async () => {
      const isSnooze = weeks && id;
      const isUnsubscribeACategory = !isSnooze && id;
      const isUnsubscribeAll = !isSnooze && !isUnsubscribeACategory && email;
      let postParams = undefined;
      if (isSnooze) {
        postParams = {id, day: weeks * 7};
      } else if (isUnsubscribeACategory) {
        postParams = {id};
      } else if (isUnsubscribeAll) {
        postParams = {email};
      }
      if (postParams) {
        await dispatch(addMailUnsubscribe({data: postParams}));
      }
    };
    submitData();
  }, [weeks, dispatch, id, email]);

  return (
    <Row h="100vh" bgColor="#eff1f2" alignItems="center" justifyContent="center">
      <Col
        p="30px"
        w={{base: '90%', lg: '40vw'}}
        borderRadius="12px"
        boxShadow="rgb(0 0 0 / 20%) 0px 3px 3px -2px, rgb(0 0 0 / 14%) 0px 3px 4px 0px, rgb(0 0 0 / 12%) 0px 1px 8px 0px"
      >
        {weeks ? (
          <Col>
            <UiTitle fontSize="5xl" textAlign="center">
              We are delighted that you are interested.
            </UiTitle>
            <UiText m="10px 0px" fontSize="xl" p={{base: '0px', lg: '0px 30px'}}>
              We will bring you newsletters with information about Rayyone&apos;s services and much more after{' '}
              {weeks === 1 ? `${weeks} week` : `${weeks} weeks`}.
              <br />
              Cheers,
            </UiText>
          </Col>
        ) : (
          <Col>
            <UiTitle fontSize="5xl" textAlign="center">
              Sorry about that, you&apos;re now off the list
            </UiTitle>
            <UiText m="10px 0px" fontSize="xl" textAlign="center" p={{base: '0px', lg: '0px 30px'}}>
              You have been successfully removed from the list and won&apos;t receive any further emails from us.
            </UiText>
          </Col>
        )}
      </Col>
    </Row>
  );
};

export default UnsubscribePage;
