import {PAGES} from '~/constants/app';
import {AiFillGithub, AiFillLinkedin} from 'react-icons/ai';
import {IoLogoWhatsapp} from 'react-icons/io';
import {BsGoogle} from 'react-icons/bs';
import config from '~/config/app-config';

export const SUBSCRIBER_API_CATEGORY = 'rayyone';

const rayyoneDocHost = 'https://docs.rayyone.com';

export const rayyoneDocUrls = {
  HOME: `${rayyoneDocHost}/docs/intro`,
  GOLANG: `${rayyoneDocHost}/docs/category/ry-golang`,
  MOBILE_APP: `${rayyoneDocHost}/docs/category/ry-mobile-app`,
  ADMIN_PORTAL: `${rayyoneDocHost}/docs/category/ry-admin-portal`,
  AWS: `${rayyoneDocHost}/docs/category/ry-infrastructure`,
};

export const socialUrls = {
  LINKEDIN: 'https://www.linkedin.com/company/rayyone',
  GIT_HUB: 'https://github.com/rayyone',
  GMAIL: 'hello@rayyone.com',
  WHATSAPP: '84905122811',
};

export const menu = [
  {
    key: 1,
    title: 'Solutions',
    pages: [
      {key: 1, title: 'Product development', url: PAGES.BUILD_IDEAS},
      {key: 2, title: 'Service rewritten', url: PAGES.BUILD_IDEAS},
      {key: 3, title: 'MVP development', url: PAGES.START_UP},
      {key: 4, title: 'Offshore team building', url: PAGES.EXPAND_TEAM},
      {key: 5, title: 'Entire service management', url: PAGES.EXPAND_TEAM},
    ],
  },
  {
    key: 2,
    title: 'Resources',
    pages: [
      {key: 1, title: 'Tools/ Docs', url: rayyoneDocUrls.HOME},
      {key: 2, title: 'Developer Tools', url: rayyoneDocUrls.HOME},
    ],
  },
  {
    key: 3,
    title: 'Rayyone',
    pages: [
      {key: 1, title: 'Home', url: '/'},
      {key: 2, title: 'How we work', url: PAGES.HOW_WE_WORK},
      {key: 3, title: 'Portfolios', url: PAGES.STORIES},
      {key: 4, title: 'Company', url: PAGES.COMPANY},
      {key: 5, title: 'Contact Us', url: PAGES.CONTACT_US},
      {key: 6, title: 'Careers', url: PAGES.CAREER},
    ],
  },
  {
    key: 4,
    title: 'Useful Links',
    pages: [
      {key: 1, title: 'Privacy Policy', url: PAGES.POLICY},
      {key: 2, title: 'Sitemap', url: config.url + '/sitemap.xml'},
    ],
  },
];

export const connects = [
  {key: 1, title: 'Email', icon: BsGoogle, url: `mailto:${socialUrls.GMAIL}`},
  {key: 2, title: 'Linkedin', icon: AiFillLinkedin, url: socialUrls.LINKEDIN},
  {key: 3, title: 'Github', icon: AiFillGithub, url: socialUrls.GIT_HUB},
  {
    key: 4,
    title: 'Whatsapp',
    icon: IoLogoWhatsapp,
    mobileUrl: `whatsapp://send?phone=${socialUrls.WHATSAPP}`,
    url: `https://wa.me/${socialUrls.WHATSAPP}`,
  },
];
