import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiLink, UiText} from '~/components/elements';
import '~/pages/home-page/style/EaseProject.css';
import {QUERY_MOBILE} from '~/constants';
import {TextProps, useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {color} from '~/theme/color';
import {UiTitle} from '~/components/elements/UiTitle';
import '~/assets/css/main.css';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import config from '~/config/app-config';
import {STORE_NAME} from '~/constants/tracker';
import EaseMobileAnimation from '~/components/shared/EaseMobileAnimation';

const Title = (p: TextProps) => (
  <UiTitle fontSize={{base: '4xl', lg: '6xl'}} lineHeight={{base: '35px', lg: '60px'}} mb="25px" {...p} />
);

const Description = (p: TextProps) => (
  <UiText
    color={color.home.textDescription}
    fontSize={{base: 'sm', lg: 'md'}}
    lineHeight={{base: '18px', lg: '25px'}}
    mb="45px"
    {...p}
  />
);

const Header = (p: TextProps) => (
  <UiTitle mb={{base: '35', lg: '25px'}} fontSize={{base: '2xl', lg: '4xl'}} lineHeight="22px" {...p} />
);

const Content = (p: TextProps) => (
  <UiTitle fontSize={{base: 'sm', lg: 'xl'}} lineHeight="30px" {...p} fontWeight="400" />
);

const EaseHealthcare = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const colorText = useColorModeValue('easeMobile.800', '#95DCBC');

  return (
    <UiContainer h="100%" maxW="container.xl" pt="40px" pos="relative" w={{base: '90%', lg: '76%'}}>
      <Row alignItems="center" mb={{base: '50px', lg: '100px'}}>
        <UiBox mr={{base: '0px', lg: '100px'}}>{isDesktop && <EaseMobileAnimation />}</UiBox>

        <Col>
          <Title color={colorText}>EASE HEALTHCARE</Title>
          <Description>
            {!isDesktop && 'The most in-depth free contraception, period tracker & women’s health app'}
            {isDesktop &&
              'Explore the most in-depth free contraception, period trackers & women’s health app with period tracking, contraception alerts, wellness tips, virtual medical care & community resources.'}
          </Description>
          {!isDesktop && <EaseMobileAnimation />}
          <Header color={colorText}>Outputs:</Header>
          <Content>1. New feature launched less than 2 weeks</Content>
          <Content>2. MoM App User Growth Rate exceeds 50%</Content>
          <Content>3. 4.8 rating on both App Store and Google Play</Content>
          <Content>4. 20,000+ monthly active users</Content>
          <Content>5. 99.8% of crash-free users</Content>
          <Row mt="20px" mb={{base: '10px', lg: '0px'}} justifyContent="center">
            <UiLink href={config.storeUrl.ios} target="_blank" actionName={STORE_NAME.APP_STORE}>
              <UiImage
                filePath="home/appstore.png"
                height={{base: '2.0625em', lg: '2.9375em'}}
                mr="20px"
                cursor="pointer"
              />
            </UiLink>
            <UiLink href={config.storeUrl.android} actionName={STORE_NAME.CH_PLAY} target="_blank">
              <UiImage filePath="home/chplay.png" height={{base: '2.0625em', lg: '2.9375em'}} cursor="pointer" />
            </UiLink>
          </Row>
        </Col>
      </Row>
    </UiContainer>
  );
};

export default EaseHealthcare;
