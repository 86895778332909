import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {MailUnsubscribeEntity} from '~/types/models/mail-unsubscribe';

class EntityConfig extends BaseEntityConfig<MailUnsubscribeEntity> {
  entityKey = 'mailUnsubscribes';

  associates = [];

  whiteListFields: WhiteListFields<MailUnsubscribeEntity> = ['*'];

  getAccessors = (value: MailUnsubscribeEntity) => {
    //
  };
}

const mailUnsubscribeEntConf = new EntityConfig();

export const mailUnsubscribeSchema = mailUnsubscribeEntConf.getEntitySchema();

export default mailUnsubscribeEntConf;

export const SOURCES_MAIL_UNSUBSCRIBE = {
  MAIL_UNSUBSCRIBES_PAGE: 'MAIL_UNSUBSCRIBES_PAGE',
};
