import React, {useEffect, useState} from 'react';
import {Col, UiBox, UiContainer, UiImage, UiText, UiTitle} from '~/components/elements';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {randomNumberRanges, sleep} from '~/utils/helpers/functions';
import {omit} from 'lodash';
import {QUERY_MOBILE} from '~/constants';

interface MemberChakraProps {
  height: {base: string; lg: string};
  top?: any;
  right?: any;
  bottom?: any;
  left?: any;
}

interface Member {
  avatar: string;
  isShow: boolean;
  chakraProps: MemberChakraProps;
}

const team: Member[] = [
  {
    avatar: 'company/minh.png',
    chakraProps: {
      top: {base: '20%', lg: '28%'},
      right: '5%',
      height: {base: '3.875em', lg: '5.75em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/hung.png',
    chakraProps: {
      top: {base: '25.5%', lg: '28%'},
      left: {base: '5%', lg: '20%'},
      height: {base: ' 4.125em', lg: '6em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/an.png',
    chakraProps: {
      bottom: {base: '52%', lg: '32%'},
      right: {base: '45%', lg: '40%'},
      height: {base: '4.125em', lg: '6em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/thinh.png',
    chakraProps: {
      top: {base: '21%', lg: '38%'},
      right: {base: '30%', lg: '20%'},
      height: {base: '4.375em', lg: '6.375em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/phuoc.png',
    chakraProps: {
      top: {base: '5%', lg: '23%'},
      left: {base: '15%', lg: '45%'},
      height: {base: '5em', lg: '8em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/nhi.png',
    chakraProps: {
      top: {base: '5%', lg: '40%'},
      left: {base: '62%', lg: '33%'},
      height: {base: '4.25em', lg: '7.5em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/khoi.png',
    chakraProps: {
      bottom: {base: '39%', lg: '32%'},
      left: {base: '35%', lg: '20%'},
      height: {base: ' 4.375em', lg: '5.25em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/trang.png',
    chakraProps: {
      bottom: {base: '12%', lg: '17%'},
      left: {base: '32%', lg: '10%'},
      height: {base: '4em', lg: '5.375em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/nhui.png',
    chakraProps: {
      bottom: {base: '52%', lg: '12%'},
      right: {base: '4%', lg: '0%'},
      height: {base: ' 4.5em', lg: '6.125em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/dat.png',
    chakraProps: {
      top: {base: '53%', lg: '43%'},
      left: {base: '75%', lg: '5%'},
      height: {base: '4.125em', lg: '6.375em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/hang.png',
    chakraProps: {
      bottom: {base: '21%', lg: '7%'},
      right: {base: '70%', lg: '40%'},
      height: {base: '4em', lg: '5.875em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/long.png',
    chakraProps: {
      bottom: {base: '37%', lg: '32%'},
      right: {base: '70%', lg: '5%'},
      height: {base: '4.625em', lg: '5.375em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/ngan.png',
    chakraProps: {
      bottom: {base: '17%', lg: '9%'},
      left: {base: '75%', lg: '30%'},
      height: {base: '3.875em', lg: '6.125em'},
    },
    isShow: true,
  },
  {
    avatar: 'company/giang.png',
    chakraProps: {
      bottom: {base: '24.5%', lg: '17%'},
      right: '25%',
      height: {base: '4em', lg: '6.625em'},
    },
    isShow: true,
  },
];

const getRandomMembers = (amount: number, members: Member[]) => {
  if (amount > members.length) {
    throw new Error('Amount must less than hidden members length');
  }

  let cloneMembers = [...members];
  let randomMembers = [];

  for (let i = 0; i < amount; i++) {
    const randomIdx = randomNumberRanges(cloneMembers.length);
    randomMembers.push(cloneMembers[randomIdx]);
    cloneMembers.splice(randomIdx, 1);
  }

  return randomMembers;
};

const AMOUNT_OF_MEMBER = 2;
const MAX_HIDDEN_MEMBER_NUMBER = 4;

const hideMembers = (members: Member[]) => {
  members.forEach(m => (m.isShow = false));
};

const showMembers = (members: Member[]) => {
  members.forEach(m => (m.isShow = true));
};

const getMembersShouldShow = () => {
  const hidingMembers = team.filter(member => !member.isShow);

  if (hidingMembers.length >= MAX_HIDDEN_MEMBER_NUMBER) {
    return hidingMembers;
  }

  const randomAmount = randomNumberRanges(hidingMembers.length);
  return getRandomMembers(randomAmount, hidingMembers);
};

const renderMember = () => {
  return team.map((value: Member, index: number) => {
    return (
      <UiBox
        key={index}
        transitionDuration="1.5s"
        pos="absolute"
        transform={value.isShow ? 'scale(1)' : 'scale(0)'}
        {...omit(value.chakraProps, ['width', 'height'])}
      >
        <UiImage filePath={value.avatar} h={value.chakraProps.height} />
      </UiBox>
    );
  });
};

export const Team = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const fontColor = useColorModeValue('easeMobile.800', 'easeMobile.800');
    const [rerenderAt, forceRerender] = useState(Date.now());

    useEffect(() => {
      const runAnimation = async () => {
        showMembers(getMembersShouldShow());
        hideMembers(getRandomMembers(AMOUNT_OF_MEMBER, team));
        await sleep(1500);
        forceRerender(Date.now());
      };

      runAnimation();
    }, [rerenderAt]);

    return (
      <UiBox ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiBox bg="orange.400">
          <UiContainer
            h="100%"
            maxW="container.xl"
            textAlign="center"
            color="white"
            p={{lg: '50px 0px', base: '30px 10px'}}
          >
            <UiTitle fontSize={{base: '2xl', lg: '6xl'}}>Your information stays in house</UiTitle>
            <UiText fontSize={{base: 'lg', lg: '2xl'}}>
              The team with which you choose to work is fully dedicated to your project. We mainly work from Vietnam,
              and <strong>we do not outsource any of the tasks</strong> we are hired to complete.
              <br /> Information is at the core of our business and we make sure that it’s kept private and secure.
            </UiText>
          </UiContainer>
        </UiBox>
        <Col mt={{base: '60px', lg: '100px'}}>
          <UiContainer maxW="container.xl" w={{base: '98%', lg: '76%'}} h={{base: '70vh', lg: '100vh'}} pos="relative">
            <UiTitle fontSize={{base: '2xl', lg: '6xl'}} lineHeight={{base: '30px', lg: '80px'}} textAlign="center">
              A team that goes an extra mile for your products
            </UiTitle>
            {isDesktop && renderMember()}
            {!isDesktop && (
              <UiBox h="70vh" pos="relative">
                {renderMember()}
              </UiBox>
            )}
          </UiContainer>
        </Col>
      </UiBox>
    );
  }),
);
