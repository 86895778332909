import {Button, ButtonProps} from '@chakra-ui/react';
import moment from 'moment';
import React, {memo} from 'react';
import {useDispatch} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {TRACKER_TYPES} from '~/constants/tracker';
import {AppDispatch} from '~/redux/root-store';
import {setTracker} from '~/redux/tracker/slice';
import {TrackerApiItem} from '~/types/models/tracker';
import {isStringOrNumber} from '~/utils/helpers';

interface Props extends ButtonProps {
  actionName?: string;
}

const orangeProps = {
  colorScheme: 'orange',
  bg: 'orange.100',
  color: 'orange.700',
  _hover: {backgroundColor: 'orange.500', color: 'white', borderColor: 'orange.500'},
  _active: {backgroundColor: 'orange.500', color: 'white'},
};

export const UiButton = memo(
  React.forwardRef<HTMLButtonElement, Props>(({actionName = '', children, onClick, ...rest}: Props, ref) => {
    const {pathname, search} = useLocation();
    const dispatch: AppDispatch = useDispatch();

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      const value = actionName || (isStringOrNumber(children) ? children : '');

      const action: TrackerApiItem = {
        duration: 0,
        value: value,
        path: `${pathname}${search}`,
        triggered_at: moment().format(),
        type: TRACKER_TYPES.BUTTON,
      };
      dispatch(setTracker(action));
      if (onClick) {
        onClick(e);
      }
    };

    return (
      <Button ref={ref} onClick={handleClick} {...(rest.colorScheme === undefined && orangeProps)} {...rest}>
        {children}
      </Button>
    );
  }),
);
