import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {LevelEntity, NormalizedLevel} from '~/types/models/levels';
import {levelSchema} from '~/redux/levels/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchLevelsParams extends FetchParams {}

const query = thunk.query<LevelEntity, NormalizedLevel>('level', levelSchema);
export const fetchLevels = query.fetch<FetchLevelsParams>(ENDPOINTS.LEVELS);
