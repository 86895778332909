import React, {useEffect} from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiIcon, UiText, UiTitle} from '~/components/elements';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import {IoChevronDownCircleOutline} from 'react-icons/io5';
import {JobApiItem} from '~/types/models/jobs';
import {BsDot} from 'react-icons/bs';

interface Props {
  onClickDownArrow: () => void;
  onClickStart: () => void;
  job?: JobApiItem;
}

const Banner = React.memo(({onClickDownArrow, onClickStart, job}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox
      h="100vh"
      bgImage={`url(${job?.image_thumbnail_url})`}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      backgroundPosition={{base: '50%', lg: '100%'}}
    >
      <Col h="100%" bgImage="linear-gradient(rgba(0,0,0,.2),rgba(74,74,74,0.4))">
        <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '89%', lg: '75%'}}>
          <Col h="100%" color="white" alignItems="center" justifyContent="center" textAlign="center" pos="relative">
            <UiTitle fontSize={{base: '3xl', lg: '7xl'}} lineHeight="1.3em" mt={{base: '20px', lg: '0px'}}>
              {job?.title}
            </UiTitle>
            <Row alignItems="center">
              <UiIcon as={BsDot} color="white" style={{width: '3em', height: '3em', margin: '0px'}} />
              <UiText alignItems="center" fontSize={{base: 'xl', lg: '4xl'}}>
                {job?.position?.name}
              </UiText>
            </Row>
            <UiText fontSize={{base: 'lg', lg: '2xl'}} mt={{base: '20px', lg: '0px'}}>
              ⭐️ Hey! Welcome to the Rayyone application form! To start your career with us, please fill out the form
              in English!
            </UiText>
            <UiButton borderRadius="30px" p="16px 28px" fontSize="md" minWidth="220px" mt="50px" onClick={onClickStart}>
              Start your journey here!
            </UiButton>
            {isDesktop && (
              <Row pos="absolute" bottom="5%" cursor="pointer" onClick={onClickDownArrow}>
                <UiIcon as={IoChevronDownCircleOutline} color="white" width="48px" height="48px" />
              </Row>
            )}
          </Col>
        </UiContainer>
      </Col>
    </UiBox>
  );
});

export default Banner;
