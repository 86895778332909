import React, {useEffect, useState} from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiImage, UiLink, UiText, UiTitle} from '~/components/elements';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {Link, useHistory, useLocation} from 'react-router-dom';
import {randomNumberRanges, sleep} from '~/utils/helpers';
import {ComponentProps} from '~/types/models/app';
import {MEMBERS} from '~/constants/service';

interface Props {
  hiddenLink?: boolean;
}

const AMOUNT_OF_MEMBER = 2;

const Balloon = (p: ComponentProps) => <UiBox pos="absolute" borderRadius="50%" w={p.size} h={p.size} {...p} />;

const Tag = (p: ComponentProps) => (
  <UiBox
    p={{base: '2px 7px', lg: '3px 13px'}}
    border="2px solid #F5F5F5"
    borderRadius="40px"
    justifyContent="center"
    alignItems="center"
    pos="absolute"
    color="white"
    fontSize={{base: '8px', lg: 'xl'}}
    {...p}
  />
);

const getRandomMembers = (amount: number) => {
  let cloneMembers = [...MEMBERS];
  let randomMembers = [];
  for (let i = 0; i < amount; i++) {
    const randomIdx = randomNumberRanges(cloneMembers.length);
    randomMembers.push(cloneMembers[randomIdx]);
    cloneMembers.splice(randomIdx, 1);
  }
  return randomMembers;
};

const ContactUsScrumTeam = ({hiddenLink}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const [rerenderAt, forceRerender] = useState(Date.now());
  const [membersShouldShow, setMembersShouldShow] = useState<string[]>([MEMBERS[0], MEMBERS[MEMBERS.length - 1]]);
  const history = useHistory();
  const colorButton = useColorModeValue('black', 'white');
  const location = useLocation();

  useEffect(() => {
    const runAnimation = async () => {
      setMembersShouldShow(getRandomMembers(AMOUNT_OF_MEMBER));
      await sleep(2000);
      forceRerender(Date.now());
    };
    runAnimation();
  }, [rerenderAt]);

  const onClickContactUs = () => {
    history.push(PAGES.CONTACT_US);
  };

  const isServicesPath = location.pathname === PAGES.SERVICE;

  return (
    <UiBox mb={{base: '50px', lg: '0px'}}>
      <UiContainer h="100%" maxW="container.xl" w={{base: '85%', lg: '76%'}} mr="auto" ml="auto" pos="relative">
        {!isServicesPath && (
          <Col fontSize={{base: 'xl', lg: '2xl'}} lineHeight="40px" textAlign="center" pb="20px" pos="relative">
            <UiTitle>Contact us!</UiTitle>
            <UiTitle mt={{base: '0px', lg: '20px'}}>
              {isDesktop ? 'Get your dedicated team to build your amazing product!' : 'Get dedicated growth team'}
            </UiTitle>
          </Col>
        )}
        <Col alignItems="center" pos="relative" pb="50px">
          <Row
            w={{base: '300px', lg: '475px'}}
            h={{base: '300px', lg: '475px'}}
            bgColor="#F5F5F5"
            pos="relative"
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
          >
            <UiImage
              filePath="home/phuoc.png"
              height={{base: '4.375em', lg: '7.125em'}}
              pos="absolute"
              top="40px"
              left="-20px"
            />
            <UiImage
              filePath={membersShouldShow[0]}
              height={{base: '3.4375em', lg: '5.625em'}}
              pos="absolute"
              top={{base: '210px', lg: '320px'}}
              right="0px"
            />
            <UiImage
              filePath="home/nhi.png"
              height={{base: '3.575em', lg: '6.375em'}}
              pos="absolute"
              top={{base: '65px', lg: '80px'}}
              right={{base: '-15px', lg: '-40px'}}
            />
            <UiImage
              filePath={membersShouldShow[1]}
              pos="absolute"
              top={{base: '240px', lg: '360px'}}
              left={{base: '40px', lg: '45px'}}
              height={{base: '3.4375em', lg: '5.625em'}}
            />
            <Tag bgColor="#95DCBC" top="55px" left="55%">
              SEO
            </Tag>
            <Tag bgColor="#C591E7" top="55%" left="5%">
              QA
            </Tag>
            <Tag bgColor="#FDD466" top="75%" left="65%">
              UI
            </Tag>
            <Tag bgColor="#95DCBC" top="85%" left={{base: '35%', lg: '30%'}} color="black">
              TECHNOLOGY
            </Tag>
            <UiText
              fontSize={!isServicesPath ? {base: 'xs', lg: 'xl'} : {base: 'sm', lg: 'lg'}}
              lineHeight={{base: '16px', lg: '40px'}}
              textAlign="center"
              p={{base: '65px', lg: '120px'}}
            >
              {!isServicesPath
                ? 'Our well-organized Scrum team is dedicated responsible end-to-end for the projects'
                : 'We gather great people from a wide range of backgrounds and hobbies to strive for excellence and make great things together - as a unique team!'}
            </UiText>
          </Row>
          <UiBox>
            <Balloon
              size={{base: '25px', lg: '55px'}}
              bgColor="#e3cdf74d"
              left={{base: '5px', lg: '150px'}}
              top={{base: '20px', lg: '150px'}}
            />
            <Balloon
              size={{base: '15px', lg: '28px'}}
              bgColor="#fdd4664d"
              left={{base: '12px', lg: '61px'}}
              top={{base: '120px', lg: '250px'}}
            />
            <Balloon
              size={{base: '20px', lg: '71px'}}
              bgColor="#fdd4664d"
              left={{base: '10px', lg: '210px'}}
              top={{base: '220px', lg: '280px'}}
            />
            <Balloon
              size={{base: '12px', lg: '28px'}}
              bgColor="#fb8c004d"
              left={{base: '280px', lg: '290px'}}
              top={{base: '270px', lg: '430px'}}
            />
            <Balloon
              size={{base: '13px', lg: '41px'}}
              bgColor="#95dcbc4d"
              right={{base: '320px', lg: '120px'}}
              top={{base: '200px', lg: '280px'}}
            />
            <Balloon
              size={{base: '22px', lg: '51px'}}
              bgColor="#ffdac14d"
              right={{base: '20px', lg: '20px'}}
              top={{base: '320px', lg: '100px'}}
            />
            <Balloon
              size={{base: '14px', lg: '32px'}}
              bgColor="#e3cdf74d"
              right={{base: '320px', lg: '180px'}}
              top={{base: '320px', lg: '180px'}}
            />
          </UiBox>
          {!isServicesPath && (
            <UiButton
              mt="50px"
              bgColor="transparent"
              border="3px solid #FB8C00"
              borderRadius="10px"
              color={colorButton}
              p={{base: '16px 22px', lg: '22px 32px'}}
              fontWeight="bold"
              fontSize={{base: 'xs', lg: 'xl'}}
              onClick={onClickContactUs}
            >
              Get your dedicated team
            </UiButton>
          )}
          {!hiddenLink && !isServicesPath && (
            <UiText fontSize={{base: 'xs', lg: 'xl'}} lineHeight="28px" mt={{base: '16px', lg: '30px'}}>
              <UiLink as={Link} to={PAGES.HOW_WE_WORK}>
                or discover How we work!
              </UiLink>
            </UiText>
          )}
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default ContactUsScrumTeam;
