import {ENDPOINTS} from '~/constants';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {MailUnsubscribeEntity, NormalizedMailUnsubscribe} from '~/types/models/mail-unsubscribe';
import {mailUnsubscribeSchema} from '~/redux/mail-unsubscribes/entity-config';
import {thunk} from '~/utils/thunk';

export interface FetchMailUnsubscribesParams extends FetchParams {}

export interface FetchMailUnsubscribeParams extends FetchParams {}

export interface AddMailUnsubscribeParams extends PostParams {
  data: Partial<MailUnsubscribeEntity> & {day?: number};
}

export interface UpdateMailUnsubscribeParams extends PutParams {
  data: Partial<MailUnsubscribeEntity>;
  id: string;
}

const query = thunk.query<MailUnsubscribeEntity, NormalizedMailUnsubscribe>('mailUnsubscribe', mailUnsubscribeSchema, {
  isIgnoreError: true,
});
export const fetchMailUnsubscribes = query.fetch<FetchMailUnsubscribesParams>(ENDPOINTS.MAIL_UNSUBSCRIBES);
export const addMailUnsubscribe = query.post<AddMailUnsubscribeParams>(ENDPOINTS.MAIL_UNSUBSCRIBES);
export const updateMailUnsubscribe = query.put<UpdateMailUnsubscribeParams>(`${ENDPOINTS.MAIL_UNSUBSCRIBES}/:id`);
export const deleteMailUnsubscribe = query.delete<DeleteParams>(`${ENDPOINTS.MAIL_UNSUBSCRIBES}/:id`, '', {
  withoutNormalize: true,
});

export const fetchMailUnsubscribe = query.fetchOne<FetchMailUnsubscribeParams>(`${ENDPOINTS.MAIL_UNSUBSCRIBES}/:id`);
