import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {CouponEntity} from '~/types/models/coupon';

class EntityConfig extends BaseEntityConfig<CouponEntity> {
  entityKey = 'coupons';

  associates = [];

  whiteListFields: WhiteListFields<CouponEntity> = ['*'];

  getAccessors = (value: CouponEntity) => {
    //
  };
}

const couponEntConf = new EntityConfig();

export const couponSchema = couponEntConf.getEntitySchema();

export default couponEntConf;

export const SOURCES_COUPON = {
  COUPONS_PAGE: 'COUPONS_PAGE',
};
