import React from 'react';
import {Col, Row, UiContainer, UiImage, UiLink, UiText, UiTitle} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import {resultsModal1} from '~/constants/story';
import {STORE_NAME} from '~/constants/tracker';
import config from '~/config/app-config';

const Result = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <Col bgColor="#456762" pb="50px">
      <UiTitle mt="50px" fontWeight="400" fontSize={{base: '3xl', lg: '6xl'}} textAlign="center" color="white">
        Result
      </UiTitle>
      <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '100%', lg: '70%'}} mt="50px">
        <Col>
          {resultsModal1.map((result: string, index) => {
            return (
              <Row key={index} w="100%" bgColor="white" borderRadius="20px" p={{base: '25px', lg: '30px'}} mb="30px">
                <UiText fontSize="xl">{result}</UiText>
              </Row>
            );
          })}
          <Row justifyContent="center" mt="20px">
            <UiLink
              href={config.storeUrl.ios}
              target="_blank"
              mb={{base: '10px', lg: '0px'}}
              actionName={STORE_NAME.APP_STORE}
            >
              <UiImage
                filePath="home/appstore.png"
                height={{base: '2.0625em', lg: '2.9375em'}}
                mr="20px"
                cursor="pointer"
              />
            </UiLink>
            <UiLink
              href={isDesktop ? config.storeUrl.android : 'https://play.google.com/store/apps/details?id=com.easeapp'}
              target="_blank"
              actionName={STORE_NAME.CH_PLAY}
            >
              <UiImage filePath="home/chplay.png" height={{base: '2.0625em', lg: '2.9375em'}} cursor="pointer" />
            </UiLink>
          </Row>
        </Col>
      </UiContainer>
    </Col>
  );
};

export default Result;
