import React, {useState} from 'react';
import {UiBox} from '~/components/elements';
import {ContactUs} from '~/components/shared/ContactUs';
import Banner from '~/pages/story/components/Banner';
import EaseAppBanner from '~/pages/story/components/EaseAppBanner';
import EaseWebBanner from '~/pages/story/components/EaseWebBanner';
import {tab} from '~/constants';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const StoryPage = () => {
  const [tabSelected, setTabSelected] = useState<string>(tab.DEFAULT);

  useScrollToTop();

  useMetaTags(
    {
      title: `Stories | Rayyone`,
      description: `View Rayyone stories to discover the advanced technical solutions we has developed for clients and are trusted by Forbes Asian Top 100 To Watch. Learn more!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content: 'technical solutions, Forbes Asian, Ease, Ease Healthcare, startup, rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  const changeTab = (value: string) => {
    setTabSelected(value);
  };

  const renderTab = () => {
    if (tabSelected === tab.DEFAULT)
      return (
        <>
          <EaseAppBanner />
          <EaseWebBanner />
        </>
      );
    if (tabSelected === tab.MOBILE) return <EaseAppBanner />;
    if (tabSelected === tab.LANDING_PAGE || tabSelected === tab.E_COMMERCE) return <EaseWebBanner />;
  };

  return (
    <UiBox>
      <Banner changeTab={changeTab} />
      {renderTab()}
      <ContactUs />
    </UiBox>
  );
};

export default StoryPage;
