import {ENDPOINTS} from '~/constants';
import {FetchParams, PostParams} from 'redux-thunk-kit';
import {jobSchema} from '~/redux/jobs/entity-config';
import {thunk} from '~/utils/thunk';
import {JobEntity, NormalizedJob} from '~/types/models/jobs';

export interface FetchJobsParams extends FetchParams {}

export interface FetchJobParams extends FetchParams {
  id: string;
}

export interface AddApplyJobParams extends PostParams {
  data: Partial<JobEntity>;
}

const query = thunk.query<JobEntity, NormalizedJob>('job', jobSchema);
export const fetchJobs = query.fetch<FetchJobsParams>(ENDPOINTS.JOBS);
export const fetchJob = query.fetchOne<FetchJobParams>(`${ENDPOINTS.JOBS}/:id`);
export const addApplyJob = query.postFormData<AddApplyJobParams>(`${ENDPOINTS.JOBS}/:id/apply`);
