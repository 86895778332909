import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {PositionEntity} from '~/types/models/positions';

class EntityConfig extends BaseEntityConfig<PositionEntity> {
  entityKey = 'positions';

  associates = [];

  whiteListFields: WhiteListFields<PositionEntity> = ['*'];

  getAccessors = (value: PositionEntity) => {};
}

const positionEntConf = new EntityConfig();

export const positionSchema = positionEntConf.getEntitySchema();

export default positionEntConf;

export const SOURCES_POSITION = {
  POSITIONS_PAGE: 'POSITION_PAGE',
  POSITIONS_DROPDOWN: 'POSITION_DROPDOWN',
};
