import {createEntityAdapter, createSlice} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {VisitorEntity} from '~/types/models/visitors';
import {RootState} from '~/redux/root-reducer';
import {createSelectAllBySource, DEFAULT_SOURCE_REDUCER_STATE, ReducerState} from 'redux-thunk-kit';
import {SOURCES_VISITORS} from '~/redux/visitors/entity-config';

/* Slice */
interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_VISITORS, () => DEFAULT_SOURCE_REDUCER_STATE);

const visitorsAdapter = createEntityAdapter<VisitorEntity>();

const initialState = visitorsAdapter.getInitialState<InitialState>({
  sources,
  request: {},
});

// Slice
const visitors = createSlice({
  name: 'visitors',
  initialState,
  reducers: {},
  extraReducers: builder => {
    // builder.addCase(fetchVisitors.fulfilled, (state, {payload}) => {
    //   const {normalized} = payload;
    //   console.log(normalized);
    //   // state.entities = {...state.currentVisitor, ...normalized.entities[normalized.result]};
    //   // fetchSuccess(state, payload);
    // });
    // builder.addCase(addVisitor.fulfilled, (state, {payload}) => {
    //   const {normalized} = payload;
    //   console.log(normalized);
    //   // state.entities = {...state.currentVisitor, ...normalized.entities[normalized.result]};
    //   // fetchSuccess(state, payload);
    // });
  },
});

// export const {} = visitors.actions;

export default visitors.reducer;

export const {
  selectById: selectVisitorById,
  selectIds: selectVisitorsIds,
  selectEntities: selectVisitorsEntities,
  selectAll: selectAllVisitors,
  selectTotal: selectTotalVisitors,
} = visitorsAdapter.getSelectors((state: RootState) => state.visitors);

export const [getVisitors, getDropdownVisitors] = createSelectAllBySource('visitors', selectVisitorsEntities, [
  SOURCES_VISITORS.VISITORS_PAGE,
]);
