import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';
import {thunk} from '~/utils/thunk';
import {NormalizedVisitor, VisitorEntity} from '~/types/models/visitors';
import {userSchema} from '~/redux/users/entity-config';
import {ENDPOINTS} from '~/constants';

export interface FetchVisitorsParams extends FetchParams {
  search?: string;
}

export interface UpdateVisitorParams extends PutParams {
  id?: number;
  data: Partial<VisitorEntity>;
}

export interface FetchVisitorsParams extends FetchParams {
  q?: string;
}

export interface AddVisitorParams extends PostParams {
  data: Partial<VisitorEntity>;
}

const query = thunk.query<VisitorEntity, NormalizedVisitor>('users', userSchema);

export const fetchVisitors = query.fetch<FetchVisitorsParams>(ENDPOINTS.VISITORS);
export const addVisitor = query.post<AddVisitorParams>(ENDPOINTS.VISITORS);
export const updateVisitor = query.put<UpdateVisitorParams>(`${ENDPOINTS.VISITORS}/:id`);
export const deleteVisitor = query.delete<DeleteParams>(`${ENDPOINTS.VISITORS}/:id`);
