import React from 'react';
import {UiBox} from '~/components/elements';
import EaseAdmin from './EaseAdmin';
import EaseHealthcare from './EaseHealthcare';

const EaseProject = () => {
  return (
    <UiBox pb="50px">
      <EaseHealthcare />
      <EaseAdmin />
    </UiBox>
  );
};

export default EaseProject;
