import React from 'react';
import {Col, UiBox, UiContainer, UiImage, UiText} from '~/components/elements/index';
import frameEffect from '~/assets/img/home/frame-effect.png';
import frameBrightBlue from '~/assets/img/home/frame-bright-blue.png';
import frameDarkBlue from '~/assets/img/home/frame-dark-blue.png';
import frameLightPink from '~/assets/img/home/frame-light-pink.png';
import frameOrange from '~/assets/img/home/frame-orange.png';
import frameRoyalBlue from '~/assets/img/home/frame-royal-blue.png';
import frameYellow from '~/assets/img/home/frame-yellow.png';
import {QUERY_MOBILE} from '~/constants';
import {BoxProps, TextProps, useMediaQuery} from '@chakra-ui/react';
import {color} from '~/theme/color';
import {UiTitle} from '~/components/elements/UiTitle';

const FrameIndustries = (p: BoxProps) => (
  <Col
    w={{base: '137px', lg: '227px'}}
    h={{base: '115px', lg: '192px'}}
    backgroundRepeat="no-repeat"
    backgroundSize="contain"
    alignItems="center"
    justifyContent="center"
    pos="absolute"
    // _hover={{
    //   '& .contentBx': {
    //     transform: 'scaleX(1)',
    //     transition: 'transform 0.5s ease-in-out',
    //   },
    // }}
    {...p}
  />
);

const FrameDescription = (p: BoxProps) => (
  <Col
    w="100%"
    h="100%"
    backgroundRepeat="no-repeat"
    backgroundSize="contain"
    alignItems="center"
    justifyContent="center"
    pos="absolute"
    backgroundImage={`url(${frameEffect})`}
    transform="scaleX(0)"
    transition="transform 0.5s ease-in-out"
    className="contentBx"
    {...p}
  />
);

const ImageIndustries = (p: any) => <UiImage {...p} h={{base: '2.6875em', lg: '4.5em'}} />;

const TextFrame = (p: TextProps) => (
  <UiTitle
    fontSize={{base: 'sm', lg: 'xl'}}
    lineHeight="20px"
    mt={{base: '8px', lg: '20px'}}
    w="120px"
    textAlign="center"
    {...p}
  />
);

const TextDescription = (p: TextProps) => (
  <UiText
    fontSize={{base: 'xs', lg: 'sm'}}
    lineHeight="20px"
    color="white"
    textAlign="center"
    w={{base: '100px', lg: '150px'}}
    {...p}
  />
);

const Industries = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  return (
    <UiBox h="100%">
      <UiContainer h="100%" maxW="container.xl" pt="40px" w={{base: '90%', lg: '76%'}}>
        <UiTitle fontSize={{base: '4xl', lg: '8xl'}} lineHeight={{base: '35px', lg: '80px'}}>
          Industries
        </UiTitle>
        <UiText
          color={color.home.textDescription}
          fontSize={{base: 'xs', lg: 'md'}}
          lineHeight={{base: '16px', lg: '25px'}}
          mt="21px"
        >
          Single point of responsibility.{!isDesktop && <br />} Multiple competences. Aligned for one goal.
          <br />
          We have had the pleasure of growing brands in:
        </UiText>

        <UiBox pos="relative" mt="40px" minH={{base: '500px', lg: '450px'}}>
          <FrameIndustries
            backgroundImage={`url(${frameBrightBlue})`}
            top={{base: '190px', lg: '100px'}}
            left={{base: '120px', lg: '0px'}}
          >
            <ImageIndustries filePath="home/fintech-1.png" />
            <TextFrame>Fintech</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
          <FrameIndustries
            backgroundImage={`url(${frameLightPink})`}
            left={{base: '120px', lg: '185px'}}
            top={{base: '65px', lg: '0px'}}
          >
            <ImageIndustries filePath="home/person-add.png" />
            <TextFrame>Social Application</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so 1on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
          <FrameIndustries
            backgroundImage={`url(${frameOrange})`}
            left={{base: '0px', lg: '185px'}}
            top={{base: '0px', lg: '200px'}}
          >
            <ImageIndustries filePath="home/camera.png" />
            <TextFrame>Streaming Application</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so 1on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
          <FrameIndustries
            backgroundImage={`url(${frameDarkBlue})`}
            top={{base: '125px', lg: '100px'}}
            left={{base: '0px', lg: '365px'}}
          >
            <ImageIndustries filePath="home/health-care.png" />
            <TextFrame>Healthcare</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so 1on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
          <FrameIndustries
            backgroundImage={`url(${frameOrange})`}
            left={{base: '0px', lg: '545px'}}
            top={{base: '250px', lg: '0px'}}
          >
            <ImageIndustries filePath="home/retailande-commerce.png" />
            <TextFrame>Retail and eCommerce</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so 1on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
          <FrameIndustries
            backgroundImage={`url(${frameYellow})`}
            left={{base: '0px', lg: '545px'}}
            top={{base: '375px', lg: '200px'}}
          >
            <ImageIndustries filePath="home/edit.png" />
            <TextFrame>Training and Education</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so 1on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
          <FrameIndustries
            backgroundImage={`url(${frameRoyalBlue})`}
            top={{base: '315px', lg: '100px'}}
            left={{base: '120px', lg: '725px'}}
          >
            <ImageIndustries filePath="home/food-and-beverage.png" />
            <TextFrame>Food and Beverage</TextFrame>
            <FrameDescription>
              <TextDescription>
                Everything from scheduling an appointment to most popular features as Chats with doctor, Video calls,
                prescription tracking, integration, so 1on,...
              </TextDescription>
            </FrameDescription>
          </FrameIndustries>
        </UiBox>
      </UiContainer>
    </UiBox>
  );
};

export default Industries;
