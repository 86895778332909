import {ENDPOINTS} from '~/constants';
import {userSchema} from '~/redux/users/entity-config';
import {setSessionIdUser} from '~/redux/tracker/slice';
import {saveSessionIdUser} from '~/services/auth';
import {TableState} from '~/types/general';
import {NormalizedUser, UserEntity} from '~/types/models/user';
import {thunk} from '~/utils/thunk';
import {DeleteParams, FetchParams, PostParams, PutParams} from 'redux-thunk-kit';

export interface FetchUsersParams extends FetchParams {
  search?: string;
}

export interface UpdateUserParams extends PutParams {
  id?: number;
  data: Partial<UserEntity>;
}

export interface FetchUsersParams extends FetchParams {
  q?: string;
}

export interface AddUserParams extends PostParams {
  data: Partial<UserEntity> & {role_ids?: string[]};
}

export interface DeleteAccountParams extends DeleteParams {}

export interface AddTableStateParams extends PostParams {
  data: {
    tableName: string;
    stateName: string;
    state: TableState;
  };
}

export interface DeleteTableStateParams extends PostParams {
  data: {
    tableName: string;
    stateName: string;
  };
}

const query = thunk.query<UserEntity, NormalizedUser>('users', userSchema);

export const onUserAccessMisc = query.wrapper<any>('onUserAccessMisc', async ({sessionId}, {dispatch}) => {
  try {
    await saveSessionIdUser(sessionId);
    await dispatch(setSessionIdUser({sessionId: sessionId}));
    // @ts-ignore
  } catch (e) {
    throw e;
  }
  return true;
});

export const fetchUsers = query.fetch<FetchUsersParams>(ENDPOINTS.USERS);
export const addUser = query.post<AddUserParams>(ENDPOINTS.USERS);
export const updateUser = query.put<UpdateUserParams>(`${ENDPOINTS.USERS}/:id`);
export const deleteUser = query.delete<DeleteParams>(`${ENDPOINTS.USERS}/:id`);
