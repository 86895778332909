import React from 'react';
import {Col, UiBox, UiContainer, UiText, UiTitle} from '~/components/elements';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {BoxProps, useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import '~/assets/css/main.css';
import {AnimationOnScroll} from 'react-animation-on-scroll';

const Balloon = (p: BoxProps) => <UiBox pos="absolute" {...p} />;

const BalloonContainer = (p: any) => (
  <Col
    bgColor="#dcdcdc4d"
    justifyContent="center"
    alignItems="center"
    borderRadius="50%"
    textAlign="center"
    lineHeight="30px"
    p="25px"
    h={p.size}
    w={p.size}
    {...p}
  />
);

const BalloonDefault = (p: BoxProps) => (
  <UiBox borderRadius="50%" w={{base: '40px', lg: '80px'}} h={{base: '40px', lg: '80px'}} {...p} />
);

const AreYou = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

    return (
      <UiBox h={{base: '75vh', lg: '100vh'}} mb={{base: '70px'}} ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiContainer h="100%" maxW="container.xl">
          <Col>
            <UiTitle fontSize={{base: '5xl', lg: '8xl'}} textAlign="center" textShadow="0px 4px 4px #00000040">
              ARE YOU?
            </UiTitle>
          </Col>
          <UiBox pos="relative" h="100%" overflow="hidden" fontSize={{base: '1xl', lg: '3xl'}}>
            <Balloon left={{base: '45%', lg: '30%'}} top={{base: '28%', lg: '5%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonDefault bgColor="#fdd4664d" />
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '80%', lg: '85%'}} top={{base: '45%', lg: '15%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonDefault bgColor="#ffdac14d" />
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '6%', lg: '15%'}} top={{base: '40%', lg: '60%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonDefault bgColor="#95dcbc4d" />
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '45%', lg: '60%'}} top={{base: '63%', lg: '30%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonDefault bgColor="#e3cdf74d" />
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '1%', lg: '5%'}} top={{base: '3%', lg: '3%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonContainer size={{base: '188px', lg: '321px'}}>
                  <UiTitle fontWeight="400">
                    Harbouring{' '}
                    <UiText as="span" color="orange.400">
                      great ideas
                    </UiText>{' '}
                    of amazing product?
                  </UiTitle>
                </BalloonContainer>
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '50%', lg: '65%'}} top={{base: '10%', lg: '3%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonContainer size={{base: '168px', lg: '225px'}}>
                  <UiTitle fontWeight="400">
                    Looking for a{' '}
                    <UiText as="span" color="orange.400">
                      dedicated
                    </UiText>{' '}
                    technical team?
                  </UiTitle>
                </BalloonContainer>
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '20%', lg: '37%'}} top={{base: '34%', lg: '12%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonContainer size={{base: '187px', lg: '294px'}}>
                  <UiTitle fontWeight="400">
                    Not yet knowing
                    <br />{' '}
                    <UiText as="span" color="orange.400">
                      how-to-start
                    </UiText>{' '}
                    &{' '}
                    <UiText color="orange.400" as="span">
                      where-to-start
                    </UiText>{' '}
                    ?
                  </UiTitle>
                </BalloonContainer>
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '51%', lg: '53%'}} top={{base: '56%', lg: '45%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonContainer size={{base: '167px', lg: '235px'}}>
                  <UiTitle fontWeight="400">
                    Yearning to retain{' '}
                    <UiText as="span" color="orange.400">
                      full control
                    </UiText>{' '}
                    over the product?
                  </UiTitle>
                </BalloonContainer>
              </AnimationOnScroll>
            </Balloon>
            <Balloon left={{base: '5%', lg: '23%'}} top={{base: '63%', lg: '40%'}}>
              <AnimationOnScroll animateIn="animate__slideInUp">
                <BalloonContainer size={{base: '167px', lg: '236px'}}>
                  <UiTitle fontWeight="400">
                    Desiring to launch your MVP{' '}
                    <UiText as="span" color="orange.400">
                      as soon as possible
                    </UiText>{' '}
                    ?
                  </UiTitle>
                </BalloonContainer>
              </AnimationOnScroll>
            </Balloon>
          </UiBox>
        </UiContainer>
      </UiBox>
    );
  }),
);

export default AreYou;
