import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {VisitorEntity} from '~/types/models/visitors';

class EntityConfig extends BaseEntityConfig<VisitorEntity> {
  entityKey = 'visitors';

  associates = [];

  whiteListFields: WhiteListFields<VisitorEntity> = ['*'];

  getAccessors = (value: VisitorEntity) => {};
}

const visitorEntConf = new EntityConfig();

export const visitorSchema = visitorEntConf.getEntitySchema();

export default visitorEntConf;

export const SOURCES_VISITORS = {
  VISITORS_PAGE: 'VISITORS_PAGE',
  VISITORS_DROPDOWN: 'VISITORS_DROPDOWN',
};
