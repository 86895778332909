import React, {useEffect, useState} from 'react';
import {Col, UiBox, UiContainer, UiGrid, UiImage, UiText} from '~/components/elements/index';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import '~/pages/home-page/style/EaseProject.css';
import '~/assets/css/main.css';
import {sleep} from '~/utils/helpers';

const Exam1 = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const [rerenderAt, forceRerender] = useState(Date.now());
  const [animation, setAnimation] = useState<boolean>(false);
  const colorText = useColorModeValue('black', 'white');

  useEffect(() => {
    const runAnimation = async () => {
      setAnimation(pre => !pre);
      await sleep(5000);
      forceRerender(Date.now());
    };

    runAnimation();
  }, [rerenderAt]);

  return (
    <Col pb="150px">
      <UiBox bgColor="#F1EEE4" pos="relative" w="100%" h={{base: 'auto', lg: '28.6em'}}>
        <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '100%', lg: '90%'}}>
          <UiGrid
            templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
            h="100%"
            m={{base: '20px 0px', lg: '0px'}}
          >
            <Col h="100%" w={{base: '75%'}} justifyContent="center" pl="10px" color={colorText}>
              <UiText fontSize={{base: 'md', lg: '3xl'}} mb="15px">
                Comprehensive Birth Control Contraception tracker
              </UiText>
              <UiText fontSize={{base: 'md', lg: '3xl'}}>
                Push notification system to notify users about status, reminders, news and more
              </UiText>
            </Col>
            <UiBox position="relative" h={{base: '9.375em', lg: '28.6em'}}>
              <UiImage
                filePath="story/reminder.png"
                pos="absolute"
                h={{base: '3.75em', lg: '8.25em'}}
                right={{base: '37.5%', lg: '55%'}}
                top="45%"
                zIndex="2"
              />
              <UiImage
                filePath="story/notification.png"
                pos="absolute"
                h={{base: '3em', lg: '7.375em'}}
                zIndex="3"
                top="35%"
                right={{base: '30%', lg: '50%'}}
              />
              <UiImage
                filePath="story/reminder-1.png"
                pos="absolute"
                h={{base: '3.125em', lg: '7.25em'}}
                right={{base: '30%', lg: '50%'}}
                top="60%"
                zIndex="3"
              />
              <UiImage
                filePath="story/ease-3.png"
                w={{base: '8.375em', lg: '25em'}}
                h={{base: '9.375em', lg: '28.6em'}}
                pos="absolute"
                right="0%"
                top="0"
                zIndex={{base: '5', lg: '1'}}
              />
            </UiBox>
          </UiGrid>
        </UiContainer>
      </UiBox>
      <Col mt={{base: '50px', lg: '150px'}}>
        <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '100%', lg: '70%'}}>
          <UiText textAlign="center" fontSize={{base: 'md', lg: '5xl'}} mb="100px" fontWeight="600">
            Hassle-free switching between Period and Contraception Trackers
          </UiText>
          <Col pos="relative" w={{base: 'auto', lg: '100%'}} alignItems="center">
            <UiBox pos="relative" w={{base: '15em', lg: '20.625em'}} h={{base: '30em', lg: '40.75em'}}>
              <UiImage filePath="story/ease-17.png" zIndex="5" pos="relative" h={{base: '30em', lg: '40.75em'}} />
              <UiImage
                filePath="story/ease-18.png"
                pos="absolute"
                h={{base: '10em', lg: '16.625em'}}
                top={{base: '40%', lg: '35%'}}
                transition="2s"
                zIndex="6"
                left={animation ? {base: '20%', lg: '15%'} : '-25%'}
                transform={animation ? 'scale(1)' : 'scale(0)'}
              />
              <UiImage
                filePath="story/ease-19.png"
                pos="absolute"
                h={{base: '10em', lg: '16.625em'}}
                top={{base: '40%', lg: '35%'}}
                zIndex="6"
                transition="2s"
                transform={!animation ? 'scale(1)' : 'scale(0)'}
                right={!animation ? {base: '20%', lg: '15%'} : '-25%'}
              />
              <UiImage
                filePath="story/ease-20.png"
                pos="absolute"
                h={{base: '4em', lg: '5em'}}
                top="5%"
                zIndex="6"
                right="-25%"
                transition="1s"
                opacity={animation ? '1' : '0.3'}
              />
              <UiImage
                filePath="story/ease-21.png"
                pos="absolute"
                h={{base: '4em', lg: '5em'}}
                top="17%"
                zIndex="6"
                right="-25%"
                transition="1s"
                opacity={!animation ? '1' : '0.3'}
              />
              <UiBox pos="absolute" top="-20px" left="-50px" transition="2s">
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <UiBox zIndex="2">
                    <svg
                      viewBox="0 0 98 104"
                      width={isDesktop ? '8em' : '6.25em'}
                      height={isDesktop ? '8em' : '6.25em'}
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M51.5 92.9785C77.1812 92.9785 98 72.1646 98 46.4893C98 20.814 77.1812 0 51.5 0C25.8188 0 5 20.814 5 46.4893C5 72.1646 25.8188 92.9785 51.5 92.9785Z"
                        fill="#456762"
                      ></path>
                    </svg>
                  </UiBox>
                  <UiBox pos="absolute" left="5px" top="80px" zIndex="1">
                    <svg
                      width={isDesktop ? '5.5em' : '3.875em'}
                      height={isDesktop ? '5.3125em' : '3.375em'}
                      viewBox="0 0 98 104"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M 10 10 H 90 V 90 H 10 L 10 10"
                        stroke="#FB8C00"
                        strokeWidth="2"
                      ></path>
                    </svg>
                  </UiBox>
                </AnimationOnScroll>
              </UiBox>
              <UiBox pos="absolute" bottom="0px" right="-50px">
                <AnimationOnScroll animateIn="animate__slideInLeft">
                  <svg
                    width={isDesktop ? '7.375em' : '6.25em'}
                    height={isDesktop ? '8.5em' : '6.25em'}
                    viewBox="0 0 78 64"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M39 8.99805L78 63.9853H0L39 8.99805Z" fill="#FDD466" />
                  </svg>
                </AnimationOnScroll>
                <AnimationOnScroll animateIn="animate__zoomIn">
                  <UiBox pos="absolute" bottom="20px" transition="2s">
                    <svg
                      width={isDesktop ? '7.375em' : '5.9375em'}
                      height={isDesktop ? '8.5em' : '5.9375em'}
                      viewBox="0 0 78 64"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        className="path"
                        d="M76.046 1L47 41.2803L17.954 1H76.046Z"
                        stroke="black"
                        strokeWidth="1"
                      ></path>
                    </svg>
                  </UiBox>
                </AnimationOnScroll>
              </UiBox>
            </UiBox>
          </Col>
        </UiContainer>
      </Col>
    </Col>
  );
};

export default Exam1;
