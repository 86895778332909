import React from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiGrid, UiImage, UiLink, UiText} from '~/components/elements';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {BoxProps, TextProps, useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {color} from '~/theme/color';
import {UiTitle} from '~/components/elements/UiTitle';
import {Link, useHistory} from 'react-router-dom';

const ContainerContent = (p: BoxProps) => <Col ml={{base: '0px', lg: '25px'}} mt={{base: '10px', lg: '0px'}} {...p} />;

const Header = (p: TextProps) => (
  <UiText fontSize={{base: 'md', lg: 'xl'}} lineHeight={{base: '16px', lg: '22px'}} fontWeight="bold" {...p} />
);

const Content = (p: TextProps) => (
  <UiText
    fontSize={{base: 'sm', lg: 'md'}}
    lineHeight={{base: '16px', lg: '18px'}}
    mb={{base: '10px', lg: '15px'}}
    mt={{base: '10px', lg: '15px'}}
    {...p}
  />
);

const BoxBadge = (p: BoxProps) => (
  <UiBox pos="absolute" w={{base: '10px', lg: '17px'}} h={{base: '10px', lg: '17px'}} borderRadius="50%" {...p} />
);

const BoxCircle = (p: BoxProps) => (
  <UiBox
    pos="relative"
    h={{base: '28px', lg: '50px'}}
    w={{base: '28px', lg: '50px'}}
    borderRadius="50%"
    p={{base: '0px', lg: '25px'}}
    {...p}
  />
);

const Services = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const history = useHistory();
    const colorButton = useColorModeValue('black', '#333237');

    const onClickContactUs = () => {
      history.push(PAGES.CONTACT_US);
    };

    const ServiceComponent = ({
      icon,
      header,
      content,
      link,
    }: {
      icon: React.ReactNode;
      header: string;
      content: string;
      link: string;
    }) => {
      return (
        <UiBox as={isDesktop ? Row : Col}>
          {isDesktop && icon}
          <ContainerContent>
            <Row alignItems="center">
              <Header>{header}</Header>
              {!isDesktop && <UiBox ml="10px">{icon}</UiBox>}
            </Row>
            <Content>{content}</Content>
            <UiLink textDecoration="underline" as={Link} to={link}>
              Let us help you
            </UiLink>
          </ContainerContent>
        </UiBox>
      );
    };

    return (
      <UiBox id="services" mb={{base: '80px', lg: '95px'}} ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiContainer h="100%" maxW="container.xl" w={{base: '90%', lg: '76%'}} pos="relative">
          {!isDesktop && (
            <UiBox position="absolute" top="-75px" right="30px">
              <UiImage filePath="home/diamond-4.png" h="5.25em" />
            </UiBox>
          )}

          <Col pos="relative">
            <UiTitle
              lineHeight={{base: '35px', lg: '80px'}}
              fontSize={{base: '4xl', lg: '8xl'}}
              mb={{base: '5px', lg: '10px'}}
            >
              Services
            </UiTitle>
            <UiText
              fontSize={{base: 'sm', lg: 'xl'}}
              color={color.home.textDescription}
              lineHeight={{base: '18px', lg: '30px'}}
              mt={{base: '27px', lg: '15px'}}
            >
              No matter what your stage is, we tailor the service that serves you the best
            </UiText>
            <UiGrid
              templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(2,1fr)'}}
              columnGap={50}
              rowGap={{base: 35, lg: '75px'}}
              pt={{base: '30px', lg: '60px'}}
            >
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="orange.400">
                    <BoxBadge bgColor="#95DCBC" bottom="0px" left="0px" />
                  </BoxCircle>
                }
                header="Build your more-than-an-MVP"
                content="Do you have an amazing idea for an app or software but don’t know where to start?"
                link={PAGES.START_UP}
              />
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="#E3CDF7">
                    <BoxBadge bgColor="#FDD466" top="0px" left="0px" />
                  </BoxCircle>
                }
                header="Build your idea from scratch"
                content="Realize your value ideas with a great custom-build application"
                link={PAGES.BUILD_IDEAS}
              />
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="#95DCBC">
                    <BoxBadge bgColor="#E3CDF7" top="0px" right="0px" />
                  </BoxCircle>
                }
                header="Expand your team"
                content="Boost your team with Rayyone's dedicated world-class talents"
                link={PAGES.EXPAND_TEAM}
              />
              <ServiceComponent
                icon={
                  <BoxCircle bgColor="#FDD466">
                    <BoxBadge bgColor="#95DCBC" bottom="0px" right="0px" />
                  </BoxCircle>
                }
                header="Manage an entire service"
                content="Optimize your team's time and quality with our management & technical support"
                link={PAGES.MANAGE_SERVICE}
              />
            </UiGrid>
            <Row justifyContent="center" mt={{base: '40px', lg: '77px'}}>
              <UiButton
                bgColor={colorButton}
                color="white"
                borderRadius="10px"
                m="0px 10px"
                textDecoration="none"
                fontSize={{base: 'md', lg: 'xl'}}
                lineHeight={{base: '16px', lg: '22px'}}
                fontFamily="Inter"
                onClick={onClickContactUs}
              >
                Hear more about us
              </UiButton>
            </Row>
          </Col>
        </UiContainer>
      </UiBox>
    );
  }),
);

export default Services;
