import React, {useRef} from 'react';
import {Row, UiBanner, UiBox, UiText} from '~/components/elements';
import MainService from '~/pages/services/components/MainService';
import {ContactUs} from '~/components/shared/ContactUs';
import ContactUsScrumTeam from '~/components/shared/ContactUsScrumTeam';
import ContactTime from '~/components/shared/ContactUsTime';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';
import WhyChooseUs from '~/pages/services/components/WhyChooseUs';
import EaseProject from '~/pages/home-page/components/EaseProject';
import TalkToUs from '~/pages/services/components/TalkToUs';

const ServicesPage = () => {
  const mainRef = useRef<HTMLDivElement>(null);
  const title = (
    <UiBox>
      <Row>
        <UiText as="span" color="orange.400">
          Our
        </UiText>
      </Row>
      <Row>
        <UiText as="span">Main Services</UiText>
      </Row>
    </UiBox>
  );
  const description =
    'We are a customer-centric company. No matter what your stage is, we tailor the service that serves you the best';

  useScrollToTop();

  useMetaTags(
    {
      title: `Services | Rayyone`,
      description: `Learn about a wide range of services provided by Rayyone: App Development, Web Development, MVP Building, Team Expand & Management, Outsourcing. Contact us now!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content:
            'software development,app development,website development,web development,application development,build mvp,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox>
      <UiBanner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
        title={title}
        description={description}
      />
      <MainService ref={mainRef} />
      <WhyChooseUs />
      <TalkToUs />
      <ContactUsScrumTeam />
      <ContactUs />
    </UiBox>
  );
};

export default ServicesPage;
