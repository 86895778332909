import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {MailUnsubscribeEntity} from '~/types/models/mail-unsubscribe';
import {RootState} from '~/redux/root-reducer';
import {
  addIdsToSources,
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  deleteSuccess,
  fetchSuccess,
  ReducerState,
  upsertMany,
  upsertManyMutably,
  upsertOneMutably,
} from 'redux-thunk-kit';
import {SOURCES_MAIL_UNSUBSCRIBE} from '~/redux/mail-unsubscribes/entity-config';
import {
  addMailUnsubscribe,
  deleteMailUnsubscribe,
  fetchMailUnsubscribes,
  updateMailUnsubscribe,
} from '~/redux/mail-unsubscribes/thunk';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_MAIL_UNSUBSCRIBE, () => DEFAULT_SOURCE_REDUCER_STATE);

const mailUnsubscribesAdapter = createEntityAdapter<MailUnsubscribeEntity>();

const initialState = mailUnsubscribesAdapter.getInitialState<InitialState>({sources});

const mailUnsubscribes = createSlice({
  name: 'mailUnsubscribes',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMailUnsubscribes.fulfilled, (state, {payload}) => {
      upsertManyMutably(mailUnsubscribesAdapter, state, payload.normalized.entities.mailUnsubscribes);
      fetchSuccess(state, payload);
    });
    builder.addCase(addMailUnsubscribe.fulfilled, (state, {payload}) => {
      upsertMany(mailUnsubscribesAdapter, state, payload.normalized.entities.mailUnsubscribes);
      addIdsToSources(state, payload.normalized.result, [SOURCES_MAIL_UNSUBSCRIBE.MAIL_UNSUBSCRIBES_PAGE]);
    });
    builder.addCase(updateMailUnsubscribe.fulfilled, (state, {payload}) => {
      upsertOneMutably(mailUnsubscribesAdapter, state, payload.normalized.entities.mailUnsubscribes);
    });
    builder.addCase(deleteMailUnsubscribe.fulfilled, (state, {payload, meta}) => {
      const {arg} = meta;
      mailUnsubscribesAdapter.removeOne(state, arg?.id as EntityId);
      deleteSuccess(state, arg?.id);
    });
  },
});

// export const {} = mailUnsubscribes.actions;

export default mailUnsubscribes.reducer;

export const {
  selectById: selectMailUnsubscribeById,
  selectIds: selectMailUnsubscribesIds,
  selectEntities: selectMailUnsubscribesEntities,
  selectAll: selectAllMailUnsubscribes,
  selectTotal: selectTotalMailUnsubscribes,
} = mailUnsubscribesAdapter.getSelectors((state: RootState) => state.mailUnsubscribes);

export const [getMailUnsubscribes] = createSelectAllBySource('mailUnsubscribes', selectMailUnsubscribesEntities, [
  SOURCES_MAIL_UNSUBSCRIBE.MAIL_UNSUBSCRIBES_PAGE,
]);
