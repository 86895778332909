import React from 'react';
import {PAGES} from '~/constants';
import {UiButton} from '~/components/elements';
import {useHistory} from 'react-router-dom';

const CaseStudyBtn = () => {
  const history = useHistory();

  return (
    <UiButton
      bgColor="black"
      color="white"
      borderRadius="10px"
      mt={{base: '35px', lg: '65px'}}
      fontSize={{base: 'md', lg: 'xl'}}
      lineHeight="22px"
      fontWeight="bold"
      onClick={() => history.push(PAGES.STORIES)}
    >
      Case Study
    </UiButton>
  );
};

export default CaseStudyBtn;
