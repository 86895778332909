import {ChakraProvider, ColorModeScript} from '@chakra-ui/react';
import React, {useEffect, useState} from 'react';
import {createRoot} from 'react-dom/client';
import ReactGA from 'react-ga';
import useMetaTags from 'react-metatags-hook';
import {Provider, useDispatch, useSelector} from 'react-redux';
import uuid from 'react-uuid';
import '~/assets/scss/main.scss';
import {AppRouter, UiPageLoading} from '~/components/elements';
import config from '~/config/app-config';
import {RootState} from '~/redux/root-reducer';
import rootStore, {AppDispatch} from '~/redux/root-store';
import reportWebVitals from '~/reportWebVitals';
import {privateRoutes, publicRoutes} from '~/routes';
import {ignoreLogs} from '~/services/ignore-logs';
import {initSentry} from '~/services/sentry';
import Fonts from '~/theme/chakra-font';
import {uiTheme} from '~/theme/chakra-theme';
import {setSessionIdUser} from './redux/tracker/slice';
import {onUserAccessMisc} from './redux/users/thunk';
import {getSessionIdUserFromStorage, saveSessionIdUser} from './services/auth';
import {setup as setupError} from 'ry-error-wrapper';

ignoreLogs();

initSentry();

setupError({initSentry, getUser: async () => ({})});

ReactGA.initialize('UA-209819890-1', {debug: false});

const Root = () => {
  return (
    <Provider store={rootStore}>
      <ChakraProvider theme={uiTheme}>
        <Fonts />
        <App />
      </ChakraProvider>
    </Provider>
  );
};

const App = () => {
  const dispatch: AppDispatch = useDispatch();
  const currentUser = useSelector((state: RootState) => state.auth.currentUser);

  const [isInitializing, setIsInitializing] = useState(true);

  useMetaTags(
    {
      openGraph: {
        image: `${config.url}/rayyone-1.png`,
      },
    },
    [],
  );

  useEffect(() => {
    const init = async () => {
      const {sessionId} = getSessionIdUserFromStorage();
      if (sessionId) {
        await saveSessionIdUser(sessionId);
        await dispatch(setSessionIdUser({sessionId: sessionId}));
      } else {
        await dispatch(onUserAccessMisc({sessionId: uuid()}));
      }

      setIsInitializing(false);
    };
    init();
  }, [dispatch]);

  if (isInitializing) {
    return <UiPageLoading />;
  }

  return (
    <AppRouter
      basename={config.routeBaseName}
      authorities={currentUser?.roles}
      privateRoutes={privateRoutes}
      publicRoutes={publicRoutes}
      defaultRedirect="/p/login"
    />
  );
};

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
  <React.StrictMode>
    <ColorModeScript initialColorMode={uiTheme.config.initialColorMode} />
    <Root />
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
