import {createEntityAdapter, createSlice, EntityId} from '@reduxjs/toolkit';
import {mapValues} from 'lodash';
import {RootState} from '~/redux/root-reducer';
import {
  createSelectAllBySource,
  DEFAULT_SOURCE_REDUCER_STATE,
  fetchSuccess,
  ReducerState,
  upsertManyMutably,
} from 'redux-thunk-kit';
import {SOURCES_POSITION} from '~/redux/positions/entity-config';
import {fetchPosition, fetchPositions} from '~/redux/positions/thunk';
import {PositionEntity} from '~/types/models/positions';

interface InitialState extends ReducerState {}

const sources = mapValues(SOURCES_POSITION, () => DEFAULT_SOURCE_REDUCER_STATE);

const positionsAdapter = createEntityAdapter<PositionEntity>();

const initialState = positionsAdapter.getInitialState<InitialState>({sources});

const positions = createSlice({
  name: 'positions',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchPositions.fulfilled, (state, {payload}) => {
      upsertManyMutably(positionsAdapter, state, payload.normalized.entities.positions);
      fetchSuccess(state, payload);
    });
    builder.addCase(fetchPosition.fulfilled, (state, {payload}) => {
      upsertManyMutably(positionsAdapter, state, payload.normalized.entities.positions);
      fetchSuccess(state, payload);
    });
  },
});

export default positions.reducer;

export const {
  selectById: selectPositionById,
  selectIds: selectPositionsIds,
  selectEntities: selectPositionsEntities,
  selectAll: selectAllPositions,
  selectTotal: selectTotalPositions,
} = positionsAdapter.getSelectors((state: RootState) => state.positions);

export const [getPositions] = createSelectAllBySource('positions', selectPositionsEntities, [
  SOURCES_POSITION.POSITIONS_PAGE,
]);
