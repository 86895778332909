import React from 'react';
import {ComponentProps} from '~/types/models/app';
import {UiButton, UiIcon} from '~/components/elements/index';
import {AiOutlineCloseCircle} from 'react-icons/ai';

interface Props {
  fileName: string;
  onChooseFile: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClearFile: () => void;
}

const ButtonUploadFile = (p: ComponentProps) => (
  <UiButton
    color="black"
    bgColor="white"
    mt="10px"
    p="30px 0px"
    boxShadow="0 0 #0000,0 0 #0000,0 2px 27px -10px rgba(0,0,0,.18)"
    pos="relative"
    {...p}
  />
);

const UploadFile = ({fileName, onChooseFile, handleClearFile}: Props) => {
  return (
    <ButtonUploadFile actionName="CV" w="100%">
      {fileName || 'Drop your file or upload'}
      <input
        type="file"
        accept="application/pdf, image/gif, image/jpeg"
        style={{opacity: 0, position: 'absolute', width: '100%', cursor: 'pointer'}}
        onChange={e => onChooseFile(e)}
      />
      {fileName && (
        <UiIcon
          as={AiOutlineCloseCircle}
          style={{color: 'black', position: 'absolute', right: '10%'}}
          onClick={handleClearFile}
        />
      )}
    </ButtonUploadFile>
  );
};

export default UploadFile;
