import React from 'react';
import {useHistory} from 'react-router-dom';
import {Col, Row, UiBox, UiButton, UiImage, UiLink, UiText} from '~/components/elements';
import {AppPostBadge} from '~/components/elements/index';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';

const data = [
  {
    title: 'Dedicated offshore team ',
    badges: ['Front End', 'Back End', 'PM', 'QC'],
    description:
      'We built and organized a standard self-managed Scrum team, under full control of clients.\n\nWe support not only products but people. We nurture self-management and personal growth of each member, invest in people and help them reach their full potential.',
    color: '#456762',
  },
  {
    title: 'Express features launched',
    badges: ['New feature', 'Migration', 'Expand'],
    description:
      'Understanding startup-spirit, we optimize the resources to reach the average features released cycle of less than 2 weeks.',
    color: '#456762',
  },
  {
    title: 'Scalability and Security',
    badges: ['Scalability', 'Security', 'Expand'],
    description: 'Scalability and security are of top important for Healthcare startups.',
    color: '#456762',
  },
];

const Reason = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const colorText = useColorModeValue('black', 'white');
  const history = useHistory();

  const onClickStories = () => {
    return history.push(PAGES.STORIES);
  };

  return (
    <UiBox mt="30px" pb={{base: '50px', lg: '150px'}} pos="relative">
      <Col px={{base: 5, lg: 28}}>
        <Col w={{base: '80%', lg: '60%'}}>
          {data.map((value, index) => {
            return <AppPostBadge content={value} key={index} />;
          })}
        </Col>
        <Row
          mt={{base: '0px', lg: '80px'}}
          alignItems="center"
          w={{base: '100%', lg: '80%'}}
          fontSize={{base: 'sm', lg: 'xl'}}
        >
          <UiButton
            border="3px solid #456762"
            borderRadius="10px"
            bgColor="transparent"
            p={{base: '10px 10px', lg: '18px 14px'}}
            color={colorText}
            mr="10px"
            fontWeight="bold"
            fontSize={{base: 'sm', lg: 'xl'}}
            onClick={onClickStories}
          >
            Let us share more!
          </UiButton>
          <UiText fontWeight="bold">
            or{' '}
            <UiLink href="https://ease-healthcare.com/ease-app/#" target="_blank">
              See it alive!
            </UiLink>
          </UiText>
        </Row>
      </Col>
      {isDesktop && <UiImage filePath="start-up/star-4.png" pos="absolute" bottom="0px" right="50px" h="11.25em" />}
    </UiBox>
  );
};

export default Reason;
