import React from 'react';
import {UiBox} from '~/components/elements';
import Banner from '~/pages/rayyone-tools/components/Banner';
import WhatYouNeed from '~/pages/rayyone-tools/components/WhatYouNeed';
import Advantage from '~/pages/rayyone-tools/components/Advantages';
import GetStarted from '~/pages/rayyone-tools/components/GetStarted';
import ContactTime from '~/components/shared/ContactUsTime';
import {ContactUs} from '~/components/shared/ContactUs';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const RayyoneTools = () => {
  useScrollToTop();

  useMetaTags(
    {
      title: `Rayyone Tools`,
      description: `A quick overview of the Rayyone Tools`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content:
            'rayyone,rayyone tools,software development,app development,admin portal development,golang tool,react tool,infrastructure',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox>
      <Banner />
      <WhatYouNeed />
      <Advantage />
      <GetStarted />
      <ContactTime />
      <ContactUs />
    </UiBox>
  );
};

export default RayyoneTools;
