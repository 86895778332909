import React, {useRef} from 'react';
import {UiBox} from '~/components/elements/index';
import Banner from '~/pages/home-page/components/Banner';
import Services from '~/pages/home-page/components/Services';
import Exam from '~/pages/home-page/components/Exam';
import EaseProject from '~/pages/home-page/components/EaseProject';
import TechStack from '~/pages/home-page/components/TechStack';
import Industries from '~/pages/home-page/components/Industries';
import HowDoWeStart from '~/pages/home-page/components/HowDoWeStart';
import {ContactUs} from '~/components/shared/ContactUs';
import ContactUsScrumTeam from '~/components/shared/ContactUsScrumTeam';
import HowWeMakeIt from '~/pages/coupon/components/HowWeMakeIt';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const HomePage = () => {
  useScrollToTop();

  useMetaTags(
    {
      title: `Software Development & Management Solutions | Rayyone`,
      description: `Rayyone is a full-stack software development company with the proven history of consistently provide express launched and high quality products. Learn more!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content:
            'software development,app development,website development,web development,application development,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  return (
    <UiBox fontFamily="Inter">
      <Banner />
      <Services />
      <Exam />
      <EaseProject />
      <UiBox mb={{base: '50px', lg: '100px'}}>
        <HowWeMakeIt />
      </UiBox>
      <ContactUsScrumTeam />
      <TechStack />
      <Industries />
      <HowDoWeStart />
      <ContactUs />
    </UiBox>
  );
};

export default HomePage;
