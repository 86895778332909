import {combineReducers} from '@reduxjs/toolkit';
import ui from '~/redux/ui/slice';
import auth from '~/redux/auth/slice';
import apps from '~/redux/apps/slice';
import users from '~/redux/users/slice';
import visitors from '~/redux/visitors/slice';
import trackers from '~/redux/tracker/slice';
import coupons from '~/redux/coupons/slice';
import levels from '~/redux/levels/slice';
import positions from '~/redux/positions/slice';
import jobs from '~/redux/jobs/slice';
import mailUnsubscribes from '~/redux/mail-unsubscribes/slice';

const appReducer = combineReducers({
  ui,
  apps,
  auth,
  users,
  visitors,
  trackers,
  coupons,
  levels,
  positions,
  jobs,
  mailUnsubscribes,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
