// Let's get things done quickly by this hacky way first
const stage = process.env.REACT_APP_ENV || 'development';

let environment: {
  STAGE: string;
  SENTRY_DSN: string;
  SENTRY_ENABLED: boolean;
  API: {
    TIMEOUT: number;
    HOST: string;
  };
  GOOGLE_CLIENT_ID: string;
  URL: string;
};
switch (stage) {
  case 'production':
    environment = {
      STAGE: stage,
      SENTRY_DSN: 'https://0f13a52e1f7b402d85e1df4efcd0435c@o982986.ingest.sentry.io/4503908698750976',
      API: {
        TIMEOUT: 60000,
        HOST: 'https://api.rayyone.com',
      },
      SENTRY_ENABLED: true,
      GOOGLE_CLIENT_ID: '946453602238-efa0vlbrpvmc96bk1sh2n3gskjl47c7r.apps.googleusercontent.com',
      URL: 'https://rayyone.com',
    };
    break;

  case 'staging':
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      API: {
        TIMEOUT: 60000,
        HOST: 'https://api-stg.rayyone.com',
      },
      SENTRY_ENABLED: true,
      GOOGLE_CLIENT_ID: '946453602238-efa0vlbrpvmc96bk1sh2n3gskjl47c7r.apps.googleusercontent.com',
      URL: 'https://stg.rayyone.com',
    };
    break;

  case 'test':
  case 'development':
  default:
    environment = {
      STAGE: stage,
      SENTRY_DSN: '',
      API: {
        TIMEOUT: 60000,
        HOST: 'https://api-stg.rayyone.com',
      },
      SENTRY_ENABLED: false,
      GOOGLE_CLIENT_ID: '946453602238-efa0vlbrpvmc96bk1sh2n3gskjl47c7r.apps.googleusercontent.com',
      URL: 'https://rayyone.com',
    };
    break;
}

export default environment;
