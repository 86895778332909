import {ListItem, TextProps, UnorderedList, useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import roynguyen from '~/assets/img/scrum-team/phuoc.png';
import {Col, Row, UiBox, UiContainer, UiImage, UiSimpleGrid, UiText, UiTitle} from '~/components/elements';
import CaseStudyBtn from '~/components/shared/CaseStudyBtn';
import WhyChooseUsCollapsible from '~/components/shared/WhyChooseUsCollapsible';
import EaseMobileAnimation from '~/components/shared/EaseMobileAnimation';
import RenderUser from '~/components/shared/RenderUser';
import {QUERY_MOBILE} from '~/constants';
import {WHY_CHOOSE_US} from '~/constants/service';
import ScrumTeam from '~/pages/services/components/ScrumTeam';
import {color} from '~/theme/color';
import React from 'react';

const Title = (p: TextProps) => (
  <UiTitle fontSize={{base: '3xl', lg: '5xl'}} lineHeight={{base: '25px', lg: '50px'}} mb="25px" {...p} />
);

const Description = (p: TextProps) => (
  <UiText
    color={color.home.textDescription}
    fontSize={{base: 'sm', lg: 'md'}}
    lineHeight={{base: '18px', lg: '25px'}}
    mb="25px"
    {...p}
  />
);

const Header = (p: TextProps) => (
  <UiTitle
    color="orange.400"
    fontSize={{base: 'lg', lg: '2xl'}}
    my={{base: '25px', lg: '15px'}}
    lineHeight="22px"
    {...p}
  />
);

const Content = (p: TextProps) => (
  <UiText my={{base: '6px', lg: '8px'}} fontSize={{base: 'sm', lg: 'md'}} lineHeight="30px" {...p} />
);

const WhyChooseUs = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const colorText = useColorModeValue('easeMobile.800', '#95DCBC');

  return (
    <UiBox>
      <UiContainer h="100%" maxW="container.xl" pt="40px" pb="40px" pos="relative" w={{base: '89%', lg: '76%'}}>
        <UiText
          pos="absolute"
          w={{base: '33px', lg: '66px'}}
          h={{base: '50px', lg: '102px'}}
          fontWeight="500"
          top={{base: '-10px', lg: '-30px'}}
          left={{base: '-40px', lg: '-65px'}}
          fontSize={{base: '100px', lg: '150px'}}
          fontStyle="italic"
          color="orange.400"
        >
          &quot;
        </UiText>
        <UiBox>
          <Row mt={{base: '10px', lg: '10px'}}>
            <UiText fontSize={{base: 'sm', lg: 'lg'}} lineHeight={{base: '22px', lg: '27px'}}>
              It is my strong belief, and the central concept driving Rayyone, that behind every great tech product
              there must be a dedicated, whole-hearted team behind the scenes, working tirelessly to meet the need and
              fill the soul of the business. But few agencies can reach that point, as they might not feel and get into
              the startup&apos;s shoes. While building and managing an in-house team is a bit over the odds for
              startups.
              <br />
              <br />
              That is how Rayyone was born -- to pioneer in solving the problem.
              <UiText as="span" fontStyle="italic">
                &quot;
              </UiText>
            </UiText>
          </Row>
          <Row justifyContent="right">
            <RenderUser avatar={roynguyen} name="Roy Nguyen" jobTitle="CTO, Rayyone" color="#FB8C00" />
          </Row>
        </UiBox>
      </UiContainer>
      <UiContainer
        h="100%"
        maxW="container.xl"
        mt={{base: '1em', lg: '5em'}}
        pt="40px"
        pb="40px"
        w={{base: '89%', lg: '76%'}}
      >
        <UiTitle
          lineHeight={{base: '35px', lg: '80px'}}
          fontSize={{base: '4xl', lg: '8xl'}}
          mb={{base: '5px', lg: '0px'}}
          color="orange.400"
        >
          Why Choose Us
        </UiTitle>
        <UiSimpleGrid columns={{base: 1, lg: 2}} h="100%" py={{base: '2rem', lg: '4rem'}}>
          <Col mb={{base: '50px', lg: '0'}}>
            {WHY_CHOOSE_US.map(item => (
              <WhyChooseUsCollapsible key={item.id} id={item.id} title={item.title} items={item.items} />
            ))}
          </Col>
          <Col>
            <ScrumTeam />
          </Col>
        </UiSimpleGrid>
      </UiContainer>
      <UiContainer h="100%" maxW="container.xl" pt="40px" pos="relative" w={{base: '85%', lg: '76%'}}>
        <UiTitle fontSize={{base: 'xl', lg: '6xl'}} lineHeight={{base: '22px', lg: '60px'}} color="#2C4142">
          We take pride in every product we built, as we built it with heart and soul
        </UiTitle>
        <UiBox pos="relative" minH={{base: '35px', lg: '50px'}}>
          <UiImage
            filePath="home/arrow.png"
            h={{base: '4.375em', lg: '9.6875em'}}
            pos="absolute"
            right="33%"
            top={{base: '0px', lg: '-20px'}}
          />
        </UiBox>
        <Row alignItems="center" mb={{base: '50px', lg: '100px'}}>
          <UiBox mr={{base: '0px', lg: '100px'}}>{isDesktop && <EaseMobileAnimation />}</UiBox>
          <Col>
            <Title color={colorText} mt="3em">
              EASE HEALTHCARE
            </Title>
            <Description>
              {!isDesktop && 'The most in-depth free contraception, period tracker & women’s health app'}
              {isDesktop &&
                'Explore the most in-depth free contraception, period trackers & women’s health app with period tracking, contraception alerts, wellness tips, virtual medical care & community resources.'}
            </Description>
            {!isDesktop && <EaseMobileAnimation />}
            <Header>Rayyone helps to:</Header>
            <Content>
              Build the products from scratch with micro-services architectures. Build offshore team and manage an
              entire technical system.
            </Content>
            <Header>Rayyone helps to:</Header>
            <Content>Backend: NodeJs - ExpressJS, PHP - Laravel, Golang, Pusher</Content>
            <Content>Frontend: Typescript - React Native, React JS</Content>
            <Content>DevOps:</Content>
            <UnorderedList fontSize={{base: 'sm', lg: 'md'}}>
              <ListItem>
                Amazon Web Service (AWS): ECS, Cluster & Application Auto Scaling, CloudFront, CloudFormation, RDS
                Postgres, ElastiCache, Secret Manager, WAF, CloudWatch, Elastic Search, SNS, ACM, S3, Serverless,...
              </ListItem>
              <ListItem>Others: docker, atop, logrotate, audit,...</ListItem>
            </UnorderedList>
            <Row mb={{base: '10px', lg: '0px'}} justifyContent="left">
              <CaseStudyBtn />
            </Row>
          </Col>
        </Row>
      </UiContainer>
    </UiBox>
  );
};

export default WhyChooseUs;
