import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiContainer,
  UiIcon,
  UiLink,
  UiSimpleGrid,
  UiSpacer,
  UiText,
  UiTitle,
} from '~/components/elements';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {useHistory} from 'react-router-dom';
import {CgArrowLongLeft, CgArrowLongRight} from 'react-icons/cg';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {Flex, useMediaQuery} from '@chakra-ui/react';

const ContentItem = ({title, extra}: any) => (
  <UiBox>
    <UiTitle fontWeight="bold" fontSize="2xl" mb={2}>
      <UiText as="span" color="orange.500">
        _{' '}
      </UiText>
      {title}
    </UiTitle>
    <UiText ml={5} fontWeight="bold" fontSize="md" color="gray">
      {extra}
    </UiText>
  </UiBox>
);

export const HowWeHelp = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const history = useHistory();

    return (
      <UiBox ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiContainer maxW="container.xl">
          <Col mb="4rem">
            <UiBox px={{base: 5, lg: 100}}>
              <UiTitle fontSize="6xl" mb={{base: 4, lg: 16}} mt={18}>
                How we help{' '}
                <UiText as="span" color="orange.500">
                  :
                </UiText>
              </UiTitle>
              <UiSimpleGrid spacingY={{base: 8, lg: 20}}>
                <UiSimpleGrid columns={{base: 1, lg: 2}} spacingX={180} spacingY={{base: 5, lg: 20}}>
                  <ContentItem
                    title="MVP development"
                    extra="to validate your idea without establishing a permanent own engineering team"
                  />
                  <ContentItem
                    title="QA & Software Testing,"
                    extra="to deliver a better product and shorten your time-to-market"
                  />
                </UiSimpleGrid>
                <UiSimpleGrid columns={{base: 1, lg: 2}} spacingX={180} spacingY={{base: 5, lg: 20}}>
                  <ContentItem
                    title="End-to-end solution development"
                    extra="from security architecture to embedded software development and custom cloud solutions"
                  />
                  <ContentItem
                    title="Innovative services"
                    extra="to support your embryonic and cutting-edge technology projects"
                  />
                </UiSimpleGrid>
              </UiSimpleGrid>
            </UiBox>
            <Flex direction={isDesktop ? 'row' : 'column'} mt={{base: '5rem', lg: '10rem'}}>
              <Row align="center" onClick={() => history.push(PAGES.EXPAND_TEAM)}>
                {isDesktop && <UiIcon as={CgArrowLongLeft} boxSize="40px" />}
                <UiLink fontSize="xl">Expand your team</UiLink>
                {!isDesktop && (
                  <>
                    <UiSpacer />
                    <UiIcon as={CgArrowLongRight} boxSize="40px" />
                  </>
                )}
              </Row>
              <UiSpacer />
              <Row align="center" onClick={() => history.push(PAGES.MANAGE_SERVICE)}>
                <UiLink fontSize="xl">Manage an entire service</UiLink>
                {!isDesktop && <UiSpacer />}

                <UiIcon as={CgArrowLongRight} boxSize="40px" />
              </Row>
            </Flex>
          </Col>
        </UiContainer>
      </UiBox>
    );
  }),
);
