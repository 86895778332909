const RAYYONE_DOCS_HOST = 'https://docs.rayyone.com';

export const RAYYONE_DOC_URLS = {
  HOME: `${RAYYONE_DOCS_HOST}/docs/intro`,
  GOLANG: `${RAYYONE_DOCS_HOST}/docs/ry-go/introduction`,
  // MOBILE_APP: `${RAYYONE_DOCS_HOST}/docs/category/ry-mobile-app`,
  ADMIN_PORTAL: `${RAYYONE_DOCS_HOST}/docs/ry-react-js/admin-portal/setup`,
  REACT_JS: `${RAYYONE_DOCS_HOST}/docs/category/ry-react-js`,
  REDUX_THUNK_KIT: `${RAYYONE_DOCS_HOST}/docs/ry-redux-thunkit/introduction`,
  VIDEO_TUTORIALS: `${RAYYONE_DOCS_HOST}/docs/category/video-tutorials`,
  GO_TUTORIALS: `${RAYYONE_DOCS_HOST}/docs/tutorials/golang`,
  ADMIN_TUTORIALS: `${RAYYONE_DOCS_HOST}/docs/tutorials/admin-page`,
  AWS: `${RAYYONE_DOCS_HOST}/docs/ry-infrastructure/introduction`,
};

export const DOCS_MENU = [
  {key: 1, title: 'Introduction', url: RAYYONE_DOC_URLS.HOME},
  {key: 2, title: 'RY Golang', url: RAYYONE_DOC_URLS.GOLANG},
  {key: 3, title: 'RY Infrastructure', url: RAYYONE_DOC_URLS.AWS},
  {key: 4, title: 'RY React JS', url: RAYYONE_DOC_URLS.REACT_JS},
  {key: 5, title: 'RY Redux Thunk Kit', url: RAYYONE_DOC_URLS.REDUX_THUNK_KIT},
  // {key: 6, title: 'RY Mobile App', url: RAYYONE_DOC_URLS.MOBILE_APP},
  {key: 7, title: 'Video Tutorials', url: RAYYONE_DOC_URLS.VIDEO_TUTORIALS},
];

export const TECH_LIST = [
  {
    key: 1,
    image: 'ry-tool/golang.png',
    title: 'Golang',
    description: `Develop Golang API resource within<strong> 5 minutes</strong> `,
    url: RAYYONE_DOC_URLS.GO_TUTORIALS,
  },
  {
    key: 2,
    image: 'ry-tool/admin-portal.png',
    title: ' Admin Portal',
    description: 'Develop an Admin page to manage your application within<strong> 5 minutes</strong>',
    url: RAYYONE_DOC_URLS.ADMIN_TUTORIALS,
  },
  {
    key: 3,
    image: 'ry-tool/mobile.png',
    title: 'Redux Thunk-kit',
    description: 'Boost your React app performance with RY Redux Thunk-kit',
    url: RAYYONE_DOC_URLS.REDUX_THUNK_KIT,
  },
  {
    key: 4,
    image: 'ry-tool/aws.png',
    title: 'Infrastructure',
    description:
      'Deploy your App to a powerful & highly scalable infrastructure in just <strong> 01 command line</strong>',
    url: RAYYONE_DOC_URLS.AWS,
  },
];

export const ADVANTAGES = [
  {
    key: 1,
    title: 'API Resources Generation',
    description:
      'Create Restful API resources <span style="color:orange">within 10 minutes</span> using Rayyone’s CLI tools',
    image: 'ry-tool/ry-tools-2.png',
  },
  {
    key: 2,
    title: 'Auto-Deploy',
    description: 'Ease the build and deployment process with our <span style="color:orange">CI/CD tools</span>',
    image: 'ry-tool/ry-tools-3.png',
  },
  {
    key: 3,
    title: 'Infrastructure',
    description:
      'Quickly set up powerful and highly scalable infrastructures for your services in <span style="color:orange">01 command</span>',
    image: 'ry-tool/ry-tools-4.png',
  },
  {
    key: 4,
    title: 'System Monitoring',
    description:
      'Monitor and track your services via a custom-made admin portal built in <span style="color:orange"> 10 minutes</span>',
    image: 'ry-tool/ry-tools-5.png',
  },
];

export const ADVANTAGES_USING_TOOLS = [
  {
    key: 1,
    title: 'Easy to Use',
    description:
      'Rayyone tools were designed from the ground up to be <strong>easily installed</strong> and used to get your application up and running <strong>quickly</strong>.',
  },
  {
    key: 2,
    title: 'Powered by Golang, React and AWS',
    description:
      'Rayyone uses the best and most modern technologies to build <strong>high quality</strong> but <strong>saving cost and time</strong> applications. Check out our tool&apos;s documentation.',
  },
  {
    key: 3,
    title: 'Focus on What Matters',
    description:
      'Rayyone tools <strong>let you focus on your business</strong>. Go ahead and start building your idea with us now.',
  },
];

export const RY_TOOLS = [
  {key: 1, title: 'RY Golang', description: 'Explore our RY Golang tool', url: RAYYONE_DOC_URLS.GOLANG},
  {
    key: 2,
    title: 'RY Infrastructure',
    description: 'View our optimized infrastructure solutions',
    url: RAYYONE_DOC_URLS.AWS,
  },
  {
    key: 3,
    title: 'RY Redux Thunk Kit',
    description: 'Learn how RY Redux Thunk Kit help',
    url: RAYYONE_DOC_URLS.REDUX_THUNK_KIT,
  },
  {key: 4, title: 'RY ReactJS', description: 'Read our RY ReactJS documents', url: RAYYONE_DOC_URLS.REACT_JS},
];
