import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiTitle} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Banner = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox mt={{base: '0px', lg: '50px'}} pb="100px">
      <UiContainer h="100%" maxW="container.xl" w={{base: '100%', lg: '95%'}} pos="relative">
        <Col bgColor="#456762" alignItems="center" borderRadius="10px">
          <UiImage
            filePath="start-up/ease.png"
            w={{base: '5.875em', lg: '10.5625em'}}
            h={{base: '1.5em', lg: '2.5625em'}}
            mt="40px"
            mb={{base: '30px', lg: '50px'}}
          />
          <UiTitle
            fontSize={{base: 'md', lg: '3xl'}}
            fontWeight="400"
            color="white"
            textAlign="center"
            w={{base: '80%', lg: '70%'}}
            lineHeight={{base: '25px', lg: '35px'}}
          >
            Empowering Ease to achieve their missions and business goals with speedy features launched and
            {!isDesktop && <br />} one-stop technical management package
          </UiTitle>
          <UiImage
            filePath="start-up/status-ring-1.png"
            w={{base: '4.875em', lg: '9.5em'}}
            h={{base: '5.125em', lg: '9.125em'}}
            pos="absolute"
            left="-2%"
            top={{base: '50%', lg: '40%'}}
            transform="rotate(-9.84deg)"
          />
          <Row h="4em" mt={{lg: '50px'}} w={{base: '90%', lg: '80%'}} justifyContent="space-evenly" alignItems="center">
            <UiImage filePath="story/aws.png" h={{base: '1.5em', lg: '2.5em'}} />
            <UiImage filePath="story/react.png" h={{base: '1.5em', lg: '2.5em'}} />
            <UiImage filePath="story/go.png" h={{base: '1.5em', lg: '1.75em '}} />
            <UiImage filePath="story/js.png" h={{base: '1.5em', lg: '2.5em'}} />
            <UiImage filePath="story/php.png" h={{base: '1em', lg: '2em'}} />
          </Row>
          <UiBox w={{base: '18.75em', lg: '35em'}} h={{base: '15.625em', lg: '30em'}} pos="relative">
            <UiImage
              filePath="story/ease-1.png"
              w={{base: '18.75em', lg: '35em'}}
              h={{base: '15.625em', lg: '30em'}}
              pos="absolute"
              bottom={{base: '-50px', lg: '-130px'}}
              left="0px"
            />
            <UiImage
              filePath="story/ease-2.png"
              w={{base: '6.125em', lg: '12.7em'}}
              h={{base: '2.25em', lg: '4.875em'}}
              pos="absolute"
              bottom={{base: '0px', lg: '-50px'}}
              right={{base: '-10%', lg: '-100px'}}
            />
          </UiBox>
        </Col>
      </UiContainer>
    </UiBox>
  );
};

export default Banner;
