import * as storage from '~/utils/storage';

interface UserCredential {
  user?: any;
  accessToken?: string | null;
}

interface GoogleUserInfo {
  accessToken?: string;
  user?: any;
}

export const authWithGoogle = async () => {
  // try {
  //   const resp = await request.get(API_URL.GET_GOOGLE_AUTH_URL)
  //   const googleAuthURL = resp.data.data.url
  //   window.location = googleAuthURL
  // } catch (e) {
  //   new AppError(e, 'Cannot login using google. Please try again')
  // }
};

export const removeSavedUserInfo = () => {
  storage.remove('RAYYONE_WEB_ACCESS_TOKEN');
  storage.remove('RAYYONE_WEB_USER');
};

export const saveUserCredential = async ({accessToken, user}: UserCredential) => {
  if (accessToken) {
    await storage.save('RAYYONE_WEB_ACCESS_TOKEN', accessToken);
  }
  if (user) {
    await storage.save('RAYYONE_WEB_USER', user);
  }
};

export const saveSessionIdUser = async (sessionId: string) => {
  if (sessionId) {
    await storage.save('RAYYONE_SESSION_ID_USER', sessionId);
    await storage.save('RAYYONE_SESSION_ID_USER', sessionId, 'cookie');
  }
};

export const getCurrentUserFromStorage = (): UserCredential => {
  const user = storage.load('RAYYONE_WEB_USER');
  const accessToken = storage.load('RAYYONE_WEB_ACCESS_TOKEN');

  return {
    accessToken,
    user,
  };
};

export const getSessionIdUserFromStorage = () => {
  let sessionId = storage.load('RAYYONE_SESSION_ID_USER');
  if (!sessionId) {
    sessionId = storage.load('RAYYONE_SESSION_ID_USER', 'cookie');
  }

  return {sessionId};
};
