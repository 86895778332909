import React from 'react';
import {UiBox} from '~/components/elements';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import {PAGES} from '~/constants';
import EaseAppBanner from '~/pages/story/components/EaseAppBanner';
import {AppDispatch} from '~/redux/root-store';
import {setShowModalEaseApp} from '~/redux/ui/slice';

export const Stories = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const history = useHistory();
    const dispatch: AppDispatch = useDispatch();

    const handleClick = () => {
      dispatch(setShowModalEaseApp({value: true}));
      return history.push(PAGES.STORIES);
    };

    return (
      <UiBox onClick={() => handleClick()}>
        <EaseAppBanner />
      </UiBox>
    );
  }),
);
