export const PRODUCT_DOMAINS = [
  'Healthcare',
  'eCommerce',
  'Fintech',
  'Food & Beverage',
  'Training & Education',
  'Beauty & Spa',
  'Gaming',
  'Entertainment',
  'Other',
];

export const PRODUCT_TYPES = ['Website', 'Application', 'Website & Application'];

export const CALENDLY_LINK = 'https://calendly.com/roy-nguyen/30min';

export const COUPON = {FORM: 'couponForm', HOW_WE_WORK: 'couponWorks'};
