import React from 'react';
import {
  Col,
  Row,
  UiBox,
  UiCenter,
  UiContainer,
  UiIcon,
  UiLink,
  UiSimpleGrid,
  UiSpacer,
  UiText,
  UiTitle,
} from '~/components/elements';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {CgArrowLongLeft, CgArrowLongRight} from 'react-icons/cg';
import {useHistory} from 'react-router-dom';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {Flex, useMediaQuery} from '@chakra-ui/react';

const data = [
  {title: 'Build a Scrum team'},
  {title: 'Construct working environment'},
  {title: 'Provide Management Solution'},
  {title: 'Develop and nurture talents'},
  {title: 'Provide Technical consultancy'},
];
const minWidth = '120px';

export const HowWeHelp = React.memo(
  React.forwardRef<HTMLDivElement>((props, ref) => {
    const menuHeight = useSelector((state: RootState) => state.ui.menu.height);
    const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
    const history = useHistory();

    return (
      <UiBox ref={ref} sx={{scrollMarginTop: `${menuHeight}px`}}>
        <UiContainer maxW="container.xl">
          <Col mb="50px">
            <UiBox>
              <UiTitle fontWeight="500" fontSize="6xl" mb={-1} mt={18}>
                How we help{' '}
                <UiText as="span" color="orange.500">
                  :
                </UiText>
              </UiTitle>

              {isDesktop && (
                <UiSimpleGrid columns={5} spacing={0} mt={16}>
                  {data.map((i, idx) => (
                    <Col key={idx} align="center">
                      <UiCenter h="1px" w="100%" bg="gray" position="relative">
                        <UiBox borderRadius={200} w="10" h="10" bg="orange.400" />
                      </UiCenter>
                      <UiText textAlign="center" mt={25} maxW="120px" fontWeight="bold">
                        {i.title}
                      </UiText>
                    </Col>
                  ))}
                </UiSimpleGrid>
              )}
              {!isDesktop && (
                <UiSimpleGrid
                  templateColumns={`repeat(auto-fill, minmax(${minWidth}, 1fr))`}
                  gridAutoFlow="column"
                  gridAutoColumns={`minmax(${minWidth}, 1fr)`}
                  overflowX="auto"
                  overflowY="hidden"
                  spacingX={0}
                  pb={3}
                  mt={16}
                >
                  {data.map((i, idx) => (
                    <Col key={idx} align="center" pt={5}>
                      <UiCenter h="1px" w="100%" bg="gray" position="relative">
                        <UiBox borderRadius={200} w="10" h="10" bg="orange.400" />
                      </UiCenter>
                      <UiText textAlign="center" mt={25} maxW="120px" fontWeight="bold">
                        {i.title}
                      </UiText>
                    </Col>
                  ))}
                </UiSimpleGrid>
              )}
              <UiText fontSize="xl" mt={{base: 10, lg: 20}}>
                After{' '}
                <UiText as="span" color="orange.400">
                  01 year
                </UiText>{' '}
                of the contract, optional recruitment is granted
              </UiText>
            </UiBox>
            <Flex direction={isDesktop ? 'row' : 'column'} mt={{base: '5rem', lg: '10rem'}}>
              <Row align="center" onClick={() => history.push(PAGES.BUILD_IDEAS)}>
                {isDesktop && <UiIcon as={CgArrowLongLeft} boxSize="40px" />}
                <UiLink fontSize="xl">Build your idea from scratch</UiLink>
                {!isDesktop && (
                  <>
                    <UiSpacer />
                    <UiIcon as={CgArrowLongRight} boxSize="40px" />
                  </>
                )}
              </Row>
              <UiSpacer />
              <Row align="center" onClick={() => history.push(PAGES.EXPAND_TEAM)}>
                <UiLink fontSize="xl">Expand your team</UiLink>
                {!isDesktop && <UiSpacer />}
                <UiIcon as={CgArrowLongRight} boxSize="40px" />
              </Row>
            </Flex>
          </Col>
        </UiContainer>
      </UiBox>
    );
  }),
);
