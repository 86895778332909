import React, {useEffect, useRef, useState} from 'react';
import Banner from '~/pages/job-detail/components/Banner';
import Content from '~/pages/job-detail/components/Content';
import LatestVacancies from '~/pages/job-detail/components/LatestVacancies';
import Form from '~/pages/job-detail/components/Form';
import {Row, UiBox, UiIcon} from '~/components/elements';
import {RiArrowDownSLine} from 'react-icons/ri';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch} from '~/redux/root-store';
import {getObjNthItem} from '~/utils/helpers';
import {fetchJob} from '~/redux/jobs/thunk';
import {SOURCES_JOB} from '~/redux/jobs/entity-config';
import {useParams} from 'react-router-dom';
import {selectJobById} from '~/redux/jobs/slice';
import {RootState} from '~/redux/root-reducer';
import '~/assets/css/main.css';
import {JobParams} from '~/types/models/jobs';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const JobDetailPage = () => {
  const dispatch: AppDispatch = useDispatch();
  const mainRef = useRef<HTMLDivElement>(null);
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const [isOpenButton, setIsOpenButton] = useState<boolean>(false);
  const params = useParams<JobParams>();
  const {id} = params;
  const job = useSelector((state: RootState) => selectJobById(state, id));

  useScrollToTop();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }, 200);
    const fetchData = async () => {
      await dispatch(fetchJob({source: SOURCES_JOB.JOB_PAGE, id: id}));
    };
    fetchData();
  }, [dispatch, id]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, {passive: true});
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  });

  const handleScroll = () => {
    const shouldShowButton =
      window.pageYOffset < contentRef?.current?.offsetTop! ||
      window.pageYOffset > contentRef?.current?.offsetHeight! * 0.9;
    setIsOpenButton(!shouldShowButton);
  };

  const onClickStart = () => {
    setIsOpenModal(true);
    document.body.style.overflow = 'hidden';
  };

  const onClickCloseModal = () => {
    setIsOpenModal(false);
    document.body.style.overflow = 'auto';
  };

  return (
    <UiBox>
      <Banner
        job={job}
        onClickStart={onClickStart}
        onClickDownArrow={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
      />
      <UiBox ref={contentRef}>
        {isDesktop && (
          <Row
            justifyContent="center"
            pos="sticky"
            top="80%"
            zIndex="1000"
            className={
              isOpenButton
                ? 'animate__animated animate__slideInUp animate__duration-1s'
                : 'animate__animated animate__slideOutDown animate__duration-0.5s'
            }
          >
            <UiBox
              alignItems="center"
              justifyContent="center"
              borderRadius="30px"
              cursor="pointer"
              p="16px 28px"
              fontSize="md"
              minWidth="220px"
              mt="50px"
              bgColor="orange.400"
              color="white"
              onClick={onClickStart}
            >
              Start your journey here!
            </UiBox>
          </Row>
        )}
        <UiBox
          zIndex="10000"
          pos="fixed"
          bgColor="rgba(49,49,49,0.8)"
          w="100vw"
          height="100vh"
          overflowY="auto"
          pb="100px"
          top="100px"
          left="0px"
          right="0px"
          bottom="0px"
          borderTopLeftRadius="24px"
          borderTopRightRadius="24px"
          className={
            isOpenModal
              ? 'animate__animated animate__slideInUp animate__duration-1s'
              : 'animate__animated animate__slideOutDown animate__duration-0.5s'
          }
        >
          <UiBox pos="relative">
            <UiBox pos="sticky" top={{base: '5%', lg: '10%'}} zIndex="11111">
              <Row justifyContent="flex-end">
                <UiIcon
                  as={RiArrowDownSLine}
                  pos="absolute"
                  right="10px"
                  color="white"
                  sx={{width: '50px', height: '50px', cursor: 'pointer'}}
                  onClick={onClickCloseModal}
                />
              </Row>
            </UiBox>
            <Form job={job} />
          </UiBox>
        </UiBox>
        <Content ref={mainRef} job={job} />
        <LatestVacancies />
      </UiBox>
      <Form job={job} />
    </UiBox>
  );
};

export default JobDetailPage;
