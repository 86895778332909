import {useMediaQuery} from '@chakra-ui/react';
import {Col, Row, UiBox, UiButton, UiContainer, UiImage, UiText} from '~/components/elements';
import {UiTitle} from '~/components/elements/UiTitle';
import {QUERY_MOBILE} from '~/constants';
import {CALENDLY_LINK} from '~/constants/coupon';
import React from 'react';
import {FILE_PATHS} from '~/constants/service';
interface Props {
  bgColor?: string;
}

const TalkToUs = ({bgColor = 'orange.400'}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  const handleScheduleMeeting = () => {
    window.open(CALENDLY_LINK, '_blank', 'noopener,noreferrer');
  };

  return (
    <UiBox h="100%" textAlign="center" mb="8rem">
      <Col
        mt={{base: '55px', lg: '95px'}}
        bgColor={bgColor}
        h={{base: '340px', lg: '370px'}}
        color="white"
        justifyContent="center"
      >
        <UiContainer maxW="container.xl" pos="relative">
          <UiTitle fontSize={{base: 'xl', lg: '4xl'}}>Not sure where to start? Talk to us!</UiTitle>
          <UiText fontSize="lg" mt={{base: '10px', lg: '30px'}}>
            Let’s share with us your story!
            <br />
            Schedule a one-hour meeting with our Senior leadership to look at <br />
            where you are now and where you want to be.
            <br />
            Usually, we can meet within a few days and start work within one to two weeks.
          </UiText>
          <Row justifyContent="center" mt={{base: '20px', lg: '50px'}}>
            <UiButton
              p="20px 70px"
              textDecor="underline"
              bgColor="transparent"
              border="1px solid #ffffff"
              color="white"
              onClick={handleScheduleMeeting}
            >
              Schedule the meeting {'>'}
            </UiButton>
          </Row>
          {isDesktop && (
            <>
              <UiImage
                filePath={FILE_PATHS.calendly}
                h={{base: '1.875em', lg: '3.375em'}}
                pos="absolute"
                top={{base: '180px', lg: '20px'}}
                left={{base: '20px', lg: '30px'}}
              />
              <UiImage
                filePath={FILE_PATHS.micro}
                h={{base: '1.625em', lg: '2.6875em'}}
                pos="absolute"
                bottom={{base: '50px', lg: '50px'}}
                left={{base: '60px', lg: '120px'}}
              />
              <UiImage
                filePath={FILE_PATHS.sun}
                pos="absolute"
                h={{base: '2.8125em'}}
                top={{base: '100px', lg: '90px'}}
                left={{base: '20px', lg: '240px'}}
              />
            </>
          )}
          <UiImage
            filePath={FILE_PATHS.rocket}
            h={{base: '1.625em', lg: '2.8125em'}}
            pos="absolute"
            bottom={{base: '55px', lg: '70px'}}
            right={{base: '330px', lg: '250px'}}
          />
          <UiImage
            filePath={FILE_PATHS.lightbulb}
            h={{base: '2em', lg: '2.9375em'}}
            pos="absolute"
            top={{base: '25px', lg: '50px'}}
            right={{base: '10px', lg: '170px'}}
          />
          <UiImage
            filePath={FILE_PATHS.headphone}
            h="3.375em"
            pos="absolute"
            right={{base: '50px', lg: '20px'}}
            bottom={{base: '-30%', lg: '20px'}}
            transform="rotate(-25.02deg)"
          />
        </UiContainer>
      </Col>
    </UiBox>
  );
};

export default TalkToUs;
