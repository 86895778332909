import React, {forwardRef, useEffect} from 'react';
import {Col, UiButton, UiContainer, UiGrid, UiIcon, UiTitle} from '~/components/elements';
import {MdKeyboardArrowRight} from 'react-icons/md';
import {PAGES} from '~/constants/index';
import {useHistory} from 'react-router-dom';
import {AppDispatch} from '~/redux/root-store';
import {useDispatch, useSelector} from 'react-redux';
import {fetchJobs} from '~/redux/jobs/thunk';
import {SOURCES_JOB} from '~/redux/jobs/entity-config';
import {getJobs} from '~/redux/jobs/slice';
import {JobApiItem} from '~/types/models/jobs';
import JobCard from '~/pages/career/components/JobCard';

const LatestVacancies = React.memo(
  forwardRef<HTMLDivElement>((props, ref) => {
    const history = useHistory();
    const dispatch: AppDispatch = useDispatch();
    const {data: jobs} = useSelector(getJobs);

    useEffect(() => {
      const fetchData = async () => {
        await dispatch(fetchJobs({source: SOURCES_JOB.JOBS_PAGE, limit: 3}));
      };
      fetchData();
    }, [dispatch]);

    return (
      <Col h={{base: '100%', lg: '100vh'}}>
        <UiContainer h="100%" maxW="container.xl" pb="40px" pos="relative" w={{base: '89%', lg: '76%'}}>
          <Col alignItems="center" justifyContent="center">
            <UiTitle fontSize="5xl">Latest Vacancies</UiTitle>
            <UiGrid
              templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(3,1fr)'}}
              columnGap={{base: 3, lg: 6}}
              rowGap={5}
              mt="20px"
              w="100%"
            >
              {jobs.map((job: JobApiItem) => {
                return <JobCard key={job.id} job={job} />;
              })}
            </UiGrid>
            <UiButton
              p="16px 28px"
              fontSize="sx"
              minW="220px"
              borderRadius="20px"
              mt="48px"
              role="group"
              actionName="All Jobs"
              onClick={() => history.replace(PAGES.JOB)}
            >
              All jobs
              <UiIcon
                as={MdKeyboardArrowRight}
                _groupHover={{ml: '20px'}}
                color="white"
                transition="0.5s"
                sx={{width: '30px', height: '30px'}}
              />
            </UiButton>
          </Col>
        </UiContainer>
      </Col>
    );
  }),
);

export default LatestVacancies;
