import React from 'react';
import {Col, Row, UiContainer, UiLink, UiText, UiTitle} from '~/components/elements/index';
import {resultsModal2} from '~/constants/story';

const Result = () => {
  return (
    <Col pb="5px">
      <UiTitle pt="50px" fontWeight="400" fontSize={{base: '3xl', lg: '6xl'}} textAlign="center">
        Result
      </UiTitle>
      <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '100%', lg: '70%'}} mt="50px">
        <Col>
          {resultsModal2.map((result: string, index) => {
            return (
              <Row key={index} w="100%" bgColor="#91AE70" borderRadius="20px" p={{base: '25px', lg: '30px'}} mb="30px">
                <UiText fontSize="xl" color="white">
                  {result}
                </UiText>
              </Row>
            );
          })}
          <Row justifyContent="center" pt="20px" w="100%" alignItems="center">
            <Col>
              <UiLink href="https://ease-healthcare.com" target="_blank" mb="10px">
                https://ease-healthcare.com
              </UiLink>
              <UiLink href="https://ease-healthcare.com/ph" target="_blank" mb="10px">
                https://ease-healthcare.com/ph
              </UiLink>
              <UiLink href="https://app.ease-healthcare.com" target="_blank">
                https://app.ease-healthcare.com
              </UiLink>
            </Col>
          </Row>
        </Col>
      </UiContainer>
    </Col>
  );
};

export default Result;
