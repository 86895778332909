import React from 'react';
import {AppAvatar, Col, Row, UiBox, UiButton, UiContainer, UiImage, UiText} from '~/components/elements';
import '~/pages/home-page/style/EaseProject.css';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {TextProps, useMediaQuery} from '@chakra-ui/react';
import {color} from '~/theme/color';
import {UiTitle} from '~/components/elements/UiTitle';
import '~/assets/css/main.css';
import {AnimationOnScroll} from 'react-animation-on-scroll';
import {useHistory} from 'react-router-dom';
import CaseStudyBtn from '~/components/shared/CaseStudyBtn';

const Title = (p: TextProps) => (
  <UiTitle fontSize={{base: '4xl', lg: '6xl'}} lineHeight={{base: '35px', lg: '60px'}} mb="25px" {...p} />
);

const Description = (p: TextProps) => (
  <UiText
    color={color.home.textDescription}
    fontSize={{base: 'sm', lg: 'md'}}
    lineHeight={{base: '18px', lg: '25px'}}
    mb="45px"
    {...p}
  />
);

const Header = (p: TextProps) => (
  <UiTitle mb={{base: '35', lg: '25px'}} fontSize={{base: '2xl', lg: '4xl'}} lineHeight="22px" {...p} />
);

const Content = (p: TextProps) => (
  <UiTitle fontSize={{base: 'sm', lg: 'xl'}} lineHeight="30px" {...p} fontWeight="400" />
);

const EaseAdmin = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const history = useHistory();

  const renderFeedBack = () => (
    <Row pos="relative">
      <UiBox w={{base: '100%', lg: '700px'}} maxH={{base: 'auto', lg: '550px'}} borderRadius="10px" pos="relative">
        <UiBox p={2} bg="#fafafa" borderRadius="20px" height="fit-content" width="100%">
          <div
            className="clutch-widget"
            data-url="https://widget.clutch.co"
            data-widget-type="12"
            data-height="350"
            data-nofollow="true"
            data-expandifr="true"
            data-primary-color="#fb8c00"
            data-secondary-color="#fb8c00"
            data-reviews="2282592,2290571"
            data-clutchcompany-id="2263600"
          ></div>
        </UiBox>
        {isDesktop && <UiImage filePath="home/diamond.png" h="7.375em" pos="absolute" top="-30px" right="-100px" />}
        {!isDesktop && (
          <UiImage filePath="home/diamond-3.png" w="5em" h="4.125em" pos="absolute" top="-35px" right="-20px" />
        )}
      </UiBox>
    </Row>
  );

  const renderEaseAdmin = () => (
    <UiBox pos="relative" h={{base: '240px', lg: '24.7vw'}} mb="50px">
      <UiImage filePath="home/frame-ipad.png" pos="absolute" zIndex="993" display="block" width="100%" height="100%" />
      <UiBox
        width="97%"
        height="96%"
        marginLeft={{base: '3px', lg: '8px'}}
        marginTop="5px"
        sx={{
          '& div': {
            height: '100%',
          },
        }}
      >
        <UiImage filePath="home/admin-ease.jpg" zIndex="10" pos="relative" height="100%" w="100%" borderRadius="20px" />
      </UiBox>
      <UiBox pos="absolute" right="-40px" top="40%">
        <AnimationOnScroll animateIn="animate__zoomIn">
          <UiImage filePath="home/circle.png" height="6.25em" zIndex="1" />
          <UiBox pos="absolute" top="-50px" right="10px" zIndex="1" transform="scale(1)" transition="2s">
            <svg height="6.1875em" viewBox="0 0 98 104" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                className="path"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M 10 10 H 90 V 90 H 10 1L 10 10"
                stroke="white"
                strokeWidth="1"
              ></path>
            </svg>
          </UiBox>
        </AnimationOnScroll>
      </UiBox>

      <UiBox pos="absolute" right="20%" bottom="-20px" transition="2s">
        <AnimationOnScroll animateIn="animate__zoomIn">
          <UiImage filePath="home/rectangle-pink.png" height="3.4375em" zIndex="1" />
          <UiBox pos="absolute" right="55%" bottom="-80px" zIndex="1" transform="scale(1)" transition="2s">
            <svg
              height={isDesktop ? '8.5em' : '9.4375em'}
              viewBox="0 0 78 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path className="path" d="M76.046 1L47 41.2803L17.954 1H76.046Z" stroke="white" strokeWidth="1"></path>
            </svg>
          </UiBox>
        </AnimationOnScroll>
      </UiBox>
    </UiBox>
  );

  return (
    <UiBox alignItems="center" bgColor="orange.400" color="white" pos="relative" mt={{base: '300px', lg: '250px'}}>
      <UiContainer h="100%" maxW="container.xl" pt="40px" pb="40px" pos="relative" w={{base: '89%', lg: '76%'}}>
        <Row
          pos="absolute"
          top={{base: '-22%', lg: '-28%'}}
          width={{base: '89%', lg: '100%'}}
          justifyContent="center"
          color="black"
        >
          {renderFeedBack()}
        </Row>
        <Row pt={{base: '220px', lg: '190px'}}>
          <Col mr="20px">
            <Title>EASE ADMIN</Title>
            <Description color="white">
              Comprehensive admin panels for orders & appointments booking management, and more.
            </Description>
            {!isDesktop && renderEaseAdmin()}
            <Header>Outputs:</Header>
            <Content>1. 15+ services managed via Admin Panels</Content>
            <Content>2. Less than one week for new features launched</Content>
            <Content>3. 7,000+ orders managed per month</Content>
            <Content>4. Permissions and roles management</Content>
            <Row>
              <CaseStudyBtn />
            </Row>
          </Col>

          {isDesktop && renderEaseAdmin()}
        </Row>
      </UiContainer>
    </UiBox>
  );
};

export default EaseAdmin;
