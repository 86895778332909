export const WHY_CHOOSE_US = [
  {
    id: 1,
    title: 'Product Experienced',
    items: [],
  },
  {
    id: 2,
    title: 'Skillful Talent Pool',
    items: [],
  },
  {
    id: 3,
    title: 'Cost-Optimized',
    items: [],
  },
  {
    id: 4,
    title: 'Transparency & Security',
    items: [],
  },
];

export const MEMBERS = [
  'scrum-team/an.png',
  'scrum-team/hang.png',
  'scrum-team/long.png',
  'scrum-team/ngan.png',
  'scrum-team/giang.png',
  'scrum-team/trang.png',
  'scrum-team/dat.png',
  'scrum-team/nhui.png',
  'scrum-team/minh.png',
  'scrum-team/khoi.png',
  'scrum-team/hung.png',
  'scrum-team/thinh.png',
];

export const FILE_PATHS = {
  calendly: 'home/calendly-1.png',
  micro: 'home/micro.png',
  sun: 'home/sun-1.png',
  rocket: 'home/rocket-1.png',
  lightbulb: 'home/lightbulb-1.png',
  headphone: 'home/headphone-1.png',
};
