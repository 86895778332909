import React, {useRef} from 'react';
import {Col, UiBanner, UiBox, UiText} from '~/components/elements';
import {HowWeHelp} from '~/pages/manage-entire-service/HowWeHelp';
import {Divider} from '@chakra-ui/react';
import {Stories} from '~/pages/build-your-ideas/Stories';
import {LaunchProcess} from '~/pages/manage-entire-service/LaunchProcess';
import {ContactUs} from '~/components/shared/ContactUs';
import useMetaTags from 'react-metatags-hook';
import {useScrollToTop} from '~/hooks/useScrollToTop';

const MangeEntireServicePage = () => {
  const mainRef = useRef<HTMLDivElement>(null);

  useScrollToTop();

  useMetaTags(
    {
      title: `Manage An Entire Service | Rayyone`,
      description: `Focus on your business goals and let us take care of everything else for you. Optimize your team's time and quality with our management & technical support. Learn more!`,
      charset: 'utf8',
      lang: 'en',
      metas: [
        {
          name: 'keywords',
          content: 'technical support,rayyone,it management,rayyone',
        },
      ],
      links: [{rel: 'canonical', href: window.location.href}],
    },
    [],
  );

  const title = (
    <>
      Manage{' '}
      <UiText as="span" color="orange.400">
        entire service
      </UiText>
    </>
  );

  return (
    <UiBox>
      <UiBanner
        onClickArrowDown={() => {
          return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'start'});
        }}
        title={title}
        description="Focus on your business goals and let us take care of everything else for you."
      />
      <LaunchProcess ref={mainRef} />
      <Col align="center">
        <Divider w="80%" borderBottomWidth={2} />
      </Col>
      <HowWeHelp />

      <Stories />
      <ContactUs />
    </UiBox>
  );
};

export default MangeEntireServicePage;
