import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {JobEntity} from '~/types/models/jobs';

class EntityConfig extends BaseEntityConfig<JobEntity> {
  entityKey = 'jobs';

  associates = [];

  whiteListFields: WhiteListFields<JobEntity> = ['*'];

  getAccessors = (value: JobEntity) => {};
}

const jobEntConf = new EntityConfig();

export const jobSchema = jobEntConf.getEntitySchema();

export default jobEntConf;

export const SOURCES_JOB = {
  JOBS_PAGE: 'JOBS_PAGE',
  JOBS_DROPDOWN: 'JOB_DROPDOWN',
  JOB_PAGE: 'JOB_PAGE',
};
