import React, {memo} from 'react';
import ReactGA from 'react-ga';
import {UiButton} from '~/components/elements';
import {useColorModeValue} from '@chakra-ui/react';

export const SendContactMessage = memo(
  ({onClickSendContactBtn, loading}: {onClickSendContactBtn: () => void; loading?: boolean}) => {
    const onSubmit = () => {
      ReactGA.event({
        category: 'Contact us',
        action: 'Click on Send message btn',
        label: 'Contact label',
      });
      onClickSendContactBtn();
    };
    const colorText = useColorModeValue('black', 'white');

    return (
      <UiButton
        mt="10px"
        onClick={onSubmit}
        borderRadius="20px"
        border=" 2px solid #FB8C00"
        color={colorText}
        bgColor="transparent"
        isLoading={loading}
        fontSize="xl"
      >
        Send Message
      </UiButton>
    );
  },
);
