import {ENDPOINTS} from '~/constants';
import {subscriberSchema} from '~/redux/subscribers/entity-config';
import {thunk} from '~/utils/thunk';
import {NormalizedSubscriber, SubscriberEntity} from '~/types/models/subscribers';
import {PostParams} from 'redux-thunk-kit';

export interface AddSubscriberParams extends PostParams {
  data: Partial<SubscriberEntity>;
}

const query = thunk.query<SubscriberEntity, NormalizedSubscriber>('subscriber', subscriberSchema);
export const addSubscriber = query.post<AddSubscriberParams>(ENDPOINTS.SUBSCRIBER);
