import React from 'react';
import {Col, Row, UiBox, UiContainer, UiImage, UiText} from '~/components/elements/index';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Exam1 = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox overflow="hidden">
      <Row
        w="100%"
        h={{base: '100%', lg: '550px'}}
        bg="radial-gradient(50% 50% at 50% 50%, rgba(238, 229, 197, 0.7) 0%, rgba(238, 245, 244, 0.69) 100%);"
      >
        <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '100%', lg: '90%'}}>
          <Row w="100%" h="100%" alignItems="center" justifyContent="flex-end" p={{base: '40px 0px'}}>
            <UiBox>
              <UiImage
                filePath="story/robiotic-cleaner-1.png"
                h={{base: '5.75em', lg: '14em'}}
                pos="absolute"
                bottom="0px"
                left={{base: '-1%', lg: '1%'}}
              />
              <UiImage
                filePath="story/ease-11.png"
                h={{base: '8em', lg: '26em'}}
                pos="absolute"
                left={{base: '12%', lg: '5%'}}
                bottom={{base: '0px', lg: '5%'}}
              />
              <UiImage
                filePath="story/ease-12.png"
                h={{base: '8.75em', lg: '26.375em'}}
                pos="absolute"
                left={{base: '17%', lg: '10%'}}
                bottom={{base: '-20%', lg: '-13%'}}
              />
              {isDesktop && (
                <UiImage
                  filePath="story/wallet.png"
                  h="5.75em"
                  pos="absolute"
                  bottom="10%"
                  left={{base: '0px', lg: '-5%'}}
                  filter="blur(4px)"
                  transform="rotate(-32.61deg)"
                />
              )}
              <UiImage
                filePath="story/blueberry.png"
                h={{base: '3.25em', lg: '6em'}}
                pos="absolute"
                top="10%"
                left={{base: '3%', lg: '-5%'}}
                filter="blur(4px)"
                transform="rotate(132.85deg)"
              />

              <UiImage
                filePath="story/cranberry-1.png"
                h={{base: '4.625em', lg: '12.5em'}}
                pos="absolute"
                top="-5%"
                left="20%"
                filter="blur(4px)"
                transform="rotate(-18.36deg)"
              />
              <UiImage
                filePath="story/blueberry.png"
                h={{base: '1.375em', lg: '3em'}}
                pos="absolute"
                top="50%"
                left="35%"
                transform="rotate(25.68deg)"
              />
              <UiImage
                filePath="story/wallet.png"
                h={{base: '5.625em', lg: '11.875em'}}
                pos="absolute"
                bottom="-20%"
                left="50%"
              />
            </UiBox>
            <Row w="50%">
              <UiText fontSize={{base: 'md', lg: '5xl'}} textAlign="center">
                Recommendations for products and services are tailored for each customer based on their purchase habits
              </UiText>
            </Row>
          </Row>
        </UiContainer>
      </Row>
      <Col mt="100px">
        <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '100%', lg: '80%'}}>
          <UiText fontSize={{base: 'md', lg: '5xl'}} textAlign="center" mb="50px">
            Agile eCommerce Growth team could manage multiple delivery aspects from technology and quality to UI, UX and
            SEO
          </UiText>
          <Row w="100%" mt="100px" justifyContent="center">
            <UiBox w={{base: '15.625em', lg: '35em'}} h={{base: '12.25em', lg: '27em'}} pos="relative">
              <UiImage pos="relative" zIndex="5" filePath="story/ease-13.png" w="100%" h="100%" />
              <UiImage
                zIndex="2"
                filePath="story/cranberry-1.png"
                h={{base: '5.5em', lg: '12.5em'}}
                pos="absolute"
                top="0%"
                left="-5%"
                filter="blur(4px)"
                transform="rotate(-18.36deg)"
              />
              <UiImage
                filePath="story/robiotic-cleaner.png"
                h={{base: '8.25em', lg: '21.5em'}}
                pos="absolute"
                zIndex="1"
                bottom="-35%"
                right="-5%"
                filter="blur(4px)"
                transform="rotate(34.67deg)"
              />
              <UiImage
                filePath="story/mannose-1.png"
                h={{base: '7.375em', lg: '11.25em'}}
                pos="absolute"
                top="-22%"
                right="-10%"
              />
            </UiBox>
          </Row>
        </UiContainer>
      </Col>
      <Col mt={{base: '200px', lg: '300px'}} pos="relative" bgColor="#91AE70">
        <UiContainer h="100%" maxW="container.xl" pos="relative" w={{base: '89%', lg: '90%'}} p={{lg: '50px 0px'}}>
          <UiImage
            filePath="story/pH-balanced.png"
            transform={{base: ' rotate(-26.65deg)', lg: 'rotate(-39.12deg)'}}
            h={{base: '8.25em', lg: '17.25em'}}
            pos="absolute"
            top={{base: '-15%', lg: '-35%'}}
            left="10%"
          />
          <Col
            pt={{base: '80px'}}
            w={{base: '100%', lg: '50%'}}
            h="100%"
            alignItems="center"
            justifyContent="center"
            fontSize={{base: 'md', lg: '3xl'}}
          >
            <UiText textAlign="center" color="white" mb={{base: '20px', lg: '40px'}}>
              Improved UX, UI design for landing and product pages to drive conversion.
            </UiText>
            <UiText textAlign="center" color="white">
              Referrals program and reward flow is custom-made to gain new users, and make loyal users “sticky”
            </UiText>
          </Col>
          <Row mt={{base: '20px'}} w="100%" justifyContent="center">
            <UiImage
              filePath="story/ease-14.png"
              h={{base: '15.625em', lg: '31.875em'}}
              pos={{base: 'relative', lg: 'absolute'}}
              bottom="0px"
              right="0px"
            />
          </Row>
        </UiContainer>
      </Col>
    </UiBox>
  );
};

export default Exam1;
