import {ButtonProps, TextProps} from '@chakra-ui/react';
import React, {useCallback, useEffect, useState} from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiText, UiTitle} from '~/components/elements/index';
import {tab} from '~/constants/story';
import {useDispatch} from 'react-redux';
import {AppDispatch} from '~/redux/root-store';
import {HashLink} from 'react-router-hash-link';
import {PAGES} from '~/constants';
import {useLocation} from 'react-router-dom';

interface Props {
  changeTab: (value: string) => void;
}

const Button = (p: ButtonProps) => (
  <UiButton
    border="1px solid #000000"
    bgColor="transparent"
    color="black"
    h={{base: '20px'}}
    px={{base: '0.5rem', lg: '2rem'}}
    py={{base: '0.8rem', lg: '1.5rem'}}
    borderRadius="20px"
    _hover={{bgColor: 'black', color: 'white'}}
    {...p}
  />
);

const Text = (p: TextProps) => <UiText fontSize={{base: 'xs', lg: 'md'}} color="black" {...p} />;

const Banner = ({changeTab}: Props) => {
  const [selected, setSelected] = useState<string>(tab.DEFAULT);
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation();

  const handleClickButton = useCallback(
    (value: string) => {
      changeTab(value);
      setSelected(value);
    },
    [changeTab],
  );

  useEffect(() => {
    const tabName = location.hash.replace('#', '');
    if (Object.values(tab).includes(tabName)) {
      handleClickButton(tabName);
    } else {
      handleClickButton(tab.DEFAULT);
    }
  }, [handleClickButton, location.hash]);

  return (
    <UiBox mt="100px">
      <Col>
        <UiContainer h="100%" maxW="container.xl" w={{base: '98%', lg: '80%'}} pos="relative">
          <UiTitle textAlign="center" fontSize="5xl">
            Get to Know Our Work
          </UiTitle>
          <Row w="100%" overflowX="auto" pos="relative" pb="20px">
            <Row
              justifyContent="space-evenly"
              w={{base: 'auto', lg: '80%'}}
              mt={{base: '15px', lg: '30px'}}
              mr="auto"
              ml="auto"
            >
              <HashLink smooth to={`${PAGES.STORIES}`}>
                <Button
                  mr={{base: '12.5px', lg: '0px'}}
                  bgColor={selected === tab.DEFAULT ? 'black' : 'white'}
                  color={selected === tab.DEFAULT ? 'white' : ''}
                  fontSize="md"
                  onClick={() => handleClickButton(tab.DEFAULT)}
                >
                  See All
                </Button>
              </HashLink>
              <HashLink smooth to={`${PAGES.STORIES}${PAGES.STORIES_ECOMMERCE}`}>
                <Button
                  mr={{base: '12.5px', lg: '0px'}}
                  bgColor={selected === tab.E_COMMERCE ? 'black' : 'white'}
                  color={selected === tab.E_COMMERCE ? 'white' : ''}
                  fontSize="md"
                  onClick={() => handleClickButton(tab.E_COMMERCE)}
                >
                  eCommerce
                </Button>
              </HashLink>
              <HashLink smooth to={`${PAGES.STORIES}${PAGES.STORIES_MOBILEAPP}`}>
                <Button
                  mr={{base: '12.5px', lg: '0px'}}
                  bgColor={selected === tab.MOBILE ? 'black' : 'white'}
                  color={selected === tab.MOBILE ? 'white' : ''}
                  fontSize="md"
                  onClick={() => handleClickButton(tab.MOBILE)}
                >
                  Mobile App
                </Button>
              </HashLink>
              <HashLink smooth to={`${PAGES.STORIES}${PAGES.STORIES_LANDING}`}>
                <Button
                  bgColor={selected === tab.LANDING_PAGE ? 'black' : 'white'}
                  color={selected === tab.LANDING_PAGE ? 'white' : ''}
                  fontSize="md"
                  onClick={() => handleClickButton(tab.LANDING_PAGE)}
                >
                  Landing Page
                </Button>
              </HashLink>
            </Row>
          </Row>
        </UiContainer>
      </Col>
    </UiBox>
  );
};

export default Banner;
