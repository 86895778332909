import React from 'react';
import {Col, Row, UiBox, UiImage, UiText, UiTitle} from '~/components/elements';
import {useMediaQuery} from '@chakra-ui/react';
import {QUERY_MOBILE} from '~/constants';

const Example = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});

  return (
    <UiBox>
      <UiBox pos="relative" px={{base: 5, lg: 28}}>
        {isDesktop && <UiImage filePath="start-up/star-yellow.png" pos="absolute" top="-60px" left="60px" />}
        <Col w="80%" fontWeight="700" pt="100px">
          <UiTitle fontSize={{base: '1.5rem', lg: '5xl'}}>
            And once the first brick is built, we accompany you to get as far as your dreams take you
          </UiTitle>
          <UiTitle fontSize="3xl" mt={{base: '10px', lg: '35px'}}>
            some dreams which came true
          </UiTitle>
        </Col>
        {isDesktop && <UiImage filePath="start-up/arrow-2.png" pos="absolute" zIndex="10" h="120px" left="17%" />}
      </UiBox>
      <Row
        bgColor="#456762"
        borderRadius="0px 120px 120px 0px"
        pos="relative"
        mt={{base: '20px', lg: '70px'}}
        w={{base: '90%', lg: '76%'}}
        minH={{base: 'auto', lg: '18.2em'}}
        px={{base: 5, lg: 28}}
      >
        <Col
          justifyContent="space-evenly"
          w="55%"
          color="#FFFFFF"
          alignItems={{base: 'center', lg: 'inherit'}}
          textAlign={{base: 'center', lg: 'inherit'}}
          lineHeight={{base: '20px', lg: '50px'}}
        >
          <UiImage filePath="start-up/ease.png" h={{base: '0.9375em', lg: '1.6875em'}} mt={{base: '8px', lg: '31px'}} />
          <Row mt={{base: '13px', lg: '24px'}}>
            <UiText fontSize={{base: 'sm', lg: '2xl'}} color="#456762" bgColor="#ffff" borderRadius="20px" p="0px 15px">
              {isDesktop ? `Forbes Asia - top “100 to Watch”` : 'Forbes Asia\nTop “100 to Watch”'}
            </UiText>
          </Row>
          <UiText fontSize={{base: 'sm', lg: '5xl'}} mt={{base: '15px', lg: '17px'}} mb={{base: '20px', lg: '45px'}}>
            Comprehensive women’s healthcare platform
          </UiText>
        </Col>
        <UiBox>
          <UiImage
            filePath="start-up/group-5.png"
            pos="absolute"
            right={{base: '-20px', lg: '-50px'}}
            top={{base: '-40px', lg: '-100px'}}
            h={{base: '12.5em', lg: '25em'}}
            transform="rotate(6.51deg)"
          />
          <UiImage
            filePath="start-up/group-1.png"
            pos="absolute"
            h={{base: '11.25em', lg: '20em'}}
            right={{base: '30px', lg: '35px'}}
            top={{base: '-15px', lg: '-5px'}}
          />
          <UiImage
            filePath="start-up/status-ring-1.png"
            pos="absolute"
            top={{base: '-20px', lg: '-70px'}}
            right={{base: '85px', lg: '110px'}}
            h={{base: '4.375em', lg: '10em'}}
          />
          <UiImage
            filePath="start-up/reply.png"
            pos="absolute"
            h={{base: '2.1875em', lg: '3.4375em'}}
            right={{base: '-30px', lg: '-80px'}}
            top={{base: '70px', lg: '80px'}}
          />
          <Row
            pos="absolute"
            bgColor="#ffff"
            borderRadius="50%"
            w={{base: '1.5625em', lg: '2.8125em'}}
            h={{base: '1.5625em', lg: '2.8125em'}}
            right={{base: '110px', lg: '180px'}}
            top={{base: '75px', lg: '155px'}}
            justifyContent="center"
            alignItems="center"
          >
            <UiImage filePath="start-up/react-1.png" w="20px" h="20px" />
          </Row>
          <Row
            pos="absolute"
            bgColor="#ffff"
            borderRadius="50%"
            w={{base: '1.875em', lg: '2.8125em'}}
            h={{base: '1.875em', lg: '2.8125em'}}
            right={{base: '0px', lg: '-25px'}}
            top={{base: '25px', lg: '25px'}}
            justifyContent="center"
            alignItems="center"
          >
            <UiImage filePath="start-up/fatigue-1.png" w="20px" h="20px" />
          </Row>
          <UiImage
            filePath="start-up/fatigue-2.png"
            w={{base: '1.875em', lg: '2.8125em'}}
            h={{base: '1.875em', lg: '2.8125em'}}
            pos="absolute"
            right={{base: '10px', lg: '0px'}}
            top={{base: '-20px', lg: '-50px'}}
          />
        </UiBox>
      </Row>
    </UiBox>
  );
};
export default Example;
