import React from 'react';
import {Col, Row, UiBox, UiButton, UiImage, UiLink, UiText, UiTitle} from '~/components/elements';
import {Link, useHistory} from 'react-router-dom';
import {useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE} from '~/constants';
import {useSelector} from 'react-redux';
import {RootState} from '~/redux/root-reducer';
import {AppPostBadge} from '~/components/elements/index';

const data = [
  {
    title: 'Strategic plan for your MVP',
    badges: ['Approach', 'Technology', 'UI/UX'],
    description:
      "Who said that the MVP is useless after fund-raising?\n\nGiant grows from the piece of idea! We know how important an MVP means to startups!\n\nBy leveraging Rayyone's product, we can help you to build <strong>more-than-an-MVP version</strong>  in top-notch quality and scalability, within a short period of time.",
    color: '#E3CDF7',
  },
  {
    title: 'Friendly budget, Dedicated team',
    badges: ['Friendly', 'Dedicated'],
    description:
      'By applying Rayyone’s product, we shorten the implementation duration which helps to save budget while up-scaling the quality.\n\nOur well-organize Scrum team is taking end-to-end responsibility to the projects. We are a people-oriented company that selects team members based on their values, has a low turnover rate, and a motivated and engaged team.',
    color: '#FB8C00',
  },
  {
    title: 'Light but powerful workflow',
    badges: ['Scrum/ Squad', 'Budget'],
    description:
      'We understand that only true cooperation will result in working software satisfying customers needs.\n\nIn our work, we follow Scrum/Agile methodologies - short 1 to 2-week sprints with clear deliverables, and visible progress.\n\nWe do not conceal ourselves behind bureaucratic procedures. We cultivate open and honest relationships with our clients by providing regular check-ins and updates.',
    color: '#95DCBC',
  },
  {
    title: 'Technology',
    badges: ['Golang', 'React Native', 'Flutter', 'Python', 'PHP'],
    description:
      'We adopt a wide-range of modern technologies.\n\nNo matter which domains you are aiming at, we propose the most suitable strategies for your decision.\n\nWe analyze the Customer Experience touch-points from A to Z to offer technical consultations and suggestions and deliver continuous improvements.\n\nCI/CD system, Semantic Commit Messages, Every-single-code-line review and Up-to-date Script system are leveraged to the greatest extent to assure the speed to market deliverables.',
    color: '#FDD466',
  },
  {
    title: 'Security',
    badges: ['Fair and Clear', 'NDA', 'Data Privacy'],
    description:
      'We do things fair and clear. \n\n NDA is signed before we starting the cooperation.\n\nWe also follow Secure Coding Practices. We treat data security very seriously, and we mean it.',
    color: '#C591E7',
  },
];

const VividIdeas = () => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const menuHeight = useSelector((state: RootState) => state.ui.menu.height);

  const history = useHistory();

  const onClickContactUs = () => {
    return history.push(PAGES.CONTACT_US);
  };

  return (
    <Col sx={{scrollMarginTop: `${menuHeight}px`}} alignItems="flex-end">
      <Row
        bgColor="#FB8C00"
        borderRadius="120px 0px 0px 120px"
        float="right"
        w={{base: '85%', lg: '70%'}}
        pos="relative"
        mt={{base: '0px', lg: '50px'}}
      >
        <Col w={{base: '15%', lg: '13%'}}>{''}</Col>
        <Col justifyContent="space-evenly" w="60%" color="#FFFFFF" float="right" fontWeight="400">
          <UiTitle
            fontSize={{lg: '5xl'}}
            lineHeight={{base: '20px', lg: '40px'}}
            mt={{base: '20px', lg: '75px'}}
            fontWeight="400"
          >
            We are happy to help you vivid your one-of-a-kind ideas!
          </UiTitle>
          <UiTitle
            fontSize={{lg: '2xl'}}
            lineHeight={{base: '12px', lg: '40px'}}
            mt={{base: '20px', lg: '60px'}}
            mb={{base: '23px', lg: '45px'}}
            fontWeight="400"
          >
            here is how we help
          </UiTitle>
        </Col>
        {isDesktop && <UiImage filePath="start-up/arrow-1.png" pos="absolute" top="260px" left="20%" height="100px" />}
      </Row>
      <UiBox px={{base: 5, lg: 28}}>
        <Col w={{base: '75%', lg: '63%'}} float="right" mt={{base: '30px', lg: '70px'}}>
          {data.map((value, index) => {
            return <AppPostBadge content={value} key={index} />;
          })}

          <UiBox
            as={isDesktop ? Row : Col}
            alignItems={{base: '', lg: 'center'}}
            w={{base: '100%', lg: '80%'}}
            fontSize={{base: 'md', lg: 'lg'}}
            mt={{base: '0px', lg: '80px'}}
          >
            <UiButton
              border="3px solid #FB8C00"
              borderRadius="10px"
              bgColor="transparent"
              p={{base: '10px 10px', lg: '18px 14px'}}
              fontSize={{base: 'md', lg: 'lg'}}
              mr="10px"
              mb={{base: '10px', lg: '0px'}}
              onClick={onClickContactUs}
            >
              Share with us!
            </UiButton>
            <UiText>
              {isDesktop && `and${' '}`}
              <UiLink as={Link} to={PAGES.CONTACT_US}>
                We are here for you! {'>'}
              </UiLink>
            </UiText>
          </UiBox>
        </Col>
      </UiBox>
    </Col>
  );
};

export default VividIdeas;
