import React from 'react';
import {BsDot} from 'react-icons/bs';
import {Col, Row, UiBox, UiContainer, UiGrid, UiGridItem, UiIcon, UiText, UiTitle} from '~/components/elements/index';
import {approachesModal2} from '~/constants/story';

const Approach = () => {
  return (
    <UiBox mt={{base: '0px', lg: '100px'}} pb="50px">
      <UiContainer h="100%" maxW="container.xl" pt="40px" pb="40px" pos="relative" w={{base: '100%', lg: '80%'}}>
        <UiGrid templateColumns={{base: 'repeat(1,1fr)', lg: 'repeat(5,1fr)'}}>
          <UiGridItem colSpan={{base: 1, lg: 2}} mb="30px">
            <UiTitle fontWeight="400" fontSize={{base: '4xl', lg: '5xl'}} lineHeight="normal">
              Approach
            </UiTitle>
          </UiGridItem>
          <UiGridItem colSpan={{base: 1, lg: 3}}>
            <Col>
              {approachesModal2.map((approach: string, index) => {
                return (
                  <Row key={index} mb={{base: '15px', lg: '25px'}} alignItems="baseline">
                    <UiIcon as={BsDot} width="25px" height="25px" color="black" />
                    <UiText fontSize={{base: 'md', lg: '2xl'}}>{approach}</UiText>
                  </Row>
                );
              })}
            </Col>
          </UiGridItem>
        </UiGrid>
      </UiContainer>
    </UiBox>
  );
};

export default Approach;
