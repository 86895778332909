import {BaseEntityConfig, WhiteListFields} from '~/redux/base-entity-config';
import {LevelEntity} from '~/types/models/levels';

class EntityConfig extends BaseEntityConfig<LevelEntity> {
  entityKey = 'levels';

  associates = [];

  whiteListFields: WhiteListFields<LevelEntity> = ['*'];

  getAccessors = (value: LevelEntity) => {};
}

const levelEntConf = new EntityConfig();

export const levelSchema = levelEntConf.getEntitySchema();

export default levelEntConf;

export const SOURCES_LEVEL = {
  LEVELS_PAGE: 'LEVEL_PAGE',
  LEVELS_DROPDOWN: 'LEVEL_DROPDOWN',
};
