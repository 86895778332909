import React, {useRef} from 'react';
import {Col, Row, UiBox, UiButton, UiContainer, UiIcon, UiImage, UiText} from '~/components/elements';
import {useColorModeValue, useMediaQuery} from '@chakra-ui/react';
import {PAGES, QUERY_MOBILE, QUERY_SMALL_PHONE} from '~/constants';
import {BsArrowDown} from 'react-icons/bs';
import {useHistory} from 'react-router-dom';
import {color} from '~/theme/color';
import {UiTitle} from '~/components/elements/UiTitle';

interface Props {
  onClickArrowDown?: () => any;
}

const Banner = React.memo(({onClickArrowDown}: Props) => {
  const [isDesktop] = useMediaQuery(`(min-width: ${QUERY_MOBILE})`, {ssr: false});
  const colorButton = useColorModeValue('black', 'white');
  const history = useHistory();
  const mainRef = useRef<HTMLDivElement>(null);
  const [isSmallPhone] = useMediaQuery(`(max-width: ${QUERY_SMALL_PHONE})`, {ssr: false});

  const onClickContactUs = () => {
    return history.push(PAGES.CONTACT_US);
  };

  const onClickAwardWinning = () => {
    return mainRef?.current?.scrollIntoView({behavior: 'smooth', block: 'center', inline: 'center'});
  };

  const renderAwardWinning = () => {
    if (isDesktop) {
      return (
        <UiBox ref={mainRef} mt={100}>
          <Row
            pl="60px"
            pr="97px"
            pt="35px"
            pb="45px"
            w="1079px"
            borderRadius="20px"
            justifyContent="space-between"
            bg="#F5F5F5"
          >
            <Col>
              <UiTitle lineHeight="45px" fontWeight="normal" mb="32px" fontSize={{base: '4xl', lg: '5xl'}}>
                Award-winning Software Company
              </UiTitle>
              <div
                className="clutch-widget"
                data-url="https://widget.clutch.co"
                data-widget-type="14"
                data-height="50"
                data-nofollow="true"
                data-expandifr="true"
                data-header-color="#17313B"
                data-footer-color="#17313B"
                data-primary-color="#fb8c00"
                data-secondary-color="#fb8c00"
                data-clutchcompany-id="2263600"
              ></div>
            </Col>
            <Row gap={20} justifyContent="flex-end">
              <iframe
                width="187"
                height="202"
                src="https://shareables.clutch.co/share/badges/2263600/77892?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch Partner API Company 2023"
              ></iframe>
              <iframe
                width="187"
                height="202"
                src="https://shareables.clutch.co/share/badges/2263600/9242?utm_source=clutch_top_company_badge&utm_medium=image_embed"
                title="Top Clutch IOT Company Vietnam 2023"
              ></iframe>
            </Row>
          </Row>
        </UiBox>
      );
    }

    return (
      <Col mb="40px" alignItems="center">
        <Row flexWrap={isSmallPhone ? 'wrap' : 'nowrap'} alignItems="center" mb={5} gap={4}>
          <Col>
            <iframe
              width={isSmallPhone ? '66' : '50'}
              height={isSmallPhone ? '72' : '60'}
              src="https://shareables.clutch.co/share/badges/2263600/77892?utm_source=clutch_top_company_badge&utm_medium=image_embed"
              title="Top Clutch Partner API Company 2023"
            ></iframe>
          </Col>
          <Col>
            <iframe
              width={isSmallPhone ? '66' : '50'}
              height={isSmallPhone ? '72' : '60'}
              src="https://shareables.clutch.co/share/badges/2263600/9242?utm_source=clutch_top_company_badge&utm_medium=image_embed"
              title="Top Clutch IOT Company Vietnam 2023"
            ></iframe>
          </Col>
          <Col>
            <div
              className="clutch-widget"
              data-url="https://widget.clutch.co"
              data-widget-type="2"
              data-height="45"
              data-scale="10"
              data-nofollow="true"
              data-expandifr="true"
              data-primary-color="#fb8c00"
              data-secondary-color="#fb8c00"
              data-clutchcompany-id="2263600"
            ></div>
          </Col>
        </Row>
      </Col>
    );
  };

  return (
    <UiBox
      mb={{base: '80px', md: '35px', lg: '90px'}}
      mt={{base: '100px', lg: '200px'}}
      pt={{base: '10px', lg: '50px'}}
    >
      <UiContainer h="100%" maxW="container.xl" w={{base: '90%', lg: '76%'}} mr="auto" ml="auto">
        <Col h="100%" w="100%" pt={{base: '125px', lg: 'inherit'}} justifyContent="center" pos="relative">
          <UiBox pos="relative">
            <UiTitle
              textAlign={{lg: 'center'}}
              fontSize={{base: '4xl', lg: '8xl'}}
              lineHeight={{base: '40px', lg: '85px'}}
            >
              We provide {!isDesktop && <br />}
              <UiText as="span" color="orange.400">
                fast-great- <br />
                affordable {!isDesktop && <br />}
              </UiText>{' '}
              software <br /> development services
              <UiText as="span" color="orange.400">
                ,
              </UiText>
            </UiTitle>
          </UiBox>

          <UiText
            textAlign={{lg: 'center'}}
            fontSize={{base: 'sm', lg: 'xl'}}
            color={color.home.textDescription}
            lineHeight={{base: '18px', lg: '30px'}}
            mt={{base: '27px', lg: '15px'}}
          >
            With product-mindset, dedicated agile team, we will accompany you{isDesktop && <br />} to get as far as your
            dreams take you
          </UiText>

          <UiBox
            height="fit-content"
            as={isDesktop ? Row : Col}
            justifyContent={{lg: 'center'}}
            alignItems={{base: 'baseline', lg: 'center'}}
            flexDirection={{base: 'column-reverse', lg: 'column'}}
            mt="40px"
          >
            <UiButton
              border="3px solid #FB8C00"
              borderRadius="10px"
              bgColor="transparent"
              px={{base: '18px  ', lg: '20px  '}}
              py={{base: '8px', lg: '30px'}}
              color={colorButton}
              mr="20px"
              fontSize={{base: 'md', lg: '2xl'}}
              onClick={onClickContactUs}
            >
              Contact us!
            </UiButton>
            {renderAwardWinning()}
          </UiBox>
          <UiBox pos="absolute" right="0px"></UiBox>
          {isDesktop && (
            <UiIcon
              pos="absolute"
              right="-40px"
              bottom="320px"
              _hover={{cursor: onClickArrowDown ? 'pointer' : undefined}}
              as={BsArrowDown}
              boxSize={70}
              color={color.home.textDescription}
              mt={10}
              alignSelf="flex-start"
              onClick={onClickAwardWinning}
            />
          )}
        </Col>
      </UiContainer>
    </UiBox>
  );
});

export default Banner;
