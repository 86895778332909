import {ENDPOINTS} from '~/constants';
import {trackerSchema} from '~/redux/tracker/entity-config';
import {NormalizedTracker, TrackerEntity} from '~/types/models/tracker';
import {thunk} from '~/utils/thunk';
import {PostParams} from 'redux-thunk-kit';

export interface AddTrackersParams extends PostParams {
  data: {data: Partial<TrackerEntity[]>};
}

const query = thunk.query<TrackerEntity, NormalizedTracker>('tracker', trackerSchema, {isIgnoreError: true});

export const addTrackers = query.post<AddTrackersParams>(ENDPOINTS.BULK_TRACKERS);
